import { Button } from "@mantine/core";
import React, { useCallback, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  STATE_CULTURE,
  STATE_CURRENCY,
  STATE_SALE_ID,
} from "../../common/state";
import { isItReal, pixelCheckoutData } from "../../common/util";
import { checkout } from "../../common/sale";
import { CLOSE_CART, Context } from "../../context";
import classes from "./DrawerFooter.module.css";
import { useNavigate } from "react-router";
import { goToCheckoutTracking } from "../common/tracking";
import Cookies from "universal-cookie";

const DrawerFooter = ({ sale }) => {
  const { language, currency, config, saleId, culture } = useSelector(
    (state) => ({
      language: state.language,
      currency: state.cookies[STATE_CURRENCY],
      config: state.config,
      saleId: state.cookies[STATE_SALE_ID],
      culture: state.cookies[STATE_CULTURE],
    })
  );
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const navigate = useNavigate();
  const cookies = useMemo(() => new Cookies(), []);
  const consent = cookies.get("shop-consent");

  const shippingCost = useCallback(() => {
    const shipping = sale.items.filter((el) => el.type === "shipping_fee");
    if (shipping.length > 0) {
      return (
        <div className={classes.shippingWrapper}>
          <h4>{language.ShippingCost}</h4>
          <h4>{shipping?.[0]?.totalPriceInclVat}</h4>
        </div>
      );
    }
  }, [sale]);
  const serviceFee = useCallback(() => {
    const serviceFee = sale.items.filter((el) => el.type === "service_fee");
    if (serviceFee.length > 0) {
      return (
        <div className={classes.shippingWrapper}>
          <h4>{language.ServiceFee}</h4>
          <h4>{serviceFee?.[0]?.totalPriceInclVat}</h4>
        </div>
      );
    }
  }, [sale]);

  const goToCheckout = () => {
    if (config?.useOptionsPage) {
      dispatch({ type: CLOSE_CART });
      return navigate("/options");
    }
    if (sale) {
      if (config.facebookPixelId && isItReal("pixel", config.facebookPixelId)) {
        pixelCheckoutData(sale.items, currency);
      }
    }
    goToCheckoutTracking(sale, currency);
    localStorage.setItem("hasBegunCheckout", sale.id);
    return rdxDispatch(checkout(consent));
  };

  return (
    <div className={`${classes.footer} drawer__footer`}>
      <div className={`${classes.textWrapper} drawer__footer-textWrapper`}>
        <h2 className={classes.toPay}>{language.ToPay}</h2>
        <h3 className={classes.sum}>{sale?.sumInclVat}</h3>
      </div>
      {shippingCost()}
      {serviceFee()}
      <Button
        fullWidth
        size="lg"
        className={`${classes.continueShopping} secondaryButton`}
        onClick={() => dispatch({ type: CLOSE_CART })}
      >
        {language.ArticleBooking.ModalSecondaryButton}
      </Button>
      <Button
        fullWidth
        size="lg"
        className="regularButton begin_checkout"
        classNames={{
          label: "begin_checkout",
          inner: "begin_checkout",
        }}
        disabled={sale?.items.length === 0}
        onClick={goToCheckout}
      >
        {language.GoToCheckout}
      </Button>
    </div>
  );
};

export default DrawerFooter;
