import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import AddToCart from "./AddToCart";
import { useDisclosure } from "@mantine/hooks";
import OptionsModal from "../Options/OptionsModal";

const Cart = ({
  section,
  articlesData,
  optionsData,
  language,
  isVisible,
  culture,
  currency,
}) => {
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [opened, { open, close }] = useDisclosure(false);
  const [extraInformation, setExtraInformation] = useState(null);

  const addItem = (item) => {
    let article = item;
    if (item?.additionalFields && extraInformation) {
      article = { ...item, extraInformation };
    }

    if (article.inStock) {
      if (calculateQuantity(article) + 1 <= article.inStock) {
        setCart([...cart, article]);
      }
    }
    if (!article.inStock) {
      setCart([...cart, article]);
    }
  };

  const removeItem = (item) => {
    const newCart = [...cart];
    const res = cart.findLastIndex((el) => el.id === item.id);
    newCart.splice(res, 1);
    setCart(newCart);
  };

  const calculateQuantity = (item) => {
    const findItems = cart.filter((el) => el.id === item.id);
    return findItems.length;
  };

  const calculateValue = () => {
    const removeChar = (price) => {
      if (price) {
        const regex = /[$€£kr\s]/g;
        let newPrice = price.replace(",", ".").replace(regex, "");
        return newPrice;
      }
    };

    let total = 0;
    for (let i = 0; i < cart.length; i++) {
      total += parseFloat(
        removeChar(cart[i].priceInclVat)
          ? removeChar(cart[i].priceInclVat)
          : removeChar(cart[i].displayPrice)
          ? removeChar(cart[i].displayPrice)
          : ""
      );
    }
    setCartTotal(total.toFixed(2));
  };

  const translation = (item) => {
    const getLang = item.translation.filter((el) => el.culture === culture);
    return getLang[0].name;
  };

  const spring = {
    type: "spring",
    stiffness: 500,
    damping: 30,
  };

  useEffect(() => {
    setCart([]);
  }, [section]);

  useEffect(() => {
    calculateValue();
  }, [cart]);

  const total = (item) => {
    switch (currency) {
      case "SEK":
        return item + " kr";
        break;
      case "EUR":
        return "€" + item;
        break;
      case "DKK":
        return item + " kr";
        break;
      case "GBP":
        return "£" + item;
        break;
      case "USD":
        return "$" + item;
        break;
      case "NOK":
        return item + " kr";
        break;
    }
  };

  const productPrice = (item) => {
    const regex = /[$€£kr]/g;
    let newPrice =
      item !== null && item !== undefined && item.replace(regex, "");
    if (newPrice.trim().split(".")[1] === "00") {
      return newPrice.split(".")[0];
    } else {
      return newPrice;
    }
  };

  return (
    <div
      className={`${
        isVisible === false ? "hideContent" : "showContent"
      } articleHeaderWrapper`}
    >
      <div className={`${isVisible === false ? "hideContent" : "showContent"}`}>
        <h2 className="articleHeader">{language?.ArticleBooking.CartHeader}</h2>
        {articlesData && articlesData.length > 0 ? (
          <div className="articleWrapper">
            {articlesData.map((el, i) => (
              <div
                className={`article ${el.inStock === 0 ? "hideScreen" : ""} `}
                key={el + i}
              >
                <div className="articleLeftColumn">
                  <p className="articleTitle">{translation(el)}</p>
                  <p className="articlePrice">
                    {el.inStock !== 0
                      ? `${total(productPrice(el?.priceInclVat))}`
                      : language?.ArticleBooking.OutOfStock}
                  </p>
                </div>
                <div className="articleRightColumnToggle">
                  <motion.div
                    className="switch"
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    transition={spring}
                    layout
                  />
                  <motion.div
                    className={`handle ${
                      calculateQuantity(el) === el.inStock ? "hideScreen" : ""
                    }`}
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    whileTap={{ scale: 1.2 }}
                    layout
                    transition={spring}
                  >
                    <button
                      className={`articleButton`}
                      onClick={() => addItem(el)}
                      aria-label={language?.ArticleBooking.AddProduct}
                    >
                      <MdAddCircle className="buttonIcons" />
                    </button>
                  </motion.div>
                  <motion.div
                    className="count articleQty"
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    data-over9={calculateQuantity(el) > 9 ? "true" : false}
                    data-over99={calculateQuantity(el) >= 100 ? "true" : false}
                    layout
                    transition={spring}
                  >
                    {calculateQuantity(el)}
                  </motion.div>
                  <motion.div
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    className="handle2"
                    layout
                    whileTap={{ scale: 1.2 }}
                    transition={spring}
                  >
                    <button
                      className="articleButtonRemove articleButton"
                      aria-label={language?.ArticleBooking.RemoveProduct}
                      onClick={() => removeItem(el)}
                      disabled={calculateQuantity(el) === 0 ? true : false}
                    >
                      <MdRemoveCircle className="buttonIcons" />
                    </button>
                  </motion.div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {optionsData && optionsData.length > 0 ? (
          <div className="articleWrapper">
            <h3 style={{ fontWeight: 600 }}>
              {language.ArticleBooking.OptionsHeader}
            </h3>
            {optionsData.map((el, i) => (
              <div
                className={`article ${el.inStock === 0 ? "hideScreen" : ""}`}
                key={el + i}
              >
                <div className="articleLeftColumn">
                  <p className="articleTitle">{translation(el)}</p>
                  <p className="articlePrice">
                    {el?.priceInclVat ? (
                      <>
                        {el.inStock !== 0
                          ? `${total(productPrice(el?.priceInclVat))}`
                          : language.ArticleBooking.OutOfStock}
                      </>
                    ) : el.inStock !== 0 ? (
                      el.displayPrice
                    ) : (
                      language.ArticleBooking.OutOfStock
                    )}
                  </p>
                </div>
                <div className="articleRightColumnToggle">
                  <motion.div
                    className="switch"
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    transition={spring}
                    layout
                  />
                  <motion.div
                    className={`handle ${
                      calculateQuantity(el) === el.inStock
                        ? "hideScreen"
                        : false
                    }`}
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    whileTap={{ scale: 1.2 }}
                    layout
                    transition={spring}
                  >
                    <button
                      className={`articleButton`}
                      onClick={() => {
                        if (el?.additionalFields.length > 0) {
                          open();
                        } else {
                          addItem(el);
                        }
                      }}
                      aria-label={language?.ArticleBooking.AddProduct}
                    >
                      <MdAddCircle className="buttonIcons" />
                    </button>
                  </motion.div>
                  <motion.div
                    className="count articleQty"
                    data-ison={calculateQuantity(el) > 0 ? true : false}
                    data-over9={calculateQuantity(el) > 9 ? "true" : false}
                    data-over99={calculateQuantity(el) >= 100 ? "true" : false}
                    layout
                    transition={spring}
                  >
                    {calculateQuantity(el)}
                  </motion.div>
                  <motion.div
                    data-ison={calculateQuantity(el) > 0 ? true : ""}
                    className="handle2"
                    layout
                    whileTap={{ scale: 1.2 }}
                    transition={spring}
                  >
                    <button
                      className={`articleButtonRemove articleButton ${
                        calculateQuantity(el) === 0 ? "hideScreen" : ""
                      }`}
                      onClick={() => removeItem(el)}
                      aria-label={language?.ArticleBooking.RemoveProduct}
                      disabled={calculateQuantity(el) === 0 ? true : false}
                    >
                      <MdRemoveCircle className="buttonIcons" />
                    </button>
                  </motion.div>
                </div>
                {el?.additionalFields?.length > 0 && (
                  <OptionsModal
                    title={translation(el)}
                    opened={opened}
                    close={close}
                    additionalFields={el.additionalFields}
                    handleAdd={() => addItem(el)}
                    extraInformation={extraInformation}
                    setExtraInformation={setExtraInformation}
                  />
                )}
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        <p
          className="articleTotalCost"
          style={{ opacity: cartTotal < 1 ? "0" : "1" }}
        >
          {language.ArticleBooking.CostText}:{" "}
          {total(productPrice(cartTotal.toString()))}
        </p>
        <AddToCart
          articlesData={articlesData}
          cart={cart}
          setCart={setCart}
          language={language}
        />
      </div>
    </div>
  );
};

export default Cart;
