import React, { useState, useEffect } from "react";
import {
  addMonths,
  subMonths,
  format,
  isBefore,
  startOfWeek,
  startOfMonth,
  isSameMonth,
} from "date-fns";

import { IoIosArrowDroprightCircle } from "react-icons/io";
import { IoIosArrowDropleftCircle } from "react-icons/io";

const Header = ({
  currentDate,
  setCurrentDate,
  selectedDate,
  firstOccasion,
  lastOccasion,
  culture,
  cultureLocale
}) => {
  const [allotmentDate, setAllotmentDate] = useState();
  const monthStart = startOfMonth(currentDate);
  const startDate = startOfWeek(monthStart);
  const dateFormat = "MMMM yyyy";

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  useEffect(() => {
    setAllotmentDate(selectedDate);
  }, []);

    return (
    <div className="header row flex-middle">
      <div className="column col-start">
        {!isBefore(monthStart, new Date(firstOccasion?.time)) ? (
          <button className="removeBorderBg icon" onClick={prevMonth}>
            <IoIosArrowDropleftCircle />
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="column col-center">
        <span>{format(currentDate, dateFormat, { locale: cultureLocale })} </span>
      </div>

      <div className="column col-end">
        {!isSameMonth(new Date(monthStart), new Date(lastOccasion?.time)) ? (
          <button className="removeBorderBg icon" onClick={nextMonth}>
            <IoIosArrowDroprightCircle />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
