import React, { useMemo } from "react";
import {
  Badge,
  Divider,
  Image,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import { useNavigate } from "react-router";
import slugify from "../../common/slugify";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { format } from "date-fns";
import classes from "./Event.module.css";
import placeholder from "../../../images/placeholder.jpg";
import { HiExternalLink } from "react-icons/hi";

const Event = ({ date, event }) => {
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const listType =
    window.innerWidth < 768
      ? config?.allEventsListType?.sm
      : config?.allEventsListType?.lg;
  const isCard = listType
    ? listType === "CARD"
    : window.innerWidth < 768
    ? config.articleListType?.sm === "CARD"
    : config.articleListType?.lg === "CARD";

  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const image = useMemo(() => {
    if (event.isExternal) {
      return event.imageFileName;
    }
    return `${uri}/allotmentimage/${event.imageFileName}`;
  }, [event]);

  const filter = useMemo(
    () => event.translation.filter((el) => el.culture === culture)[0],
    [event]
  );

  const nextOccasion = useMemo(() => {
    const occasion = event?.occasions.filter(
      (occasion) => occasion.remaining > 0
    )?.[0]?.time;
    if (occasion)
      return `${language.NextOccasion} ${format(
        new Date(occasion),
        "d/M HH:mm"
      )}`;
    return language.Allotment_Full;
  }, [event]);

  const links = () => {
    if (config?.ticketConfigurator) {
      const isEventCalendar = config?.ticketConfigurator.filter(
        (el) => slugify(el.name) === slugify(event.description)
      )?.[0];
      if (isEventCalendar) return `tickets/${slugify(event.description)}`;
      return `/events/${slugify(event.description)}`;
    } else {
      return `/events/${slugify(event.description)}`;
    }
  };

  const navigateEvent = () => {
    if (event?.isExternal) {
      return window.open(event.externalLink, "_blank");
    }
    return navigate(links(), { state: date });
  };

  return (
    <div style={{ height: isCard && "100%" }}>
      <UnstyledButton
        className={`${classes.wrapper} ${
          isCard && classes.isCard
        } allEventsEventButton`}
        onClick={() => navigateEvent()}
        style={{
          gridTemplateColumns: isCard ? "1fr" : "1fr",
          height: isCard && "100%",
          alignContent: isCard && "space-between",
          width: "100%",
        }}
        aria-label={filter?.name}
      >
        <div
          className={classes.grid}
          style={{
            flexDirection: isCard ? "column" : "row",
          }}
        >
          <div
            className={classes.imageWrapper}
            style={{ width: isCard ? "100%" : "auto" }}
          >
            <Image
              radius="md"
              src={image}
              fit={"cover"}
              h={isCard ? "20rem" : "7rem"}
              w={isCard ? "100%" : "7rem"}
              fallbackSrc={placeholder}
              alt={`${language.EventAltLabel} ${filter?.name}`}
            />
            <div className={classes.badgeWrapperCard}>
              <Badge
                className={`${classes.badge} allEventsBadge`}
                size="lg"
                style={{ display: isCard ? "block" : "none" }}
              >
                <div className={classes.badgeTextWrapper}>{nextOccasion}</div>
              </Badge>
              {event?.isExternal && (
                <Badge
                  className={`${classes.badgeCard} allEventsBadge`}
                  size="lg"
                  style={{ display: isCard ? "block" : "none" }}
                  color="red"
                >
                  <div className={classes.badgeTextWrapper}>
                    <HiExternalLink /> {language.ExternalLink}
                  </div>
                </Badge>
              )}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className={classes.badgeWrapperList}>
              <Badge
                className={`${classes.badgeList} allEventsBadgeList`}
                size="lg"
                style={{ display: isCard ? "none" : "block" }}
              >
                <div className={classes.badgeTextWrapper}>{nextOccasion}</div>
              </Badge>
              {event?.isExternal && (
                <Badge
                  className={`${classes.badgeList} allEventsBadgeList`}
                  size="lg"
                  style={{ display: isCard ? "none" : "block" }}
                  variant="outline"
                >
                  <div className={classes.badgeTextWrapper}>
                    <HiExternalLink /> {language.ExternalLink}
                  </div>
                </Badge>
              )}
            </div>
            <div className={classes.badgeListWrapper}>
              <p
                className={`${classes.title} boldText`}
                style={{ margin: isCard && "0 0 0.5rem 0", color: "black" }}
              >
                {filter?.name}
              </p>
            </div>
            <div className={classes.markdown} style={{ marginTop: "0.25rem" }}>
              {filter?.description !== "" && (
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {isCard && filter?.description.length > 230
                    ? filter.description.substring(0, 100) + "..."
                    : !isCard && filter?.description.length > 230
                    ? filter?.description.substring(0, 230) + "..."
                    : filter?.description}
                </ReactMarkdown>
              )}
            </div>
          </div>
        </div>
      </UnstyledButton>
      {!isCard && <Divider color={theme.colors.gray[2]} />}
    </div>
  );
};

export default Event;
