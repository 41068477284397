import React, { useEffect, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import Event from "./Event";
import { Container, Select } from "@mantine/core";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import { FaSortAmountDown } from "react-icons/fa";
import { isBefore } from "date-fns";
import classes from "./EventWrapper.module.css";

const EventWrapper = ({ date, items, events, isSorting, setIsSorting }) => {
  const { language, culture, config } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    config: state.config,
  }));
  const [value, setValue] = useState(null);
  const [mounted, setMounted] = useState(false);

  const hasChoosedSorting = sessionStorage.getItem("sorting");

  const sortByTime = (val) => {
    if (val === "timeAscending") {
      items.sort((a, b) => {
        let timeA = a.occasions.filter(
          (occasion) => occasion.remaining !== 0
        )?.[0]?.time;

        let timeB = b.occasions.filter(
          (occasion) => occasion.remaining !== 0
        )?.[0]?.time;

        if (!timeA) timeA = a?.occasions?.[0]?.time;
        if (!timeB) timeB = b?.occasions?.[0]?.time;

        if (isBefore(new Date(timeB), new Date(timeA))) return 1;
        else if (isBefore(new Date(timeA), new Date(timeB))) return -1;
        else return 0;
      });
      setIsSorting(false);
    }
    if (val === "timeDescending") {
      items.sort((a, b) => {
        let timeA = a.occasions.filter(
          (occasion) => occasion.remaining !== 0
        )?.[0]?.time;

        let timeB = b.occasions.filter(
          (occasion) => occasion.remaining !== 0
        )?.[0]?.time;

        if (!timeA) timeA = a?.occasions?.[0]?.time;
        if (!timeB) timeB = b?.occasions?.[0]?.time;

        if (isBefore(new Date(timeA), new Date(timeB))) return 1;
        else if (isBefore(new Date(timeB), new Date(timeA))) return -1;
        else return 0;
      });
      setIsSorting(false);
    }
    sessionStorage.setItem("sorting", val);
  };

  const sortByName = (val) => {
    if (val === "nameDescending") {
      items.sort((a, b) => {
        const nameA = a.translation
          .find((el) => el.culture === culture)
          .name.slice(0, 1)
          .toLowerCase();
        const nameB = b.translation
          .find((el) => el.culture === culture)
          .name.slice(0, 1)
          .toLowerCase();
        return nameB.localeCompare(nameA);
      });
      setIsSorting(false);
    }
    if (val === "nameAscending") {
      items.sort((a, b) => {
        const nameA = a.translation
          .find((el) => el.culture === culture)
          .name.slice(0, 1)
          .toLowerCase();
        const nameB = b.translation
          .find((el) => el.culture === culture)
          .name.slice(0, 1)
          .toLowerCase();
        return nameA.localeCompare(nameB);
      });
      setIsSorting(false);
    }
    sessionStorage.setItem("sorting", val);
  };

  const sortValue = (val) => {
    if (val === "nameAscending" || val === "nameDescending") sortByName(val);
    if (val === "timeAscending" || val === "timeDescending") sortByTime(val);
  };

  const listType =
    window.innerWidth < 768
      ? config?.allEventsListType?.sm
      : config?.allEventsListType?.lg;
  const isCard = listType
    ? listType === "CARD"
    : window.innerWidth < 768
    ? config.articleListType?.sm === "CARD"
    : config.articleListType?.lg === "CARD";

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.2,
        staggerChildren: 0.08,
      },
    },
  };
  const item = {
    hidden: { opacity: 0, scale: 0.95 },
    show: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0.95 },
  };

  useEffect(() => {
    if (!hasChoosedSorting && config?.allEventsDefaultSortBy) {
      setValue(config?.allEventsDefaultSortBy);
    } else setValue(hasChoosedSorting);
  }, [config]);

  useEffect(() => {
    if (items && items.length > 0 && !mounted) {
      if (hasChoosedSorting) sortValue(hasChoosedSorting);
      else sortValue(config?.allEventsDefaultSortBy);
      setMounted(true);
    }
    if (items && items.length > 0) {
      sortValue(value);
    }
  }, [items, config]);

  useEffect(() => {
    if (isSorting) {
      sortValue(value);
    }
  }, [isSorting]);

  return (
    <>
      <div className={classes.selectContainer}>
        <Select
          className={`${classes.selectWrapper} selectWrapper`}
          placeholder={language.SortLabel}
          comboboxProps={{ zIndex: 98 }}
          value={value}
          label={language.Sort}
          onChange={(value) => {
            setValue(value);
            sortValue(value);
          }}
          data={[
            {
              value: "timeAscending",
              label: language.TimeAscending,
            },
            {
              value: "timeDescending",
              label: language.TimeDescending,
            },
            { value: "nameAscending", label: language.NameAscending },
            {
              value: "nameDescending",
              label: language.NameDescending,
            },
          ]}
          leftSection={<FaSortAmountDown />}
          styles={{
            icon: { color: "black" },
          }}
        />
      </div>
      {items.length > 0 ? (
        <Container
          className={`${classes.container} ${isCard && classes.cardContainer}`}
          component={motion.div}
          variants={container}
          initial="hidden"
          animate="show"
          exit="exit"
          p={0}
          m={0}
        >
          <AnimatePresence initial={false}>
            {items.map((el, i) => (
              <motion.div
                variants={item}
                key={el.id}
                layout
                transition={{
                  type: "spring",
                  duration: 0.3,
                  layout: {
                    type: "spring",
                    damping: 50,
                    stiffness: 600,
                  },
                }}
              >
                <Event event={el} date={date} />
              </motion.div>
            ))}
          </AnimatePresence>
        </Container>
      ) : (
        events.length === 0 && <p>{language.AllEventsNoEvents}</p>
      )}
      {items.length === 0 && events.length > 0 && (
        <p>{language.AllEventsNoResults}</p>
      )}
    </>
  );
};

export default EventWrapper;
