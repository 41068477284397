const productPrice = (item) => {
  if (item && item.length > 0) {
    const regex = /[$€£kr]/g;
    let newPrice = item.replace(regex, "");
    if (newPrice.trim().split(".")[1] === "00") {
      return newPrice.split(".")[0];
    } else {
      return newPrice;
    }
  }
};

export const goToCheckoutTracking = (sale, currency) => {
  const trackReduceCart = Object.values(
    sale.items.reduce((acc, curr) => {
      acc[curr.key] = {
        id: curr.article.plu,
        quantity: curr.quantity,
        price: curr.pricePerPiece.amountInclVat,
        name: curr.article.name,
      };
      return acc;
    }, {})
  );
  const hasBegun = localStorage.getItem("hasBegunCheckout");
  const saleId = sale.id;
  if (window.dataLayer && hasBegun !== saleId) {
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        currency,
        value: parseFloat(
          productPrice(sale.sumInclVat.replace(/\s/g, "").replace(",", "."))
        ),
        items: trackReduceCart,
      },
    });
  }
};
