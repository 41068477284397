import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Divider,
  Flex,
  Image,
  Skeleton,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import slugify from "../common/slugify";
import { getSaleData } from "../Product/util";
import { addArticle, addToCartTracking } from "../../common/sale";
import { DataContext } from "../common/dataProvider";
import { SaleContext } from "../common/saleProvider";
import { getProduct } from "../../Pages/ProductPage/util";
import { STATE_CURRENCY } from "../../common/state";
import { BsCheck } from "react-icons/bs";
import classes from "./ListItemV2.module.css";
import placeholder from "../../images/placeholder.jpg";
import { getImage } from "./util";
import { Link } from "react-router-dom";

const ListItemV2 = ({ product, culture }) => {
  const [addedToCart, addedToCartSet] = useState(null);
  const [price, priceSet] = useState(null);
  const [clicked, clickedSet] = useState(false);
  const { config, language, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const rdxDispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const { data } = useContext(DataContext);
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;
  const regex = /\D/g;
  const reduce =
    product.products &&
    product.products.reduce((acc, curr) => {
      if (curr.priceInclVat) {
        acc.push(curr.priceInclVat);
      }
      return acc;
    }, []);

  const reduceChooseValue =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr) => {
      acc.push(curr);
      return acc;
    }, []);

  const sortPrice = (prices) => {
    if (prices && typeof prices?.[0] === typeof 0) {
      const sortOpenPrice = prices.sort((a, b) => a - b);
      return sortOpenPrice;
    } else if (prices && typeof prices?.[0] === typeof "") {
      const sortStringValue = prices.sort(
        (a, b) => a.replace(regex, "") - b.replace(regex, "")
      );
      return sortStringValue;
    }
  };

  const links = (isLink) => {
    const url = `/${product.slug}`;
    return isLink ? `${config?.self}${url}` : navigate(url);
  };

  useEffect(() => {
    const giftCard = config?.productConfig?.filter((el) =>
      el.description?.includes("presentkort")
    );
    const filterGiftCard = giftCard?.filter((el) => el.plu === product.plu);

    if (product?.priceInclVat && !reduce && !reduceChooseValue) {
      priceSet(product.priceInclVat);
    }

    if (product?.displayPrice && !reduce && !reduceChooseValue) {
      priceSet(product.displayPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length > 0
    ) {
      priceSet(
        `${language.FromCost} ${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 0
    ) {
      priceSet(language.OpenPrice);
    }
    if (
      filterGiftCard.length > 0 &&
      filterGiftCard[0].openPriceValues.length === 1
    ) {
      priceSet(
        `${filterGiftCard[0].openPriceValues[0]} ${language.CurrencyAbbreviation}`
      );
    }

    if (reduce) {
      priceSet(
        `${sortPrice(reduce)?.[0]}–${
          sortPrice(reduce)?.[sortPrice(reduce).length - 1]
        }`
      );
    }

    if (reduceChooseValue) {
      priceSet(
        `${sortPrice(reduceChooseValue)?.[0]} kr–${
          sortPrice(reduceChooseValue)?.[
            sortPrice(reduceChooseValue).length - 1
          ]
        } kr`
      );
    }
  }, [config, product]);

  const trackProductData = [
    {
      id: product.plu,
      name: product.name,
      price: product?.price?.amountInclVat,
      quantity: 1,
    },
  ];

  const handleAdd = () => {
    addToCartTracking(currency, trackProductData);
    clickedSet(true);
    const callback = (status, msg) => {
      if (status === 200) {
        clickedSet(false);

        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        clickedSet(false);

        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;
    let quantity = 1;
    const saleData = getSaleData(
      product,
      article,
      values,
      photo,
      quantity,
      overrideDate
    );
    rdxDispatch(addArticle(saleData, callback));
  };

  const image = useMemo(() => {
    return getImage(product, uri, config);
  }, [product.images]);

  const productData = useMemo(() => {
    if (sale) {
      return getProduct(data, config, product.plu, culture);
    }
  }, [data, product, config, culture, sale]);

  const added = useMemo(() => {
    if (window.offsetWidth >= 769) {
      return language.Added_To_Cart;
    } else {
      return <BsCheck className={classes.checkIcon} />;
    }
  }, [window]);

  const disableAdd = useCallback(
    (productItem) => {
      if (sale && sale?.items?.length > 0) {
        const getItem = sale?.items.filter(
          (item) => item?.article?.plu === productItem?.plu
        )?.[0];
        if (getItem?.quantity === productItem?.inStock) return true;
      }

      if (productItem?.inStock <= 0) return true;
      return false;
    },
    [sale?.items, productData]
  );

  if (!product || !productData)
    return (
      <>
        <div className={classes.skeleton}>
          <Skeleton height={96} width={96} radius="md" />
          <div className={classes.skeletonTextWrapper}>
            <Skeleton height={19} width="75%" radius="sm" />
            <Skeleton height={17} mb={16} width="25%" radius="sm" />
          </div>
          <Skeleton height={42} width={"100%"} radius="sm" mt={16} />
        </div>
        <Divider color={theme.colors.gray[2]} />
      </>
    );

  return (
    <>
      <div className={classes.wrapper}>
        <UnstyledButton
          className={`${classes.grid} listItemButton`}
          to={links(true)}
          component={Link}
        >
          <div>
            <Image
              radius="md"
              src={image}
              fit={"cover"}
              h={"6rem"}
              w={"6rem"}
              fallbackSrc={placeholder}
              alt={`${language.ProductAltLabel} ${product?.name}`}
            />
          </div>
          <Flex direction="column" gap="xs">
            <span className={`${classes.title} itemTitle`}>{`${product.name
              .slice(0, 1)
              .toUpperCase()}${product.name.slice(1)}`}</span>
            <span
              className={`${classes.price} product-price-list`}
              color={"gray.7"}
              weight={"500"}
            >
              {price}
            </span>
          </Flex>
        </UnstyledButton>
        <div
          className={classes.priceWrapper}
          role="region"
          aria-live="assertive"
        >
          {productData?.isProduct ? (
            <Button
              className={`add_to_cart_product ${
                !addedToCart && "regularButton"
              }`}
              classNames={{
                label: "add_to_cart_product",
                inner: "add_to_cart_product",
              }}
              size="md"
              onClick={() => handleAdd()}
              color={addedToCart ? "green" : "var(--theme-primary-color)"}
              loading={clicked}
              disabled={disableAdd(productData)}
            >
              {!addedToCart && (!product.inStock || product.inStock >= 1)
                ? language.Buy
                : !addedToCart && product.inStock <= 0
                ? language.SoldOut
                : addedToCart && added}
            </Button>
          ) : (
            <Button
              size="md"
              onClick={() => links(false)}
              variant="outline"
              className="productChooseBtn primaryButtonOutline"
              aria-label={`${language.Choose} ${product.name}`}
            >
              {language.Choose}
            </Button>
          )}
        </div>
      </div>
      <Divider size="sm" className={classes.divider} />
    </>
  );
};

export default ListItemV2;
