import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import Settings from "../Settings";

const Header = ({ progress, step, language }) => {
  const [isActive, setIsActive] = useState(false);

  const variants = {
    initial: {
      scale: 1,
    },
    active: {
      scale: [1, 1.5, 1],
    },
  };

  useEffect(() => {}, [step]);

  const circle = (el, i) => {
    if (step === i) {
      return (
        <motion.div
          className={`${
            step === i || step > i ? "progressCircle" : "progressCircleOutline"
          }`}
          // animate={isActive ? "active" : "initial"}
          style={{ paddingLeft: "1rem" }}
          animate="active"
          variants={variants}
          transition={{
            type: "spring",
            stiffness: 500,
            damping: 30,
            duration: 0.3,
          }}
          key={el + i}
        />
      );
    }

    if (step > i) {
      return (
        <motion.div
          className={`${"progressCircle"}`}
          animate={isActive ? "active" : "initial"}
          style={{ paddingLeft: "1rem" }}
          variants={variants}
          key={el + i}
        />
      );
    }

    if (step < i) {
      return (
        <motion.div
          className={`${"progressCircleOutline"}`}
          style={{ paddingLeft: "1rem" }}
          key={el + i}
        />
      );
    }
  };
  return (
    <div className="topHeader">
      <h1>{language.Header}</h1>
      <div style={{ position: "absolute", right: "1rem", top: "0.8rem", transform: "scale(1.25)", "WebkitTransform": "scale(1.4)"  }}>
        <Settings />
      </div>
      <div className="progressWrapper">
        <div className="progressLine" />
        <motion.div
          className="progressCircleWrapper"
          key="progressCircleWrapper"
        >
          {progress.map((el, i) => circle(el, i))}
        </motion.div>
      </div>
    </div>
  );
};

export default Header;
