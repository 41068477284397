import {
  ActionIcon,
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  List,
  NumberInput,
  Text,
  ThemeIcon,
} from "@mantine/core";
import React, { useContext, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, addToCartTracking } from "../../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import { getSaleData } from "../util";
import rehypeRaw from "rehype-raw";
import ReactMarkdown from "react-markdown";
import { motion } from "framer-motion";
import PersonalTicket from "./PersonalTicket";
import { SaleContext } from "../../common/saleProvider";
import { useDisclosure } from "@mantine/hooks";
import { HiMinusSm, HiPlusSm } from "react-icons/hi";
import classes from "./SingleProduct.module.css";
import placeholder from "../../../images/placeholder.jpg";
import OptionsModal from "../../Options/OptionsModal";

const SingleProduct = ({ product, description }) => {
  const { culture, language, currency } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));

  const [quantity, quantitySet] = useState(1);
  const [addedToCart, addedToCartSet] = useState(null);
  const [openPrice, openPriceSet] = useState(100);
  const [extraInformation, setExtraInformation] = useState(null);
  const handlers = useRef();
  const quantityChangeRef = useRef();
  const rdxDispatch = useDispatch();
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const [opened, { open, close }] = useDisclosure(false);

  const getTranslation = product?.translation.filter(
    (el) => el?.culture === culture
  )?.[0];

  const productData = [
    {
      id: product.plu,
      name: product?.name,
      price: product.price.amountInclVat,
      quantity: quantity,
    },
  ];

  const hasSetValueOnSale = useMemo(() => {
    return product?.additionalFields?.some((item) => item.setValueOnSale);
  }, [product]);

  const handleAdd = () => {
    if (product?.additionalFields && !opened && hasSetValueOnSale) {
      return open();
    }
    addToCartTracking(currency, productData);
    announceQuantityChange(quantity);

    const callback = (status, msg) => {
      if (status === 200) {
        quantitySet(1);
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        quantitySet(1);
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let article;
    let values;
    let photo;
    let overrideDate;

    const saleData = !product.isOpenPrice
      ? getSaleData(
          product,
          article,
          values,
          photo,
          quantity,
          overrideDate,
          extraInformation
        )
      : {
          plu: product.plu,
          quantity: quantity,
          priceInclVat: openPrice * 100,
        };
    rdxDispatch(addArticle(saleData, callback));
  };

  const disabledIncrement = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === product?.plu
      )?.[0]?.quantity;
      const calc = product?.inStock - (qty ? qty : 0);
      if (quantity >= calc) {
        return true;
      }
    }
  }, [sale, product, quantity]);

  const disabledAdd = useMemo(() => {
    if (sale) {
      const qty = sale.items.filter(
        (el) => el.article.plu === product?.plu
      )?.[0]?.quantity;
      const calc = product?.inStock - (qty ? qty : 0);
      if (
        quantity > calc ||
        quantity === undefined ||
        (product.isOpenPrice && !openPrice)
      ) {
        return true;
      }
    }
  }, [sale, product, quantity, openPrice]);

  const isAddedToCart = sale?.items.filter(
    (el) => el.article.plu === product.plu
  );

  const announceQuantityChange = (newQuantity) => {
    quantityChangeRef.current.innerText = `${language.Quantity} ${newQuantity}`;
  };

  const increment = () => {
    handlers.current.increment();
    announceQuantityChange(quantity + 1);
  };

  const decrement = () => {
    handlers.current.decrement();
    announceQuantityChange(quantity - 1);
  };

  return (
    <Container className={classes.containerWrapper}>
      <Grid gutter={28}>
        <Grid.Col span={{ sm: 12, md: 7 }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: "100%" }}
          >
            <Image
              src={product.images && product.images}
              fallbackSrc={placeholder}
              radius={"sm"}
              fit="cover"
              h={"auto"}
              alt={`${language.ProductAltLabel} ${getTranslation?.name}`}
            />
          </motion.div>
        </Grid.Col>
        <Grid.Col span={{ sm: 12, md: 5 }} className={classes.container}>
          <div className={classes.titleWrapper}>
            <h1 className="productTitle" style={{ fontSize: "1.5rem" }}>
              {getTranslation?.name}
            </h1>
          </div>
          <Grid cols={2} className={`${classes.stockWrapper} stockWrapper`}>
            <Grid.Col span={6}>
              <List
                size="sm"
                center
                className={`${classes.list} stockIndicatorSingle`}
              >
                {product.isProduct && (
                  <List.Item
                    icon={
                      <ThemeIcon
                        color={product.inStock > 0 ? "#40C057" : "#FA5252"}
                        size={19}
                        radius="xl"
                      >
                        <div />
                      </ThemeIcon>
                    }
                  >
                    <p style={{ paddingTop: "0.1rem", marginLeft: "-0.2rem" }}>
                      {product.inStock > 0
                        ? language.In_Stock
                        : language.Not_In_Stock}
                    </p>
                  </List.Item>
                )}
              </List>
            </Grid.Col>
            <Grid.Col
              span={6}
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <span className={`${classes.price} product-price`}>
                {!product.isOpenPrice ? product.priceInclVat : ""}
              </span>
            </Grid.Col>
          </Grid>
          {product.isPersonalTicket && <PersonalTicket />}
          {product.isOpenPrice && (
            <div style={{ marginTop: "1rem" }}>
              <NumberInput
                label={language.OpenPrice}
                defaultValue={100}
                stepHoldDelay={500}
                stepHoldInterval={100}
                size="md"
                onChange={(val) => openPriceSet(val)}
                min={1}
                icon={
                  <Text component={"span"} c="gray.7">
                    kr
                  </Text>
                }
                parser={(value) => value.replace(/[$€]\s?|(,*)/g, "")}
                formatter={(value) =>
                  !Number.isNaN(parseFloat(value))
                    ? `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, "")
                    : ""
                }
              />
            </div>
          )}
          <div className={`${classes.actionGrid} product-button-wrapper`}>
            <div
              className={classes.hideLabel}
              role="region"
              aria-live="assertive"
              ref={quantityChangeRef}
            />
            <Group
              gap={5}
              styles={{ height: 42 }}
              className={classes.actionWrapper}
            >
              <ActionIcon
                className={`${classes.actionButton} addToCart`}
                size={35}
                color="gray"
                radius="50"
                variant="filled"
                disabled={
                  product.inStock <= 0 ||
                  quantity === 1 ||
                  product.isGiftCertificate
                }
                onClick={() => decrement()}
                aria-label={`${getTranslation?.name} ${language.DecreaseQuantity}`}
              >
                <div style={{ fontSize: "1.5rem", height: "24px" }}>
                  <HiMinusSm />
                </div>
              </ActionIcon>
              <NumberInput
                hideControls
                value={quantity}
                aria-label={`${getTranslation?.name} ${language.Quantity} ${quantity}`}
                onChange={(val) => quantitySet(val)}
                handlersRef={handlers}
                max={product.inStock}
                min={1}
                step={1}
                disabled={
                  product?.additionalFields?.length > 0 ||
                  product.inStock <= 0 ||
                  (product.isOpenPrice && !openPrice) ||
                  product.isGiftCertificate
                }
                styles={{
                  input: {
                    backgroundColor: "transparent",
                    border: "none",
                    width: 35,
                    textAlign: "center",
                    height: 35,
                    fontWeight: "600",
                    fontSize: "1.25rem",
                    padding: 0,
                  },
                }}
              />
              <ActionIcon
                aria-label={`${getTranslation?.name} ${language.AddQuantity}`}
                className={`${classes.actionButton} addToCart`}
                size={35}
                color="gray"
                radius="50"
                variant="filled"
                disabled={
                  product?.additionalFields?.length > 0 ||
                  disabledIncrement ||
                  (product.isOpenPrice && !openPrice) ||
                  product.isGiftCertificate
                }
                onClick={() => increment()}
              >
                <div style={{ fontSize: "1.5rem", height: "24px" }}>
                  <HiPlusSm />
                </div>
              </ActionIcon>
            </Group>

            <div
              className={classes.alertWrapper}
              role="region"
              aria-live="polite"
            >
              <Button
                variant="filled"
                size="md"
                className="regularButton add_to_cart_product"
                classNames={{
                  root: addedToCart && classes.addedToCart,
                  label: "add_to_cart_product",
                  inner: "add_to_cart_product",
                }}
                onClick={handleAdd}
                disabled={disabledAdd}
                style={{
                  width: "100%",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                {!product.isOpenPrice &&
                disabledAdd &&
                isAddedToCart?.length > 0
                  ? language.MaxInCart
                  : !product.isOpenPrice &&
                    disabledAdd &&
                    language.Not_In_Stock}

                {!disabledAdd && addedToCart
                  ? language.Added_To_Cart
                  : !disabledAdd && language.AddToCartBtn}

                {product.isOpenPrice && disabledAdd && language.AddToCartBtn}
              </Button>
            </div>
          </div>

          <Divider my="lg" />
          <h2 className={`${classes.descriptionTitle} descriptionTitle`}>
            {language.Description}
          </h2>

          <div className={`${classes.descriptionBody} productDescription`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {description ? description : getTranslation?.description}
            </ReactMarkdown>
          </div>
        </Grid.Col>
      </Grid>
      {product?.additionalFields?.length > 0 && (
        <OptionsModal
          title={getTranslation?.name}
          opened={opened}
          close={close}
          additionalFields={product.additionalFields}
          handleAdd={handleAdd}
          extraInformation={extraInformation}
          setExtraInformation={setExtraInformation}
        />
      )}
    </Container>
  );
};

export default SingleProduct;
