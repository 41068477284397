import {
  Button,
  Container,
  Image,
  SimpleGrid,
  Skeleton,
  Space,
  Text,
  Title,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import React, { useEffect, useState } from "react";
import { isAfter, isSameMonth } from "date-fns";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import TimePicker from "./TimePicker";
import "./styles.css";
import _ from "lodash";
import EventCalendarV2 from "./Calendar";
import { useNavigate, useLocation } from "react-router";
import classes from "./EventV2.module.css";
import placeholder from "../../../images/placeholder.jpg";

const EventV2 = (props) => {
  const [isLoading, isLoadingSet] = useState(true);
  const [text, textSet] = useState("");
  const [event, eventSet] = useState(null);
  const [day, daySet] = useState(null);
  const [lastOccasion, lastOccasionSet] = useState();
  const [firstOccasion, firstOccasionSet] = useState();
  const [month, monthSet] = useState(new Date());
  const [initialMonth, initialMonthSet] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [cultureLocale, setCultureLocale] = useState();
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const isDesktop = useMediaQuery("(min-width: 768px)");
  const image = `${uri}/allotmentimage/${props?.event?.imageFileName}`;
  const location = useLocation();
  const navigate = useNavigate();
  const filter = location.state;
  let pathname = location.pathname;

  useEffect(() => {
    firstOccasionSet(_.first(props.event.occasions));
    lastOccasionSet(_.last(props.event.occasions));
  }, [props.event.occasions]);

  useEffect(() => {
    daySet(null);
    if (props.event.occasions) {
      const getEventByMonth =
        props.event.occasions &&
        props.event.occasions.filter((el) =>
          isSameMonth(new Date(el.time), new Date(currentDate))
        );
      if (getEventByMonth) {
        eventSet(getEventByMonth);
      }
    }
  }, [currentDate, pathname, month]);

  useEffect(() => {
    if (Object.keys(props.event).length > 1) {
      isLoadingSet(false);
    }
    const filterTextByCulture = props.event.translation.filter(
      (el) => el.culture === culture
    );
    if (filterTextByCulture) {
      textSet({
        name: filterTextByCulture[0]?.name,
        description: filterTextByCulture[0]?.description,
      });
    }
  }, [props, culture]);

  useEffect(() => {
    if (filter?.[0] && filter?.[1] && props.event.occasions.length > 0) {
      const reduce = props.event.occasions.reduce((acc, curr) => {
        if (isAfter(new Date(curr.time), filter[0])) {
          acc.push(curr.time);
        }
        return acc;
      }, []);
      if (reduce && reduce.length > 0) {
        initialMonthSet(new Date(reduce[0]));
      }
    } else {
      initialMonthSet(new Date(props.event.occasions[0]?.time));
    }
  }, [filter, props.event.occasions, pathname]);

  return (
    event && (
      <>
        <Container className={classes.buttonWrapper}>
          <Button
            className={`${classes.button} backButton`}
            variant="default"
            mb={2}
            onClick={() => navigate(-1)}
          >
            {language.Back}
          </Button>
        </Container>
        <Container style={{ padding: isDesktop ? "0" : "1rem 0" }}>
          <Skeleton visible={isLoading}>
            <SimpleGrid
              spacing={"xl"}
              cols={isDesktop ? 2 : 1}
              breakpoints={[
                {
                  maxWidth: 768,
                  cols: 1,
                  spacing: "sm",
                },
              ]}
            >
              <div className="event-image-wrapper">
                <Image
                  className="event-image"
                  src={image}
                  fallbackSrc={placeholder}
                  h={"auto"}
                  mah={isDesktop ? "500px" : "20rem"}
                  // h={!props.event.ImageFileName ? "20rem" : "100%"}
                  alt={`${language.EventAltLabel} ${text?.name}`}
                />
              </div>
              <Container style={{ padding: 0, width: "100%" }}>
                <Title mb="lg" order={1}>
                  {text.name}
                </Title>

                <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                  {text.description}
                </ReactMarkdown>
              </Container>
            </SimpleGrid>
          </Skeleton>
          <Space h={isDesktop ? "xl" : "lg"} />
          <Space h={isDesktop ? "xl" : "lg"} />

          <SimpleGrid
            spacing="xl"
            cols={isDesktop ? 2 : 1}
            breakpoints={[{ maxWidth: 768, cols: 1 }]}
          >
            <SimpleGrid cols={1} spacing="xs" style={{ height: "fit-content" }}>
              <Title
                id="event-date-title"
                order={2}
                style={{ fontSize: "1.25rem" }}
              >
                {language.ChooseDateHeader}
              </Title>
              <Text
                size="s"
                component="small"
                c="gray.7"
                fw={400}
                style={{ marginTop: "-0.5rem", marginBottom: "1rem" }}
              >
                {language.ChooseDateDescription}
              </Text>
              <EventCalendarV2
                cultureLocale={cultureLocale}
                setCultureLocale={setCultureLocale}
                currentDate={currentDate}
                setCurrentDate={setCurrentDate}
                firstOccasion={firstOccasion}
                lastOccasion={lastOccasion}
                occasions={event}
                month={month}
                monthSet={monthSet}
                day={day}
                daySet={daySet}
                initialMonth={initialMonth}
              />
            </SimpleGrid>

            <div
              className="time-picker-container"
              style={{ opacity: !day ? 0.35 : 1 }}
            >
              <TimePicker
                day={day}
                allotment={props.event}
                cultureLocale={cultureLocale}
              />
            </div>
          </SimpleGrid>
        </Container>
      </>
    )
  );
};
export default EventV2;
