import {
  Container,
  Image,
  Popover,
  Select,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addArticle, addToCartTracking } from "../../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../common/state";
import { getSaleData } from "../util";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { motion } from "framer-motion";
import PersonalTicketForm from "./PersonalTicketForm";
import autoAnimate from "@formkit/auto-animate";
import axios from "axios";
import classes from "./PersonalTicket.module.css";
import placeholder from "../../../images/placeholder.jpg";
import { useLocation } from "react-router-dom";

const PersonalTicket = ({ product }) => {
  const [addedToCart, addedToCartSet] = useState(null);
  const [photo, photoSet] = useState(null);
  const { config, culture, language, currency } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));

  const rdxDispatch = useDispatch();
  const [value, setValue] = useState(null);
  const [text, setText] = useState("");
  const parent = useRef(null);
  const location = useLocation();

  const itemsArr =
    product?.articles &&
    product?.articles.length > 0 &&
    product.articles.reduce((acc, curr, index) => {
      acc.push({
        key: index,
        value: curr.id,
        label:
          curr.inStock <= 0
            ? curr.name + " " + language.ArticleBooking.OutOfStock
            : curr.name + " " + curr.priceInclVat,
        disabled: curr.inStock <= 0,
      });
      return acc;
    }, []);

  const productData = useMemo(() => {
    if (value && product?.articles) {
      const getProduct = product?.articles.filter((el) => el.id === value)?.[0];
      return [
        {
          id: getProduct.plu,
          name: getProduct.name,
          price: getProduct?.price?.amountInclVat,
          quantity: 1,
        },
      ];
    } else {
      return product;
    }
  }, [value]);

  const salesProductData = useMemo(() => {
    if (value && product?.articles) {
      const getProduct = product?.articles.filter((el) => el.id === value)?.[0];
      return getProduct;
    } else {
      return product;
    }
  }, [value, product]);

  const img = useMemo(() => {
    if (salesProductData && value) {
      return `${config?.apiUri}/articleimage/${salesProductData.imageFilename}`;
    } else {
      return salesProductData?.images?.[0];
    }
  }, [salesProductData]);

  const getTranslation = salesProductData?.translation?.filter(
    (el) => el.culture === culture
  )?.[0];

  const handleAdd = (values) => {
    const callback = (status, msg) => {
      if (status === 200) {
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };
    let quantity = 1;
    let overrideDate;
    const saleData = getSaleData(
      salesProductData,
      salesProductData.id,
      values,
      photo,
      quantity,
      overrideDate
    );
    addToCartTracking(currency, productData);
    rdxDispatch(addArticle(saleData, callback));
  };

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    if (product?.articles) {
      axios
        .get(`${config.baseUrl}${product?.description}.${culture}.md`)
        .then((res) => setText(res.data))
        .catch(() => setText(""));
    }
  }, [product, config]);

  useEffect(() => {
    setValue(null);
  }, [location]);

  if (!product) return null;
  return (
    <div>
      <Container className={classes.containerWrapper}>
        <div className={classes.wrapper}>
          <motion.div
            className={classes.imageContainer}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: "100%" }}
          >
            <Image
              src={img}
              fallbackSrc={product?.images?.[0]}
              radius={"sm"}
              className={`${classes.image} personalImage`}
              alt={language.TicketPhotoAltLabel}
              h={"auto"}
            />
          </motion.div>
          <div>
            <div className={classes.titleWrapper}>
              <h1 className="productTitle" style={{ fontSize: "1.5rem" }}>
                {getTranslation?.name
                  ? getTranslation?.name
                  : salesProductData?.name &&
                    `${salesProductData?.name
                      .split("")?.[0]
                      .toUpperCase()}${salesProductData?.name.slice(1)}`}
              </h1>
            </div>
            <SimpleGrid cols={1} className={classes.stockWrapper}>
              <Text
                className={`${classes.price} product-price`}
                component="span"
              >
                {!product.isOpenPrice ? salesProductData?.priceInclVat : ""}
              </Text>
            </SimpleGrid>
            {product?.articles && (
              <Select
                label={language.ChooseVariant}
                placeholder={language.Product}
                comboboxProps={{ zIndex: 98 }}
                size="md"
                value={value}
                onChange={setValue}
                data={itemsArr}
                style={{
                  paddingTop: 0,
                  paddingBottom: ".5rem",
                }}
              />
            )}
            <h2
              className={`${classes.descriptionTitle} personalTicketFormTitle descriptionTitle`}
            >
              {language.Description}
            </h2>
            <div className={classes.descriptionBody}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {getTranslation?.description
                  ? getTranslation?.description
                  : text}
              </ReactMarkdown>
            </div>
          </div>
        </div>

        <SimpleGrid>
          <div ref={parent}>
            {(product?.isPersonalTicket || value) && (
              <div
                className={
                  product?.articles && !value
                    ? "personal-ticket-form-not-available"
                    : ""
                }
              >
                <PersonalTicketForm
                  photo={photo}
                  photoSet={photoSet}
                  handleAdd={handleAdd}
                  addedToCart={addedToCart}
                />

                <div className={classes.actionGrid}>
                  <div className={classes.alertWrapper}>
                    <Popover
                      width="target"
                      withArrow
                      shadow="md"
                      opened={addedToCart === false}
                    >
                      <Popover.Target>
                        <div className={classes.alertContainer} />
                      </Popover.Target>
                      <Popover.Dropdown>
                        <Text size="md" component="p">
                          {language.CouldNotAddProduct}
                        </Text>
                      </Popover.Dropdown>
                    </Popover>
                  </div>
                </div>
              </div>
            )}
          </div>
        </SimpleGrid>
      </Container>
    </div>
  );
};

export default PersonalTicket;
