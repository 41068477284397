import React from "react";
import PageContent from "../../components/Styles/PageContent";
import PageLayout from "../../components/Styles/PageLayout";
import MoveOccasions from "../../components/MoveOccasions";

const MoveOccasionsPage = () => {
  return (
    <PageLayout>
      <PageContent>
          <MoveOccasions/>
      </PageContent>
    </PageLayout>
  );
};

export default MoveOccasionsPage;
