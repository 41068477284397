import classes from "./Options.module.css";
import { useContext } from "react";
import { SaleContext } from "../common/saleProvider";
import OptionsItem from "./OptionsItems";
import { itemVariants } from "./variants";
import { motion } from "framer-motion";

const EventItem = ({ data, allotmentId }) => {
  const { articles } = data;
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  return (
    <div className={classes.itemWrapper}>
      {articles.map((article, i) => (
        <motion.div
          key={`article-${article.id}`}
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={itemVariants}
          transition={{ delay: 0.03 * i }}
        >
          <OptionsItem
            allotmentId={allotmentId}
            item={article}
            sale={sale}
            isLastItem={i + 1 === articles.length}
          />
        </motion.div>
      ))}
    </div>
  );
};

export default EventItem;
