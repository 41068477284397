import React, { useEffect, useRef, useState } from "react";
import format from "date-fns/format";
import { isBefore, parseISO } from "date-fns";
import "./styles.css";
import { motion } from "framer-motion";
import { STATE_CULTURE } from "../../common/state";
import { useSelector } from "react-redux";
import AlertIcon from "../Drawer/AlertIcon";
import { Button } from "@mantine/core";
import CalendarIcon from "./icons/calendarIcon";

const TimeChooser = ({
  chosenDate,
  setChosenDate,
  selectedDate,
  filteredData,
  dataContext,
  allotmentSettings,
  activeScreen,
  setActiveScreen,
  chooseTime,
  setChooseTime,
  setSelected,
  setActiveOptionScreen,
  scrollIntoView,
  language,
  cultureLocale,
  pathname,
  singleOccasion,
  setSingleOccasion,
  activeTicketConfigurator,
  setActiveTicketConfigurator,
}) => {
  const [occasionsData, setOccasionsData] = useState();
  const changeDateBtn = useRef(null);
  const formatDay = "yyyy-MM-dd";
  const formatTitle = "EEEE d MMMM";

  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));

  useEffect(() => {
    const getActiveTicketConfigurator = config?.ticketConfigurator.filter(
      (el) => el.slug.toLowerCase() === pathname.split("/").pop()
    );
    setActiveTicketConfigurator(getActiveTicketConfigurator[0]);
    setChosenDate(undefined);
    setSelected(false);
    setActiveScreen(false);
    setActiveOptionScreen(false);
  }, [pathname]);

  useEffect(() => {
    setChooseTime(null);
    if (occasionsData && occasionsData.length === 1) {
      setSingleOccasion(true);
    } else {
      setSingleOccasion(false);
    }
  }, [occasionsData]);

  useEffect(() => {
    if (chosenDate !== undefined) {
      setActiveScreen(true);
    }
    if (chosenDate === undefined) {
      setActiveScreen(false);
    }
  }, [pathname, chosenDate]);

  const removeChars = (price) => {
    if (price) {
      let newPrice = price && price.replaceAll(/[^0-9\,.]/g, "");
      return newPrice.replace(",", "");
    }
  };

  const getAvailability = (remaining) => {
    if (
      allotmentSettings !== undefined &&
      allotmentSettings[0].showColors === true
    ) {
      if (remaining >= parseInt(allotmentSettings[0].lowTicketQty)) {
        return "calendar-available";
      }
      if (
        remaining <= parseInt(allotmentSettings[0].lowTicketQty) &&
        remaining > 0
      ) {
        return "calendar-low";
      }
      if (remaining === 0) {
        return "calendar-full";
      }
    }

    if (
      allotmentSettings !== undefined &&
      allotmentSettings[0].showColors === false
    ) {
      if (remaining > parseInt(allotmentSettings[0].lowTicketQty)) {
        return "calendar-nocolorallotment";
      }
      if (
        remaining <= parseInt(allotmentSettings[0].lowTicketQty) &&
        remaining > 0
      ) {
        return "calendar-nocolorallotment";
      }
      if (remaining === 0) {
        return "disabled";
      }
    }
  };
  const getPrice = (remaining, price) => {
    let newPrice = removeChars(price);
    if (
      allotmentSettings[0] !== undefined &&
      allotmentSettings[0].showPrice === true &&
      newPrice > 0
    ) {
      if (remaining === 0) {
        return <span className="calendar-price">{language.FullAllotment}</span>;
      }

      if (remaining > 0 && newPrice > 0) {
        return (
          <span className="calendar-price">
            {language.CostFromAbbreviation} {price}
          </span>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (chosenDate !== undefined) {
      const filterDataByDay = filteredData.filter(
        (el) =>
          format(parseISO(el.time), formatDay) === format(chosenDate, formatDay)
      );
      filterDataByDay.sort(
        (a, b) => new Date(a?.timeOccasions) - new Date(b?.timeOccasions)
      );
      setOccasionsData(filterDataByDay);
    }
  }, [filteredData, chosenDate]);

  const resetDate = () => {
    scrollIntoView("NewCalendar");
    setChosenDate(undefined);
    setSelected(false);
    setActiveScreen(false);
    setActiveOptionScreen(false);
    setChooseTime();
    setSingleOccasion();
  };

  const chooseOccasion = (occasion) => {
    setChooseTime(occasion);
    setSelected(occasion);
    scrollIntoView("ArticleChooser");
    setActiveOptionScreen(true);
  };

  useEffect(() => {
    if (
      activeTicketConfigurator?.hideTimeChooserIfSingleOccasion === true &&
      singleOccasion === true &&
      chosenDate
    ) {
      setChooseTime(occasionsData?.[0]);
      setSelected(occasionsData?.[0]);
      setActiveOptionScreen(true);
    } else {
      setChooseTime("");
      setSelected(false);
      setActiveOptionScreen(false);
    }
  }, [activeTicketConfigurator, occasionsData, singleOccasion]);

  let time = [];

  if (occasionsData !== undefined) {
    const filterOutByIds = [
      ...occasionsData
        .reduce((a, b) => {
          a.set(b.allotment.id, b);
          return a;
        }, new Map())
        .values(),
    ];
    const mapOutIds = filterOutByIds.map((el) => el.allotment.id);

    const checkAllotmentId = (allotments) => {
      let allotmentData = [];
      allotmentData.push(dataContext.allotments);

      const filterIds = filterOutByIds.filter(
        (el) => el.allotment.id === allotments.allotment.id
      );
      const filterDataByIds = allotmentData
        .flat()
        .filter((el) => el.id === filterIds[0].allotment.id);
      return filterDataByIds[0].description;
    };

    for (let i = 0; i < occasionsData.length; i++) {
      time.push(
        <div>
          {isBefore(new Date(occasionsData[i].timeOccasions), new Date()) ? (
            <div className="disabled noPointerEvents">
              <p>
                {mapOutIds.length > 1 && mapOutIds.length < occasionsData.length
                  ? checkAllotmentId(occasionsData[i])
                  : ""}
              </p>
              <div>
                <div
                  key={occasionsData[i].timeOccasions + i}
                  className={`calendar-occasionTime ${getAvailability(
                    occasionsData[i].remaining
                  )} ${
                    occasionsData[i] === chooseTime
                      ? "calendar-selectedTime"
                      : ""
                  }`}
                  onClick={() => chooseOccasion(occasionsData[i])}
                >
                  <p
                    key={occasionsData[i].timeOccasions}
                    className="calendar-occasionTimeText"
                  >
                    {format(new Date(occasionsData[i].timeOccasions), "HH:mm")}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <p>
                {mapOutIds.length > 1 && mapOutIds.length < occasionsData.length
                  ? checkAllotmentId(occasionsData[i])
                  : ""}
              </p>
              <div>
                <motion.div
                  key={occasionsData[i].timeOccasions + i}
                  className={`calendar-occasionTime ${getAvailability(
                    occasionsData[i].remaining,
                    occasionsData[i].price
                  )} ${
                    occasionsData[i] === chooseTime
                      ? "calendar-selectedTime"
                      : ""
                  }`}
                  onClick={() => chooseOccasion(occasionsData[i])}
                >
                  <p
                    key={occasionsData[i].timeOccasions}
                    className="calendar-occasionTimeText"
                  >
                    {format(new Date(occasionsData[i].timeOccasions), "HH:mm")}
                  </p>

                  {getPrice(
                    occasionsData[i].remaining,
                    occasionsData[i].price
                      .filter((el) => el > 0)
                      .sort((a, b) => a - b)[0]
                  )}
                </motion.div>
              </div>
            </div>
          )}
        </div>
      );
    }
  }

  const filterOutByIds = occasionsData && [
    ...occasionsData
      .reduce((a, b) => {
        a.set(b.allotment.id, b);
        return a;
      }, new Map())
      .values(),
  ];

  let res = [];
  const mapAllIds =
    filterOutByIds && filterOutByIds.map((el) => el.allotment.id);
  res.push(mapAllIds);

  const checkAllotmentId = (allotments, i) => {
    let allotmentData = [];
    allotmentData.push(dataContext.allotments);

    const filterDataByIds =
      allotmentData &&
      allotmentData.flat().filter((el) => el.id === allotments);

    const filterDataByCulture =
      filterDataByIds[0] &&
      filterDataByIds[0]?.translation.filter((el) => el.culture === culture);

    return filterDataByCulture && filterDataByCulture?.[0]?.name;
  };

  const removeChar = (price) => {
    if (price) {
      let newPrice = price && price.replaceAll(/[^0-9\,.]/g, "");
      return newPrice.replace(",", "");
    }
  };

  useEffect(() => {
    const calendarTimePicker = document.getElementById("calendar-time-picker");
    if (calendarTimePicker && occasionsData?.length > 0) {
      setTimeout(() => {
        calendarTimePicker.focus();
      }, 1000);
    }
  }, [occasionsData]);

  return (
    <div id="calendar-time-picker" tabIndex={0}>
      {occasionsData &&
      (!activeTicketConfigurator?.hideTimeChooserIfSingleOccasion ||
        occasionsData?.length > 1) ? (
        <div
          data-isactive={activeScreen === false ? false : true}
          className={`calendar-timeChooserWrapper ${
            activeScreen === false ? "calendar-hideScreen" : ""
          }`}
        >
          <h2 className="calendar-header">{language.ChooseTimeHeader}</h2>

          <div
            className={`calendar-timeChooserDate ${
              activeScreen === false ? "calendar-hideItems" : ""
            }`}
          >
            <h3 className="calendar-subHeader">
              {format(selectedDate, formatTitle, { locale: cultureLocale })}{" "}
            </h3>
            <Button
              ref={changeDateBtn}
              onClick={() => resetDate()}
              className="calendar-changeDateBtn primaryButtonOutline"
              aria-label={language.ChangeDateBtn}
              variant="outline"
              leftSection={<CalendarIcon />}
              size="lg"
            >
              {language.ChangeDateBtn}
            </Button>
            <div>
              {res &&
                res.flat().map((allotmentId, i) => (
                  <div
                    className="calendar-occasionWrapper"
                    key={allotmentId + i}
                  >
                    <p className="calendar-occasionHeader">
                      {checkAllotmentId(allotmentId)}
                    </p>
                    <div className="calendar-occasionTimeWrapper">
                      {occasionsData &&
                        occasionsData.map((el, i) =>
                          el.allotment.id === allotmentId ? (
                            <button
                              key={el.timeOccasions + i}
                              className={`removeBorderBg calendar-occasionTime ${getAvailability(
                                el.remaining,
                                el.price
                              )} ${
                                el === chooseTime ? "calendar-selectedTime" : ""
                              }`}
                              onClick={() => chooseOccasion(el)}
                              aria-label={`${checkAllotmentId(
                                allotmentId
                              )} ${format(new Date(el.timeOccasions), "HH:mm", {
                                locale: cultureLocale,
                              })} ${el === chooseTime ? language.Chosen : ""}`}
                              style={{ position: "relative" }}
                            >
                              {getAvailability(occasionsData[i].remaining) ===
                                "calendar-low" && (
                                <div
                                  className="few-left-indicator"
                                  style={{
                                    position: "absolute",
                                    left: ".4rem",
                                    top: ".3rem",
                                    width: "1rem",
                                    height: "1rem",
                                  }}
                                >
                                  <AlertIcon />
                                </div>
                              )}
                              <span
                                key={el.timeOccasions}
                                className="calendar-occasionTimeText"
                              >
                                {format(new Date(el.timeOccasions), "HH:mm")}
                              </span>
                              {getPrice(
                                el.remaining,
                                el.price
                                  .filter((el) => removeChar(el) > 0)
                                  .sort(
                                    (a, b) => removeChar(a) - removeChar(b)
                                  )[0]
                              )}
                            </button>
                          ) : (
                            ""
                          )
                        )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default TimeChooser;
