export const getProductConfig = (
  config,
  item,
  url,
  baseUrl,
  allotmentImage = undefined
) => {
  const plu = item.article.plu;
  const isModification =
    !item.article.isPersonalTicket && item.ticketOwners.length > 0;
  const prodConf = config.productConfig.find((a) => a.plu === plu);

  let productGroupConfig = config.productGroupConfig.find((c) =>
    prodConf?.name?.includes(c?.name)
  );
  const articleImage = plu && item.article.imageFilename;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const imageUri =
    plu && item.article.imageFilename
      ? `${uri}/articleimage/${articleImage}`
      : plu && item.allotmentId
      ? `${uri}/allotmentimage/${allotmentImage}`
      : undefined;

  return {
    thumbnail: imageUri ? imageUri : undefined,
    slug: isModification
      ? null
      : productGroupConfig?.useGroupsAsItems
      ? null
      : item.article.isProduct
      ? `${baseUrl}/products/${plu}`
      : null,
  };
};

export const getCartArticle = (
  product,
  config,
  article,
  eventImage = undefined
) => {
  let productGroupConfig = config.productGroupConfig.find(
    (c) => c.name === product.name
  );

  const groupArticle =
    productGroupConfig?.useGroupsAsItems &&
    product.articles &&
    product.articles.find((a) => a.id === article);
  const prodConf =
    productGroupConfig?.useGroupsAsItems && product.articles
      ? config.productConfig.find((c) => c.plu === groupArticle.plu)
      : config.productConfig.find((c) => c.plu === product.plu);
  const a = productGroupConfig?.useGroupsAsItems ? groupArticle : product;
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const imageUri = article ? `${uri}/articleimage/${article}.jpg` : undefined;
  if (!prodConf) return { ...a, apiUri: uri, eventImage };
  return {
    ...a,
    cartImage: imageUri ? imageUri : undefined,
    apiUri: uri,
  };
};
