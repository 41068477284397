import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CallbackComponent } from "redux-oidc";

const MyCallbackPage = (props) => {
  console.log("callback props:", props);
  const navigate = useNavigate();
  const successCallback = () => navigate("/");

  const errorCallback = (err) => {
    console.log("error callback:", err);
    navigate("/");
  };

  useEffect(() => {
    navigate("/");
  }, []);

  return (
    <CallbackComponent
      successCallback={successCallback}
      errorCallback={errorCallback}
      userManager={props.userManager}
    >
      <div>Redirect...</div>
    </CallbackComponent>
  );
};

export default MyCallbackPage;
