import React from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import Menu from "./Menu";
import { useMediaQuery } from "@mantine/hooks";

const Header = () => {
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const isMobile = useMediaQuery("(max-width: 768px)");
  if (!config) return null;
  return (
    <>
      <a
        href="#main-page-content"
        id="main-content-link"
        style={{
          height: isMobile ? "var(--header-height-xs)" : "var(--header-height)",
        }}
      >
        {language.SkipToMainContent}
      </a>
      <Menu config={config} language={language} culture={culture} />
    </>
  );
};

export default Header;
