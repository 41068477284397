import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import {
  STATE_CULTURE,
  STATE_CURRENCY,
  STATE_SALE_ID,
} from "../../common/state";
import { RefetchSaleQuery } from "./refetch";

const query = gql`
  query SaleQuery(
    $saleId: Guid
    $posId: Int
    $culture: String
    $currency: String
  ) {
    sale(
      saleId: $saleId
      posId: $posId
      culture: $culture
      currency: $currency
    ) {
      id
      version
      isActive
      isCreated
      sumInclVat
      itemQuantity
      firstArticleExpiresAt
      items {
        expires
        key
        quantity
        totalPriceInclVat
        information
        canRemove
        canChangeQuantity
        pricePerPieceInclVat
        isMultiTicket
        extraInformation {
          name
          value
        }
        multiTickets {
          categoryName
          quantity
        }
        type
        pricePerPiece {
          amountInclVat
        }
        totalPrice {
          amountInclVat
        }
        article {
          name
          plu
          imageFilename
          priceInclVat
          translation {
            culture
            name
            description
          }
          isAllotted
          isPersonalTicket
          isAnonymousTicket
          isFee
          isProduct
          overrideStartDate
          isMultiTicket
          additionalFields {
            id
            setValueOnSale
            regExValidation
            translation {
              culture
              name
              description
            }
          }
        }
        ticketOwners {
          name
        }
        occasion
        allotmentId
      }
    }
  }
`;

export const SaleContext = React.createContext();

const NoSaleId = (props) => {
  return (
    <SaleContext.Provider value={{ state: { sale: null } }}>
      {props.children}
    </SaleContext.Provider>
  );
};

const WithSaleId = (props) => {
  const { culture, currency, saleId, posId, version } = props.ctx;
  const { data, error, refetch } = useQuery(query, {
    variables: {
      saleId,
      posId,
      currency,
      culture,
    },
    fetchPolicy: "network-only",
  });

  if (error)
    return (
      <SaleContext.Provider value={{ state: { sale: null } }}>
        {props.children}
      </SaleContext.Provider>
    );
  const sale = data && data.sale;
  return (
    <>
      <RefetchSaleQuery refetch={refetch} version={version} />
      <SaleContext.Provider
        value={{
          state: { sale },
        }}
      >
        {props.children}
      </SaleContext.Provider>
    </>
  );
};

const SaleProvider = (props) => {
  const ctx = useSelector((state) => ({
    config: state.config,
    version: state.sale.version,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
    saleId: state.cookies[STATE_SALE_ID],
  }));
  if (!ctx.saleId) return <NoSaleId ctx={ctx} {...props} />;
  return <WithSaleId ctx={ctx} {...props} />;
};

export default SaleProvider;
