import React from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import slugify from "../common/slugify";
import CardV2 from "./CardV2";
import ListV2 from "./ListV2";
import classes from "./Landing.module.css";

const getPagesWithOnlyOneEvent = (pages) => {
  let pagesWithOnlyOneEvent = [];

  pages.map((page) =>
    page.events && page.events.length === 1
      ? pagesWithOnlyOneEvent.push(page.name)
      : null
  );

  return pagesWithOnlyOneEvent;
};

const LandingPage = () => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));

  const { pages } = config;
  const pagesWithOnlyOneEvent = getPagesWithOnlyOneEvent(pages);

  const { landingPage } = config;
  const { listType, links } = landingPage;
  const list = window.innerWidth > 667 ? listType.lg : listType.sm || "CARD";
  return (
    <div
      className={clsx(
        classes.landingContent,
        "landing__content",
        list === "CARD" && classes.landingCard
      )}
    >
      {links.map((landingPage, index) => {
        if (landingPage.disabled) return null;
        const landingTexts = language.LandingTexts;
        const text = landingTexts
          ? landingTexts[landingPage.name]
          : { primary: null, secondary: null };
        const image = `${config.baseUrl}${config.imageFolderUrl}${
          landingPage.image ? landingPage.image : "placeholder.jpg"
        }`;
        const item = { ...landingPage, image, text };

        if (
          pagesWithOnlyOneEvent.includes(item.name) &&
          config.useAutoNavigationOnSingleEvent
        ) {
          const eventSlug = `${process.env.PUBLIC_URL}/events/${slugify(
            item.name
          )}`;
          return list === "CARD" ? (
            <CardV2 slug={eventSlug} item={item} key={index} />
          ) : (
            <ListV2 slug={eventSlug} item={item} key={index} />
          );
        } else {
          return list === "CARD" ? (
            <CardV2 item={item} key={index} />
          ) : (
            <ListV2 item={item} key={index} />
          );
        }
      })}
    </div>
  );
};

export default LandingPage;
