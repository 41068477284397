import { useSelector } from "react-redux";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useEffect, useState } from "react";
import { STATE_CULTURE } from "../../common/state";
import axios from "axios";
import classes from "./Options.module.css";
import { AnimatePresence, motion } from "framer-motion";
import { variants } from "./variants";

let source;
const Description = ({ description }) => {
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [text, setText] = useState("");

  useEffect(() => {
    if (config && culture) {
      let CancelToken = axios.CancelToken;
      source = CancelToken.source();
      axios
        .get(
          `${config.baseUrl}/${config.descriptionFolderUrl}${description}.${culture}.md`
        )
        .then((res) => setText(res.data))
        .catch((err) => console.log(err));
    }
    return source.cancel("Component is unmounting");
  }, [culture, config]);

  return (
    <AnimatePresence mode="wait">
      <motion.div
        className={classes.description}
        initial="hidden"
        animate="visible"
        exit="hidden"
        variants={variants}
      >
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{text}</ReactMarkdown>
      </motion.div>
    </AnimatePresence>
  );
};

export default Description;
