import React from "react";
import clsx from "clsx";
import { UnstyledButton, Avatar } from "@mantine/core";
import classes from "./sideMenu.module.css";

const SideMenu = ({ ticketOwners, activePerson, setActivePerson }) => {
  if (ticketOwners.length < 2) {
    return null;
  }
  return (
    <div className={classes.sideMenu}>
      {ticketOwners.map((o) => {
        const isActive = o.id === activePerson?.id;

        return (
          <UnstyledButton
            onClick={() => setActivePerson(o)}
            key={o.id}
            className={classes.item}
          >
            <Avatar className={classes.avatar} alt={o.name} src={o.photo} />
            <p className={clsx(classes.name, isActive && classes.active)}>
              {o.name}
            </p>
          </UnstyledButton>
        );
      })}
    </div>
  );
};

export default SideMenu;
