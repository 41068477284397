export function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

export const cookieOptions = {
  expires: new Date(
    new Date().setFullYear(new Date().getFullYear() + 1)
  ).toString(),
  path: "/",
  sameSite: "lax",
};

export const removeTrackingCookies = (cookies, baseUrl) => {
  const all = cookies.getAll();
  const domain = baseUrl.split(".").slice(1).join(".");

  for (const key in all) {
    if (key.startsWith("_fbp") || key.startsWith("_ga")) {
      cookies.remove(key, { path: "/", domain: domain });
    }
  }
};
