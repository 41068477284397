import { Indicator } from "@mantine/core";
import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Context, OPEN_CART } from "../../context";
import { SaleContext } from "../common/saleProvider";
import classes from "./CartV2.module.css";
import { useLocation } from "react-router";

const CartV2 = () => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const {
    state: { sale },
  } = useContext(SaleContext);
  const { dispatch } = useContext(Context);
  const { pathname } = useLocation();

  const openCart = () => {
    if (pathname !== "/options") {
      dispatch({ type: OPEN_CART });
    }
  };

  const isDisabled = useMemo(() => {
    if (pathname === "/options") {
      return true;
    }
    return false;
  }, [pathname]);

  return (
    <div className={`${classes.wrapper} cart`}>
      <Indicator
        inline
        label={sale?.itemQuantity ? sale?.itemQuantity : 0}
        size={20}
        className={`${classes.indicator} ${
          isDisabled && classes.indicatorDisabled
        }`}
      >
        <button
          className={`${classes.button} shoppingCartButton`}
          onClick={openCart}
          aria-label={language.OpenCart}
          disabled={isDisabled}
        >
          {sale?.itemQuantity > 0 ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className={classes.shoppingBag}
            >
              <path
                fillRule="evenodd"
                d="M7.5 6v.75H5.513c-.96 0-1.764.724-1.865 1.679l-1.263 12A1.875 1.875 0 004.25 22.5h15.5a1.875 1.875 0 001.865-2.071l-1.263-12a1.875 1.875 0 00-1.865-1.679H16.5V6a4.5 4.5 0 10-9 0zM12 3a3 3 0 00-3 3v.75h6V6a3 3 0 00-3-3zm-3 8.25a3 3 0 106 0v-.75a.75.75 0 011.5 0v.75a4.5 4.5 0 11-9 0v-.75a.75.75 0 011.5 0v.75z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={classes.shoppingBag}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>
          )}
        </button>
      </Indicator>
    </div>
  );
};

export default CartV2;
