import React, { useContext } from "react";
import { Button } from "@mantine/core";
import { useSelector } from "react-redux";
import { Context } from "../../context";

const Login = () => {
  const { handleLogin, handleLogout } = useContext(Context);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const { auth } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
  }));

  return (
    <div>
      <Button
        className="primaryButton"
        onClick={() => {
          if (auth) {
            handleLogout();
          } else {
            localStorage.setItem(
              "goBackUrl",
              `publicBooking?${window.location.pathname}`
            );
            handleLogin();
          }
        }}
      >
        {!auth && language.Log_On}
        {auth && language.Log_Off}
      </Button>
    </div>
  );
};

export default Login;
