import { Divider, Grid, Stack, Text } from "@mantine/core";
import React from "react";
import ArticleButtons from "./ArticleButtons";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";

const Article = ({
  ticketData,
  cart,
  cartSet,
  chosenOccasion,
  disabledBtn,
  availableOccasions,
  open,
  groupTicketCart,
  groupTicketCartSet,
}) => {
  const { culture } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
  }));

  const getName = () => {
    const getTranslation = ticketData.translation.filter(
      (el) => el.culture === culture
    );
    return getTranslation?.[0]?.name;
  };

  return (
    <>
      {ticketData && (
        <Grid justify="space-between">
          <Grid.Col span={6}>
            <Stack>
              <Text fw={500}>{getName()}</Text>
              <Text
                c="dimmed"
                size="sm"
                fw={500}
                style={{ marginTop: "-1rem" }}
              >
                {ticketData.priceInclVat}
              </Text>
            </Stack>
          </Grid.Col>
          <Grid.Col span={6} style={{ textAlign: "right", flexBasis: "0%" }}>
            <ArticleButtons
              chosenOccasion={chosenOccasion}
              disabledBtn={disabledBtn}
              cart={cart}
              cartSet={cartSet}
              ticketData={ticketData}
              availableOccasions={availableOccasions}
              name={getName()}
              open={open}
              groupTicketCart={groupTicketCart}
              groupTicketCartSet={groupTicketCartSet}
            />
          </Grid.Col>
        </Grid>
      )}
      <Divider size="xs" mb={"-0.5rem"} />
    </>
  );
};

export default Article;
