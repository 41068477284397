import React, { useReducer } from "react";
export const Context = React.createContext();

export const CART_ARTICLE_ADD = "add_cart_article";
export const CART_ARTICLE_REMOVE = "remove_cart_article";
export const CLOSE_CART = "close_cart";
export const CLOSE_SEARCH = "close_search";
export const OPEN_CART = "open_cart";
export const SET_SEARCH_VALUE = "set_search_value";
export const TOGGLE_CART = "toggle_cart";
export const TOGGLE_SEARCH = "toggle_search";
export const TOGGLE_USER = "toggle_user";

const initialState = {
  cartArticle: null,
  cartOpen: false,
  searchOpen: false,
  searchValue: "",
  userOpen: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case CART_ARTICLE_ADD:
      return { ...state, cartArticle: action.payload };
    case CART_ARTICLE_REMOVE:
      return { ...state, cartArticle: null };
    case CLOSE_CART:
      return { ...state, cartOpen: false };
    case CLOSE_SEARCH:
      return { ...state, searchOpen: false };
    case OPEN_CART:
      return { ...state, cartOpen: true };
    case SET_SEARCH_VALUE:
      return { ...state, searchValue: action.payload };
    case TOGGLE_CART:
      return { ...state, cartOpen: !state.cartOpen };
    case TOGGLE_SEARCH:
      return { ...state, searchOpen: !state.searchOpen };
    case TOGGLE_USER:
      return { ...state, userOpen: !state.userOpen };
    default:
      throw new Error(`wrong action type: ${action.type}`);
  }
};

const ContextProvider = ({ userManager, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const handleLogin = () => {
    userManager.clearStaleState();
    // maybe just set busy and move this into a useEffect hook
    userManager.signinRedirect().catch((err) => console.log("Error!", err));
  };
  const handleLogout = () =>
    userManager.signoutRedirect().catch((err) => console.log("Error!", err));
  return (
    <Context.Provider
      value={{
        state,
        dispatch,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default ContextProvider;
