import React, { useEffect } from "react";
import moment from "moment";
import { Skeleton } from "@mantine/core";
import classes from "./CountdownContent.module.css";
import { useCountdown } from "../common/countdownProvider";

const CountdownContent = ({ sale, language, openModal, changeBackground }) => {
  const { timeState, timerExpiration, setTimerExpiration } = useCountdown();

  useEffect(() => {
    const timerExpired = moment().isAfter(timerExpiration);

    if (timerExpired) {
      openModal(true);
    }
  }, [timeState, timerExpiration, openModal]);

  useEffect(() => {
    if (sale && sale.firstArticleExpiresAt) {
      const firstArticleExpiration = Date.parse(sale.firstArticleExpiresAt);
      setTimerExpiration(firstArticleExpiration);
    }
  }, [sale, setTimerExpiration]);

  const isWithinTwoHours = moment(timerExpiration).isBetween(
    Date.now(),
    moment().add(2, "hours")
  );

  if (!isWithinTwoHours) {
    return null;
  }

  return (
    <div
      className={`countdownTimer ${
        changeBackground ? "darkColor" : "lightColor"
      } ${classes.countdownTimer}`}
      role="timer"
    >
      <span>{language.Countdown_Remaining}</span>
      {timeState.minutes === 0 && timeState.seconds === 0 ? (
        <Skeleton height={20} width={50} mt={2} radius="xl" />
      ) : (
        `${timeState.minutes}:${timeState.seconds}`
      )}
    </div>
  );
};

export default CountdownContent;
