import React from "react";
import "./styles.css";

const TicketImage = ({ imageUrl, altText }) => {
  return (
    <>
      {imageUrl ? (
          <img src={imageUrl} alt={altText} className="ticketIntroductionImage" />
      ) : (
        ""
      )}
    </>
  );
};

export default TicketImage;
