import {
  Button,
  Container,
  Grid,
  Image,
  Loader,
  Popover,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addModification } from "../../../common/sale";
import { STATE_CULTURE } from "../../../common/state";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { motion } from "framer-motion";
import { SaleContext } from "../../common/saleProvider";
import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import ExtensionCard from "./ExtensionCard";
import ExtensionModifications from "./ExtensionModifications";
import { useMediaQuery } from "@mantine/hooks";
import { Context, OPEN_CART } from "../../../context";
import { notification } from "../../common/util";
import classes from "./Extension.module.css";
import placeholder from "../../../images/placeholder.jpg";
import axios from "axios";

const GET_INTERNAL_FUNDS = gql`
  query InternalFundsQuery($barcode: String) {
    internalFundsLookup(barcode: $barcode) {
      hasInternalFunds
      fundsAmount
      isRefundable
      minimumAmount
      maximumAmount
      articleId
      fundsId
      ticketId
    }
  }
`;

const TICKET_BY_BARCODE = gql`
  query ticketByBarcode($barcode: String, $posId: Int) {
    ticketByBarcode(barcode: $barcode, posId: $posId) {
      id
      applicableModifications {
        id
        name
        priceInclVat
        plu
      }
      article {
        id
      }
      validFrom
      validUntil
      owner {
        firstname
        lastname
      }
      uniqueIdentifier
      article {
        id
        name
      }
    }
  }
`;

const Extension = ({ product }) => {
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));

  const [value, setValue] = useState("");
  const [checked, setChecked] = useState(null);
  const [description, setDescription] = useState("");
  const rdxDispatch = useDispatch();
  const { posId } = config;
  const mobile = useMediaQuery("(max-width: 768px)");
  const { dispatch } = useContext(Context);

  const [getTicket, { loading, data }] = useLazyQuery(TICKET_BY_BARCODE, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const [getFunds, { data: dataFunds }] = useLazyQuery(GET_INTERNAL_FUNDS, {
    fetchPolicy: "no-cache",
    nextFetchPolicy: "no-cache",
  });

  const getProduct = config.productConfig.filter(
    (el) => el?.plu === product?.plu
  )?.[0];

  useEffect(() => {
    axios
      .get(
        `${config?.baseUrl}${config?.descriptionFolderUrl}${getProduct?.description}.${culture}.md`
      )
      .then((res) => setDescription(res.data))
      .catch((err) => console.log(err));
  }, [config, culture]);

  const getTranslation =
    getProduct &&
    getProduct?.translation?.filter((el) => el.culture === culture)?.[0];

  const getImage = `${config.baseUrl}/${config?.imageFolderUrl}${getProduct.images?.[0]}`;

  const callback = (status, msg) => {
    if (status === 200) {
      dispatch({ type: OPEN_CART });
    }
    if (status !== 200) {
      return notification("error", msg);
    }
  };
  const ticketId = data?.ticketByBarcode?.[0]?.id;
  const articleId = data?.ticketByBarcode?.[0]?.article.id;
  const plu = checked?.plu;
  const fundsId = dataFunds ? dataFunds?.internalFundsLookup?.fundsId : null;
  const amount = dataFunds
    ? checked?.priceInclVat.replace(/[^0-9\.]+/g, "")
    : null;
  const handleAdd = () => {
    const model = {
      ticketId,
      articleId,
      plu,
      fundsId,
      amount,
    };

    rdxDispatch(
      addModification(model, (status, msg) => {
        callback(status, msg);
      })
    );
  };

  const getExtensions = () => {
    setChecked(null);
    getTicket({ variables: { barcode: value, posId: posId } });
    getFunds({ variables: { barcode: value, posId: posId } });
  };

  return (
    <Container className={classes.containerWrapper}>
      <Grid gutter={28}>
        <Grid.Col span={{ sm: 12, md: 7 }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: "100%" }}
          >
            <Image
              src={getImage}
              alt="bild"
              fallbackSrc={placeholder}
              radius={"sm"}
              h={
                mobile && !product.images ? "20rem" : mobile ? "20rem" : "25rem"
              }
              fit="cover"
            />
          </motion.div>
        </Grid.Col>
        <Grid.Col span={{ sm: 12, md: 5 }} className={classes.container}>
          <div className={classes.titleWrapper}>
            <h1 className="productTitle" style={{ fontSize: "1.5rem" }}>
              {getTranslation.name}
            </h1>
          </div>
          <div className={classes.descriptionBody}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {description}
            </ReactMarkdown>
          </div>
          <div className={classes.inputWrapper}>
            <TextInput
              placeholder={language.ExtendBody}
              required={true}
              classNames={"classes"}
              value={value}
              onChange={(e) => setValue(e.currentTarget.value)}
              label={language.Extend}
            />
          </div>
          <div className={classes.alertWrapper}>
            <Button
              variant="filled"
              size="md"
              className="regularButton"
              onClick={getExtensions}
              disabled={!value || value === ""}
              style={{
                width: "100%",
                transition: "all 0.2s ease-in-out",
              }}
            >
              {loading ? <Loader size={"sm"} /> : language.Fetch_Ticket}
            </Button>
            <Popover
              width="target"
              withArrow
              shadow="md"
              opened={data?.ticketByBarcode.length === 0}
            >
              <Popover.Target>
                <div className={classes.alertContainer} />
              </Popover.Target>
              <Popover.Dropdown>
                <Text size="md" component="p">
                  {language.CouldNotFindTicket}
                </Text>
              </Popover.Dropdown>
            </Popover>
          </div>
        </Grid.Col>
      </Grid>
      {data && data?.ticketByBarcode.length > 0 && (
        <Grid mt="lg">
          <Grid.Col span={mobile ? 12 : 6}>
            <ExtensionCard card={data.ticketByBarcode[0]} />
          </Grid.Col>

          <Grid.Col span={mobile ? 12 : 6}>
            {data?.ticketByBarcode.length > 0 && (
              <Title order={5} mb={13.33}>
                {language.AvailableModifications}
              </Title>
            )}
            {data &&
              data?.ticketByBarcode.length > 0 &&
              data.ticketByBarcode[0].applicableModifications.length > 0 &&
              data.ticketByBarcode[0].applicableModifications.map((el) => (
                <ExtensionModifications
                  modification={el}
                  id={el.id}
                  title={el.name}
                  price={el.priceInclVat}
                  checked={checked}
                  setChecked={setChecked}
                />
              ))}
          </Grid.Col>
          <Grid.Col
            span={12}
            style={{ display: "flex", justifyContent: "end" }}
          >
            <Button
              size="md"
              className={`${classes.addToCart} regularButton add_to_cart_product`}
              classNames={{
                label: "add_to_cart_product",
                inner: "add_to_cart_product",
              }}
              disabled={!checked}
              onClick={handleAdd}
            >
              {language.AddToCartBtn}
            </Button>
          </Grid.Col>
        </Grid>
      )}
    </Container>
  );
};

export default Extension;
