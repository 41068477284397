import React from "react";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  toDate,
} from "date-fns";
import "../styles.css";
import "./eventStyles.css";
import { useSelector } from "react-redux";

const WeekDay = ({
  currentDate,
  selectedDate,
  daySet,
  occasions,
  cultureLocale,
}) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const monthStart = startOfMonth(currentDate, {
    locale: cultureLocale,
  });
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { locale: cultureLocale });
  const endDate = endOfWeek(monthEnd, { locale: cultureLocale });
  const dateFormat = "d";
  const rows = [];

  let days = [];
  let day = startDate;
  let formattedDate = "";
  let today = new Date();
  let yesterday = today.setDate(today.getDate() - 1);

  const onDateClick = (day) => {
    daySet(day);
  };

  const getAvailability = (day, occasion) => {
    if (occasion) {
      const filter = occasion.filter(
        (el) =>
          format(new Date(el.time), "yyyy-MM-dd") ===
          format(new Date(day), "yyyy-MM-dd")
      );
      if (filter.length > 0) {
        return "available";
      } else {
        return null;
      }
    }
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;
      let isAvailable;
      getAvailability(day, occasions) === "available"
        ? (isAvailable = "available")
        : (isAvailable = "disabled");
      days.push(
        <button
          aria-label={`${format(day, "EEEE do MMMM yyyy", {
            locale: cultureLocale,
          })} ${isSameDay(day, selectedDate) ? language.Chosen : ""}`}
          className={`removeBorderBg ${isAvailable} event-column event-cell ${
            !isSameMonth(day, monthStart)
              ? "disabled"
              : isSameDay(day, selectedDate)
              ? "occasion-selected"
              : ""
          } ${isBefore(day, yesterday) ? "disabled" : ""}`}
          key={day}
          disabled={!isSameMonth(day, monthStart) || isAvailable === "disabled"}
          aria-disabled={`${isAvailable === "disabled" ? true : false}`}
          onClick={() => onDateClick(toDate(cloneDay))}
        >
          <span className="event-number">{formattedDate}</span>
        </button>
      );
      day = addDays(day, 1);
    }

    rows.push(
      <div className="row" key={day}>
        {days}
      </div>
    );
    days = [];
  }
  return <div className="body">{rows}</div>;
};
export default WeekDay;
