import classes from "./Options.module.css";
import { useContext } from "react";
import { SaleContext } from "../common/saleProvider";
import OptionsItem from "./OptionsItems";

const Item = ({ data }) => {
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  if (!data) return null;
  return (
    <div className={classes.itemWrapper}>
      <OptionsItem item={data} sale={sale} />
    </div>
  );
};

export default Item;
