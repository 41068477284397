import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import PageLayout from "../../components/Styles/PageLayout";
import Description from "../../components/common/Description";
import ProductList from "../../components/ProductList";
import ProductGroup from "../../components/Page/productGroup";
import { STATE_CULTURE } from "../../common/state";
import { getAllProducts, extendPage } from "./util";
import { DataContext } from "../../components/common/dataProvider";
import PageContent from "../../components/Styles/PageContent";

let source;

const AllProductsPagePage = ({ setPosition }) => {
  const { data } = useContext(DataContext);

  const [text, setText] = useState("");
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const products = getAllProducts(data, config, culture);

  useEffect(() => {
    source = axios.CancelToken.source();
    axios
      .get(
        `${config.baseUrl}/${config.descriptionFolderUrl}/all-products.${culture}.md`,
        {
          cancelToken: source.token,
        }
      )
      .then((res) => setText(res.data));
    return () => source.cancel("All Products Is Unmounting");
  }, [culture, config.descriptionFolderUrl]);

  const page = extendPage(data, config);

  return (
    <PageLayout>
      <PageContent>
        <Description text={text} />
        {config.allProductsGroupByTag ? (
          page.productGroups.map((group, i) => (
            <ProductGroup
              key={i}
              group={group}
              page={page}
              setPosition={setPosition}
            />
          ))
        ) : (
          <ProductList products={products} setPosition={setPosition} />
        )}
      </PageContent>
    </PageLayout>
  );
};

export default AllProductsPagePage;
