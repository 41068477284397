import React from "react";
import { useSelector } from "react-redux";
import Content from "./content";

const Footer = (props) => {
  const config = useSelector((state) => state.config);
  return config.useFooter ? <Content /> : null;
};

export default Footer;
