import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setConsent } from "../../common/state";
import { Notification, Modal } from "@mantine/core";
import classes from "./CookieBanner.module.css";
import TrackingCookiesContent from "./TrackingCookiesContent";
import NecessaryCookiesContent from "./NecessaryCookiesContent";
import Cookies from "universal-cookie";
import { cookieOptions, gtag } from "./util";

const CookieBanner = () => {
  const [decisionMade, setDecisionMade] = useState(true);
  const dispatch = useDispatch();
  const [config] = useSelector((state) => [state.config]);
  const cookies = useMemo(() => new Cookies(), []);

  const sendConsent = useCallback((consent) => {
    gtag("consent", "default", consent);
  }, []);

  const sendUpdateConsent = useCallback((consent) => {
    gtag("consent", "update", consent);
  }, []);

  useEffect(() => {
    if (cookies.get("shop-consent") !== null) {
      setDecisionMade(true);
    } else {
      setDecisionMade(false);
    }
  }, [cookies, setDecisionMade, sendConsent]);

  const handleDecision = (outcome) => {
    if (outcome === "denied") {
      dispatch(setConsent(false, cookieOptions));
    } else {
      const consent = {
        ad_storage: outcome,
        ad_user_data: outcome,
        ad_personalization: outcome,
        analytics_storage: outcome,
      };
      dispatch(setConsent(consent, cookieOptions));
      if (cookies.get("shop-consent")) {
        sendUpdateConsent(cookies.get("shop-consent"));
      } else {
        sendConsent(consent);
      }
    }
    setDecisionMade(true);
  };

  const handleNoConsent = () => {
    dispatch(setConsent(false, cookieOptions));
    setDecisionMade(true);
  };

  useEffect(() => {
    if (cookies.get("shop-consent")) {
      sendUpdateConsent(cookies.get("shop-consent"));
    }
  }, []);

  if (decisionMade) return <></>;
  return (
    <Modal
      opened={true}
      p={0}
      withCloseButton={false}
      withOverlay={false}
      lockScroll={false}
      align="end"
      closeOnClickOutside={false}
      closeOnEscape={false}
      classNames={{
        inner: classes.inner,
        body: classes.body,
      }}
      size="lg"
      yOffset={20}
    >
      <Notification
        withCloseButton={false}
        data-autofocus
        className={classes.notification}
        color="var(--button-primary-bg-color)"
      >
        {config.useTagmanager && (
          <TrackingCookiesContent
            onAccept={() => handleDecision("granted")}
            onDeny={() => handleDecision("denied")}
            onClose={() => setDecisionMade(true)}
          />
        )}
        {!config.useTagmanager && (
          <NecessaryCookiesContent onClose={handleNoConsent} />
        )}
      </Notification>
    </Modal>
  );
};

export default CookieBanner;
