import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import LandingPage from "./Pages/LandingPage";
import Product from "./Pages/ProductPage";
import Event from "./Pages/EventPage";
import AllProducts from "./Pages/AllProductsPage";
import AllEvents from "./Pages/AllEventsPage";
import SitePage from "./Pages/SitePage";
import User from "./Pages/UserPage";
import TicketPage from "./Pages/TicketPage";
import MoveOccasionsPage from "./Pages/MoveOccasionsPage";
import ArticleBookingPage from "./Pages/ArticleBookingPage";
import ScrollToTop from "./components/common/ScrollToTop";
import PublicBookingPage from "./Pages/PublicBookingPage";
import AccessibilityPage from "./Pages/AccessibilityPage";
import OptionsPage from "./Pages/OptionsPage";

const RouteWrapper = ({ setPosition }) => {
  const { auth } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
  }));

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/events/:id" element={<Event />} />
        <Route path="/products/:id" element={<Product />} />
        <Route
          path="/all-products"
          element={<AllProducts setPosition={setPosition} />}
        />
        <Route path="/all-events" element={<AllEvents />} />
        <Route path="/:id" element={<SitePage setPosition={setPosition} />} />
        <Route path="/booking/:id" element={<ArticleBookingPage />} />
        <Route path="/tickets/:id" element={<TicketPage />} />
        <Route path="/publicbooking/:id" element={<PublicBookingPage />} />
        <Route path="/sale/:id" element={<MoveOccasionsPage />} />
        <Route path="/options" element={<OptionsPage />} />
        <Route path="/accessibility" element={<AccessibilityPage />} />

        {auth && <Route path="/user" element={<User />} />}
      </Routes>
    </>
  );
};

export default RouteWrapper;
