import _ from "lodash";

export const promiseMiddleware = () => (next) => (action) => {
  const { promise, then } = action;
  const rest = _.omit(action, [promise, then]);

  if (!promise) {
    return next(action);
  }
  next({ ...rest, readyState: "request" });
  return promise.then(
    (result) => {
      next({ ...rest, result, readyState: "success" });
      if (then) {
        console.log("then", result);
        then(result);
      }
    },
    (error) => {
      next({ ...rest, error, readyState: "failure" });
    }
  );
};

export const thunkMiddleware =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (typeof action === "function") {
      return action(dispatch, getState);
    } else {
      return next(action);
    }
  };
