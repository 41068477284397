import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useSelector } from "react-redux";
import SiteHeading from "../common/SiteHeading";
import Footer from "../common/Footer";
import RouteWrapper from "../../routes";
import classes from "./AppContent.module.css";

const AppContent = () => {
  const location = useLocation();
  const [scrollPos, setScrollPos] = useState({});
  const contentRef = React.createRef();
  const config = useSelector((state) => state.config);

  useEffect(() => {
    if (Object.keys(scrollPos).includes(location.pathname)) {
      contentRef.current.scrollTop = scrollPos[location.pathname];
    } else {
      contentRef.current.scrollTop = 0;
    }
  }, [scrollPos, contentRef, location.pathname]);

  const setPosition = () => {
    const page = location.pathname;
    const position = contentRef.current.scrollTop;
    setScrollPos({ [page]: position });
  };
  return (
    <div
      className={`${classes.appContent} app__content-container`}
      id="AppBody"
      ref={contentRef}
    >
      <main>
        {!config.showSiteHeadingOnlyOnLanding && <SiteHeading />}
        <RouteWrapper setPosition={setPosition} />
      </main>
      <Footer />
    </div>
  );
};

export default AppContent;
