import React, { useEffect, useRef, useState } from "react";
import { Button, RangeSlider, Text, TextInput, Title } from "@mantine/core";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import { DatePickerInput } from "@mantine/dates";
import "dayjs/locale/sv";
import "./styles.css";
import { useEventListener, useMediaQuery } from "@mantine/hooks";
import autoAnimate from "@formkit/auto-animate";
import _ from "lodash";
import useDebounce from "../../../hooks/useDebounce";
import classes from "./EventFilter.module.css";

const EventFilter = ({
  events,
  priceVal,
  setPriceVal,
  priceBeforeChange,
  setPriceBeforeChange,
  text,
  setText,
  date,
  setDate,
  setIsSorting,
}) => {
  const [initialValues, setInitialValues] = useState(null);
  const [newValues, setNewValues] = useState([null]);
  const [show, setShow] = useState(false);
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [searchText, setSearchText] = useState("");
  const debouncedTerm = useDebounce(searchText, 500);
  const isMobile = useMediaQuery("(max-width: 755px)");
  const parent = useRef(null);

  const calculateValue = (value) => {
    const regex = /[$€£kr,]/g;
    let newPrice = value && value.replace(regex, "");
    return newPrice;
  };

  const getPriceRange = events.reduce((acc, curr) => {
    acc.push(curr.articles);
    return acc.flat();
  }, []);

  const articles = getPriceRange.sort(
    (a, b) => a.price.amountInclVat - b.price.amountInclVat
  );

  const maxDate = events.reduce((acc, curr) => {
    acc.push(curr.occasions);
    return acc.flat().sort((a, b) => new Date(a.time) - new Date(b.time));
  }, []);

  useEffect(() => {
    setInitialValues([
      date,
      [
        parseFloat(calculateValue(articles?.[0]?.priceInclVat)),
        parseFloat(
          calculateValue(articles?.[articles.length - 1]?.priceInclVat)
        ),
      ],
      text,
    ]);
    setPriceBeforeChange([
      parseFloat(calculateValue(articles?.[0]?.priceInclVat)),
      parseFloat(calculateValue(articles?.[articles.length - 1]?.priceInclVat)),
    ]);
  }, []);

  useEffect(() => {
    setNewValues([date, priceVal, text]);
    setIsSorting(true);
  }, [date, priceVal, text]);

  useEffect(() => {
    setText(debouncedTerm);
  }, [debouncedTerm]);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    if (initialValues && newValues) {
      if (!_.isEqual(initialValues, newValues)) {
        setShow(true);
      }
      if (_.isEqual(initialValues, newValues)) {
        setShow(false);
      }
      setSearchText(text);
    }
  }, [newValues, initialValues]);

  const handleOnChangeEnd = (value) => {
    setPriceVal(value);
    window?.plausible("Slider Click");
  };

  const reset = () => {
    setDate(initialValues[0]);
    setPriceVal(initialValues[1]);
    setPriceBeforeChange([
      parseFloat(calculateValue(articles?.[0]?.priceInclVat)),
      parseFloat(
        calculateValue(articles?.[articles.length - 1]?.priceInclVat) + 100
      ),
    ]);
    setText(initialValues[2]);
  };

  return (
    <div className={`${classes.wrapper} allEventsFilter`}>
      <h2>{language.FilterEvents}</h2>
      <Text size="sm" component={"span"}>
        {language.FilterEventsBody}
      </Text>
      <div className={classes.filterWrapper}>
        <DatePickerInput
          type="range"
          label={language.Choose_From_To_Date}
          placeholder={language.Choose_Date}
          value={date}
          onChange={setDate}
          className={`${classes.datePicker}`}
          classNames={{
            input: `${
              !config?.hideAllEventsSlider &&
              "plausible-event-name=Calendar+Click"
            }`,
          }}
          locale={culture}
          size="md"
          radius="md"
          dropdownType={isMobile ? "modal" : "popover"}
          minDate={new Date(maxDate?.[0]?.time)}
          maxDate={new Date(maxDate?.[maxDate.length - 1]?.time)}
          valueFormat="D MMMM YYYY"
          defaultValue={[new Date(), new Date()]}
          clearable={false}
        />
        {!config?.hideAllEventsSlider && (
          <div className={`${classes.sliderWrapper}`} style={{ width: "100%" }}>
            <Text component="label" htmlFor="allEventsRangeSlider" size={"md"}>
              {language.ChoosePrice}
            </Text>
            <RangeSlider
              id="allEventsRangeSlider"
              labelAlwaysOn
              value={priceBeforeChange}
              onChange={setPriceBeforeChange}
              onChangeEnd={(value) => handleOnChangeEnd(value)}
              min={0}
              max={
                articles?.[articles.length - 1]
                  ? parseFloat(
                      calculateValue(
                        articles?.[articles.length - 1]?.priceInclVat
                      )
                    ) + 100
                  : 0
              }
              defaultValue={[
                0,
                parseFloat(
                  calculateValue(articles?.[articles.length - 1]?.priceInclVat)
                ) + 100,
              ]}
              classNames={{
                label: classes.label,
                thumb: classes.thumb,
                dragging: classes.dragging,
              }}
              mt={"0.9rem"}
              size={"lg"}
              thumbFromLabel={language.ChooseFromPrice}
              thumbToLabel={language.ChooseToPrice}
            />
          </div>
        )}
        <TextInput
          label={language.Search}
          leftSection={
            <BiSearch style={{ height: "1.35rem", width: "1.35rem" }} />
          }
          radius="md"
          size="md"
          placeholder={language.Search}
          rightSectionWidth={42}
          className={`${classes.search}`}
          classNames={{
            input: `${
              !config?.hideAllEventsSlider &&
              "plausible-event-name=Search+Click"
            }`,
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          style={{ width: "100%" }}
        />
      </div>
      {show && (
        <div ref={parent} className={classes.resetButtonWrapper}>
          <div style={{ width: "fit-content" }}>
            <Button
              className={`${classes.scopedRegularButton} regularButton`}
              style={{
                gridColumn: 3,
                width: "fit-content",
                marginLeft: "auto",
                marginBottom: "-1rem",
              }}
              onClick={reset}
            >
              {language.Clear_Filter}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventFilter;
