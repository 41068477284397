import React, { useEffect } from "react";
import "./styles.css";
import { Button } from "@mantine/core";

const PreviousPageBtn = ({
  step,
  setStep,
  setChosenOldOccasion,
  returnClick,
  setReturnClick,
  language,
}) => {
  const clicked = () => {
    setStep(step - 1);
    setReturnClick(false);
  };
  const markdown = language.GoToPreviousStepBtn;
  return (
    <>
      {step !== 0 ? (
        <div className="previousPageWrapper">
          <Button
            style={{ color: "inherit" }}
            variant="transparent"
            onClick={() => clicked()}
            aria-label={language?.GoToPreviousStepBtn}
            leftSection={
              <svg
                className="previousPageIcon"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
            }
          >
            {markdown}
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default PreviousPageBtn;
