import React, { useEffect, useState } from "react";
import {
  addMonths,
  format,
  isAfter,
  isBefore,
  isSameMonth,
  parseISO,
  startOfMonth,
  subMonths,
} from "date-fns";
import { GoChevronLeft, GoChevronRight } from "react-icons/go";

const Header = ({
  currentDate,
  setCurrentDate,
  firstOccasion,
  lastOccasion,
  cultureLocale,
  initialMonth,
  language,
}) => {
  const monthStart = startOfMonth(currentDate);
  const dateFormat = "MMMM yyyy";

  const nextMonth = () => {
    setCurrentDate(addMonths(currentDate, 1));
  };
  const prevMonth = () => {
    setCurrentDate(subMonths(currentDate, 1));
  };

  useEffect(() => {
    setCurrentDate(new Date(firstOccasion.time));
  }, []);

  useEffect(() => {
    let checkIfAfter = isAfter(initialMonth, parseISO(lastOccasion.time));
    if (
      !checkIfAfter &&
      initialMonth !== undefined &&
      !isSameMonth(new Date(currentDate), new Date(initialMonth))
    ) {
      setCurrentDate(new Date(initialMonth));
    }
    if (checkIfAfter) {
      setCurrentDate(parseISO(lastOccasion.time));
    }
  }, [initialMonth, lastOccasion.time]);

  const lastMonthLabel = format(subMonths(currentDate, 1), "MMMM", {
    locale: cultureLocale,
  });
  const nextMonthLabel = format(addMonths(currentDate, 1), "MMMM", {
    locale: cultureLocale,
  });

  return (
    <div className="event-header row flex-middle">
      <div className="column col-start">
        {!isBefore(monthStart, new Date(firstOccasion?.time)) ? (
          <button
            className="removeBorderBg event-icon"
            onClick={prevMonth}
            aria-label={`${language.ShowLastMonth} ${lastMonthLabel}`}
          >
            <GoChevronLeft />
          </button>
        ) : (
          ""
        )}
      </div>

      <div className="event-column col-center">
        <span>
          {format(currentDate, dateFormat, { locale: cultureLocale })}{" "}
        </span>
      </div>

      <div className="event-column col-end">
        {!isSameMonth(new Date(monthStart), new Date(lastOccasion?.time)) ? (
          <button
            className="removeBorderBg event-icon"
            onClick={nextMonth}
            aria-label={`${language.ShowNextMonth} ${nextMonthLabel}`}
          >
            <GoChevronRight />
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Header;
