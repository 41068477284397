import React, { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { getProduct } from "./util";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import { STATE_CULTURE } from "../../common/state";
import { DataContext } from "../../components/common/dataProvider";
import ProductV2 from "../../components/Product/ProductV2";
import { Button, Container, Text, Title } from "@mantine/core";
import { useParams, useNavigate } from "react-router";
import { SaleContext } from "../../components/common/saleProvider";

const ProductPage = () => {
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const navigate = useNavigate();
  const id = useParams().id;
  const { data } = useContext(DataContext);
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;

  const product = useMemo(
    () => getProduct(data, config, id, culture),
    [data, id, config, culture, sale]
  );

  const isProduct = product?.plu || product?.articles;

  if (!product || !isProduct) {
    return (
      <PageLayout>
        <PageContent>
          <Container
            style={{ textAlign: "center", margin: "3rem 0", maxWidth: "100%" }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindProductHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindProductText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate(-1)}
              className="primaryButton"
              variant="filled"
              size="md"
              radius="sm"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <PageContent>
        <ProductV2 product={product} />
      </PageContent>
    </PageLayout>
  );
};

export default ProductPage;
