// open price
export const formatDisplayPrice = (price, currency) => {
  if (!price) return "-";
  switch (currency) {
    case "SEK":
    case "NOK":
    case "DKK":
      return `${Number(price).toFixed(2)} kr`;
    case "EUR":
      return `€${Number(price).toFixed(2)}`;
    case "USD":
      return `$${Number(price).toFixed(2)}`;
    default:
      return price;
  }
};

export const getDisplayPrice = (articles) => {
  var filteredArticles = articles.filter((a) => a.price !== undefined);

  if (filteredArticles.length > 0) {
    filteredArticles.sort(
      (a, b) => a.price?.amountInclVat - b.price?.amountInclVat
    );
    const first = filteredArticles[0].price?.amountInclVat;
    const last =
      filteredArticles[filteredArticles.length - 1].price?.amountInclVat;
    if (first !== last) {
      return `${filteredArticles[0].priceInclVat} - ${
        filteredArticles[filteredArticles.length - 1].priceInclVat
      }`;
    }
    return filteredArticles[0].priceInclVat;
  }
  return undefined;
};

export const getImage = (product, uri, config) => {
  if (!product) return "";

  // default
  if (product.images?.[0]?.startsWith("http")) {
    return product?.images?.[0];
  }

  // allotment images
  if (product.imageFileName) {
    return `${uri}/allotmentimage/${product.imageFileName}`;
  }

  // modification articles in productConfig from local.json
  if (product.images?.[0]?.includes("/ee-static/")) {
    const imageArray = product?.images?.[0].split("/");
    const eestaticIndex = imageArray.findIndex((path) => path === "ee-static");
    const filteredString = imageArray
      .filter((path, index) => index !== eestaticIndex - 1)
      .join("/");
    return `${config.baseUrl}/${filteredString}`;
  }

  return "";
};
