import Options from "../../components/Options";
import PageContent from "../../components/Styles/PageContent";
import PageLayout from "../../components/Styles/PageLayout";

const OptionsPage = () => {
  return (
    <PageLayout>
      <PageContent>
        <Options />
      </PageContent>
    </PageLayout>
  );
};

export default OptionsPage;
