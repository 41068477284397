import { v4 } from "uuid";
import slugify from "../common/slugify";
const makeId = () => v4();

export const getData = (data) => {
  let articleIds = [];
  let articlePlus = [];
  let tags = [];
  if (!data) return { dataProductIds: [], dataTags: [] };
  data.tags.forEach((t) => {
    if (!tags.includes(t.name)) {
      tags = [...tags, t.name];
    }
    t.articles.forEach((a) => {
      if (!articleIds.includes(a.id)) {
        articleIds = [...articleIds, a.id];
        articlePlus = [...articlePlus, a.plu];
      }
    });
  });
  return {
    dataProductIds: articleIds,
    dataProductPlus: articlePlus,
    dataTags: tags,
  };
};

const extendGroup = (g, config) => {
  const { imageFolderUrl } = config;
  return {
    ...g,
    id: makeId(),
    images: g.images.map((img) => `${imageFolderUrl}${img}`),
    slug: `products/${slugify(g.name)}`,
  };
};

export const getAllProducts = (groups, config, data) => {
  let productIds = [];
  let products = [];
  let unpublished = [];
  const { dataProductIds, dataTags } = getData(data);

  groups.forEach((g) => {
    let productGroupConfig = config.productGroupConfig.find(
      (c) => c.name === g.name
    );

    if (productGroupConfig?.useGroupsAsItems) {
      if (!dataTags.includes(g.name)) {
        unpublished = [...unpublished, { name: g.name, type: "productGroup" }];
        return;
      }
      const extendedGroup = extendGroup(g, config);
      products = [...products, extendedGroup];
    } else {
      g.products.forEach((p) => {
        let productConfig = config.productConfig.find((x) => x.plu === p.plu);
        if (!dataProductIds.includes(p.id) && productConfig.isModification) {
          unpublished = [
            ...unpublished,
            { plu: p.plu, name: p.name, type: "product" },
          ];
          return;
        }
        if (!productIds.includes(p.id)) {
          productIds = [...productIds, p.id];
          products = [...products, p];
        }
      });
    }

    // add modifications
    g.products.forEach((p) => {
      let productConfig = config.productConfig.find((x) => x.plu === p.plu);
      if (productConfig?.isModification) {
        products = [...products, p];
      }
    });
  });
  if (unpublished.length > 0) {
    console.log("Unpublished items", unpublished);
  }
  return products;
};
