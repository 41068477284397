import {
  Button,
  Container,
  Divider,
  Grid,
  Group,
  Image,
  SimpleGrid,
  Text,
  Title,
} from "@mantine/core";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addArticleGroupTicket,
  addToCartTracking,
} from "../../../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../../../common/state";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { motion } from "framer-motion";
import { SaleContext } from "../../../common/saleProvider";
import { useMediaQuery } from "@mantine/hooks";
import GroupArticle from "./GroupArticle";
import classes from "./MultiTicket.module.css";
import placeholder from "../../../../images/placeholder.jpg";

const MultiTicket = ({ product, description }) => {
  const { culture, language, currency } = useSelector((state) => ({
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));

  const [groupTicketCart, groupTicketCartSet] = useState({
    plu: product.plu,
    quantity: 1,
    ticketOwners: [],
  });

  const [addedToCart, addedToCartSet] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const rdxDispatch = useDispatch();
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const mobile = useMediaQuery("(max-width: 768px)");

  const getTranslation = product?.translation.filter(
    (el) => el?.culture === culture
  )?.[0];

  const groupTicketInSale = sale?.items?.some(
    (item) => item.article.plu === groupTicketCart.plu
  );

  useEffect(() => {
    if (groupTicketCart.ticketOwners.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [groupTicketCart]);

  const addGroupTicket = () => {
    const trackingGroupTicket = [
      {
        id: product.plu,
        quantity: 1,
        price: product.price.amountInclVat,
        name: product.name,
      },
    ];
    const callback = (status, msg) => {
      if (status === 200) {
        addedToCartSet(true);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
      if (status !== 200) {
        addedToCartSet(false);
        setTimeout(() => {
          addedToCartSet(null);
        }, 5000);
      }
    };

    addToCartTracking(currency, trackingGroupTicket);
    groupTicketCartSet({
      plu: product.plu,
      quantity: 1,
      ticketOwners: [],
    });
    setTimeout(() => {
      rdxDispatch(addArticleGroupTicket(groupTicketCart, callback));
    });
  };

  return (
    <Container className={classes.containerWrapper}>
      <Grid gutter={28}>
        <Grid.Col span={{ sm: 12, md: 7 }}>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ height: "100%" }}
          >
            <Image
              src={product.images && product.images}
              fallbackSrc={placeholder}
              radius={"sm"}
              fit="cover"
              style={{ height: !product.images && "100%" }}
              h={
                mobile && product.images
                  ? "20rem"
                  : mobile && !product.images
                  ? "20rem"
                  : "25rem"
              }
              alt={`${language.ProductAltLabel} ${getTranslation.name}`}
            />
          </motion.div>
        </Grid.Col>
        <Grid.Col span={{ sm: 12, md: 5 }} className={classes.container}>
          <div className={classes.titleWrapper}>
            <h1 className="productTitle" style={{ fontSize: "1.5rem" }}>
              {getTranslation.name}
            </h1>
          </div>
          <p style={{ marginTop: "0.5rem", marginBottom: "1.5rem" }}>
            {language.GroupTicketDescription}
          </p>
          <SimpleGrid cols={1} style={{ gap: ".5rem" }}>
            {product.ticketHolders.map((el) => (
              <div key={el.categoryId}>
                <GroupArticle
                  allTicketsData={product.ticketHolders}
                  ticketData={el}
                  groupTicketCart={groupTicketCart}
                  groupTicketCartSet={groupTicketCartSet}
                />
                <Divider size="xs" />
              </div>
            ))}
          </SimpleGrid>
          <div className={classes.actionGrid}>
            <div
              className={classes.hideLabel}
              role="region"
              aria-live="assertive"
            />
            <Group>
              <span className={classes.price} component="span">
                {!product.isOpenPrice ? product.priceInclVat : ""}
              </span>
            </Group>
            <div
              className={classes.alertWrapper}
              role="region"
              aria-live="polite"
            >
              <Button
                variant="filled"
                size="md"
                className="regularButton add_to_cart_product"
                classNames={{
                  root: addedToCart && classes.addedToCart,
                  label: "add_to_cart_product",
                  inner: "add_to_cart_product",
                }}
                onClick={addGroupTicket}
                disabled={disabled}
                style={{
                  width: "100%",
                  transition: "all 0.2s ease-in-out",
                }}
              >
                {groupTicketInSale && !addedToCart
                  ? language.MaxInCart
                  : addedToCart
                  ? language.Added_To_Cart
                  : language.AddToCartBtn}
              </Button>
            </div>
          </div>
          <Divider my="lg" />
          <h2 className={`${classes.descriptionTitle} descriptionTitle`}>
            {language.Description}
          </h2>
          <div className={`${classes.descriptionBody} productDescription`}>
            <ReactMarkdown rehypePlugins={[rehypeRaw]}>
              {description ? description : getTranslation.description}
            </ReactMarkdown>
          </div>
        </Grid.Col>
      </Grid>
    </Container>
  );
};

export default MultiTicket;
