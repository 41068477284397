import React, { useContext, useEffect, useState } from "react";
import {
  addArticleBatch,
  addArticleGroupTicket,
  addToCartTracking,
} from "../../common/sale";
import { useDispatch, useSelector } from "react-redux";
import { CART_ARTICLE_ADD, Context } from "../../context";
import { getCartArticle } from "../cart/util";
import { useNavigate } from "react-router";
import { STATE_CURRENCY } from "../../common/state";
import { notification } from "../common/util";
import { Button } from "@mantine/core";

const AddToCart = ({
  cart,
  setCart,
  allotmentId,
  setClicked,
  language,
  groupTicketCart,
  groupTicketCartSet,
  allotments,
}) => {
  const rdxDispatch = useDispatch();
  const { dispatch } = useContext(Context);
  const { config, currency } = useSelector((state) => ({
    config: state.config,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [product, setProduct] = useState([]);
  const [text, setText] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    setProduct(groupByTimes(cart));
  }, [cart]);

  const reduceCart = Object.values(
    cart.reduce((acc, curr, i) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
          extraInformation: curr?.extraInformation,
        };
      } else if (acc[curr.plu] && curr?.extraInformation) {
        acc[i] = {
          plu: curr.plu,
          quantity: 1,
          allotmentId: curr.allotmentId,
          allotmentOccasion: curr.timeOccasions,
          extraInformation: curr?.extraInformation,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );

  const chosenAllotment =
    cart &&
    allotmentId &&
    cart.filter((el) => {
      return allotmentId.articles.some((ele) => {
        return el.id === ele.id;
      });
    });

  const groupByTimes = (data) =>
    Object.values(
      data.reduce(
        (data, { id, plu }) =>
          Object.assign({}, data, {
            [id]: data[id]
              ? { id, plu: [...data[id].plu, plu] }
              : { id, plu: [plu] },
          }),
        {}
      )
    );

  const cartArticle = product && getCartArticle(product, config);

  const productPrice = (item) => {
    if (item && item.length > 0) {
      const regex = /[$€£kr]/g;
      let newPrice = item.replace(regex, "");
      if (newPrice.trim().split(".")[1] === "00") {
        return newPrice.split(".")[0];
      } else {
        return newPrice;
      }
    }
  };

  const trackReduceCart = Object.values(
    cart.reduce((acc, curr) => {
      if (!acc[curr.plu]) {
        acc[curr.plu] = {
          id: curr.plu,
          quantity: 1,
          price: parseFloat(productPrice(curr.priceInclVat).replace(",", ".")),
          name: curr.name,
        };
      } else {
        acc[curr.plu].quantity += 1;
      }
      return acc;
    }, {})
  );
  const addGroupTicket = () => {
    const ticket = allotments.articles.find(
      (article) => article.plu === groupTicketCart.plu
    );
    const trackingGroupTicket = [
      {
        id: ticket.plu,
        quantity: 1,
        price: ticket.price.amountInclVat,
        name: ticket.name,
      },
    ];
    addToCartTracking(currency, trackingGroupTicket);
    setTimeout(() => {
      rdxDispatch(addArticleGroupTicket(groupTicketCart, cb));
    });
  };

  const add = () => {
    if (groupTicketCart) {
      setClicked(true);
      addGroupTicket();
    } else {
      setClicked(true);
      rdxDispatch(addArticleBatch(reduceCart, cb));
    }
  };

  const cb = (status, msg) => {
    if (status >= 400) {
      notification("error", language.ErrorMsgCouldNotAddToCart);
      setClicked(false);
      setCart([]);
      groupTicketCartSet(null);
    } else {
      dispatch({
        type: CART_ARTICLE_ADD,
        payload: cartArticle,
      });
      setCart([]);
      groupTicketCartSet(null);
      setClicked(false);
      setText(language.Added_To_Cart);
      setTimeout(() => {
        setText(null);
      }, 3000);
      config?.redirectEventCalendar && navigate("/ticketshop/cart");
      addToCartTracking(currency, trackReduceCart);
    }
  };

  return (
    <>
      <Button
        onClick={() => add()}
        disabled={chosenAllotment?.length === 0 && !groupTicketCart}
        aria-live="assertive"
        className="primaryButton"
        size="md"
      >
        {text ? text : language.AddToCartBtn}
      </Button>
    </>
  );
};

export default AddToCart;
