import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Button,
  Checkbox,
  Container,
  NumberInput,
  Stepper,
} from "@mantine/core";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_PUBLIC_BOOKING_REQUEST,
  GET_PUBLIC_BOOKING_PUBLIC_INFO,
} from "./publicBookingQuery";
import BookingSection from "./BookingSection";
import Login from "./Login";
import { useSelector } from "react-redux";
import Type from "./Type";
import DateTimePicker from "./DateTimePicker";
import { useMediaQuery } from "@mantine/hooks";
import TextField from "./ExtraForm/TextField";
import NumberField from "./ExtraForm/NumberField";
import CheckboxGroupField from "./ExtraForm/CheckboxGroupField";
import Confirmation from "./Confirmation";
import ButtonNavigation from "./ButtonNavigation";
import autoAnimate from "@formkit/auto-animate";
import { useForm } from "@mantine/form";
import Receipt from "./Receipt";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { STATE_CULTURE } from "../../common/state";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import RadioGroupField from "./ExtraForm/RadioGroupField";
import classes from "./PublicBooking.module.css";

const PublicBooking = ({ activityId, bookingApiClient, tag, subLabel }) => {
  const { user, auth } = useSelector((state) => ({
    user: state.oidc.user,
    auth: state.oidc.user !== null && !state.oidc.user.expired,
  }));
  const [introduction, introductionSet] = useState(null);
  const [extraFormIsValid, extraFormIsValidSet] = useState([
    { id: 99999, isValid: true },
  ]);
  const [consent, consentSet] = useState(false);
  const [active, activeSet] = useState(0);
  const [firstFetch, firstFetchSet] = useState(false);
  const [queryData, queryDataSet] = useState(null);
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const isMobile = useMediaQuery("(max-width: 768px)");

  const form = useForm({
    initialValues: {
      activityId: "",
      responsibleId: "",
      publicBookingTypeId: "",
      occasion: "",
      numberOfParticipants: 0,
      formFields: [],
    },
  });

  const { data, loading, error } = useQuery(GET_PUBLIC_BOOKING_PUBLIC_INFO, {
    client: bookingApiClient,
    variables: { activityId },
    fetchPolicy: "cache-and-network",
    skip: firstFetch === false,
    onCompleted: () => {
      firstFetchSet(false);
      queryDataSet(data);
    },
  });

  const [
    publicBookingRequest,
    { data: mutationData, loading: mutationLoading, error: mutationError },
  ] = useMutation(CREATE_PUBLIC_BOOKING_REQUEST, {
    client: bookingApiClient,
  });

  useEffect(() => {
    if (activityId) {
      firstFetchSet(true);
    }
    form.reset();
    activeSet(0);
    consentSet(false);
  }, [activityId, window.location.pathname]);

  useEffect(() => {
    if (activityId) {
      form.setFieldValue("activityId", activityId);
      axios
        .get(
          `${config.baseUrl}/ee-static/shop/${config.shopName}/Texts/public-booking-${activityId}.${culture}.md`
        )
        .then((res) => introductionSet(res.data))
        .catch(() => introductionSet(""));
    }
  }, [config, culture, activityId]);

  const handleSubmit = (values) => {
    const getStringifiedFields = values?.formFields.reduce((acc, curr) => {
      acc.push(JSON.stringify(curr));
      return acc;
    }, []);

    publicBookingRequest({
      variables: {
        activityId: values.activityId,
        numberOfParticipants: values.numberOfParticipants,
        occasion: values.occasion,
        publicBookingTypeId: values.publicBookingTypeId,
        responsibleId: user?.profile?.id ? user?.profile?.id : "0",
        formFields: getStringifiedFields,
      },
    });
  };

  const parent = useRef(null);
  const stepRef = useRef(null);
  const matches = useMediaQuery("(max-width: 768px)");

  const formFields = (field, index) => {
    switch (field.fieldType) {
      case "STRING":
        return (
          <TextField
            type={"STRING"}
            placeholder={`${field.name} ${language.Max} ${field.fieldConstraints.max} ${language.Characters}`}
            label={field.name}
            required={field.fieldConstraints.isMandatory}
            description={field.description}
            min={field.fieldConstraints.min}
            max={field.fieldConstraints.max}
            values={field.fieldConstraints.values}
            form={form}
            index={index}
            id={field.id}
            extraFormIsValid={extraFormIsValid}
            extraFormIsValidSet={extraFormIsValidSet}
          />
        );
      case "TEXT":
        return (
          <TextField
            type={"TEXT"}
            placeholder={`${field.name} ${language.Max} ${field.fieldConstraints.max} ${language.Characters}`}
            label={field.name}
            required={field.fieldConstraints.isMandatory}
            description={field.description}
            min={field.fieldConstraints.min}
            max={field.fieldConstraints.max}
            values={field.fieldConstraints.values}
            form={form}
            index={index}
            id={field.id}
            extraFormIsValid={extraFormIsValid}
            extraFormIsValidSet={extraFormIsValidSet}
          />
        );
      case "INTEGER":
        return (
          <NumberField
            placeholder={0}
            label={field.name}
            required={field.fieldConstraints.isMandatory}
            description={field.description}
            min={field.fieldConstraints.min}
            max={field.fieldConstraints.max}
            values={field.fieldConstraints.values}
            {...form.getInputProps("formFields")}
            id={field.id}
            form={form}
          />
        );
      case "SINGLE_SELECT":
        return (
          <RadioGroupField
            label={field.name}
            required={field.fieldConstraints.isMandatory}
            id={field.id}
            description={field.description}
            values={field.fieldConstraints.values}
            {...form.getInputProps("formFields")}
            form={form}
          />
        );
      case "MULTI_SELECT":
        return (
          <CheckboxGroupField
            label={field.name}
            required={field.fieldConstraints.isMandatory}
            description={field.description}
            values={field.fieldConstraints.values}
            {...form.getInputProps("formFields")}
            id={field.id}
            form={form}
          />
        );
      default:
        return null;
    }
  };

  const nextStep = () => activeSet((current) => current + 1);
  const prevStep = () => activeSet((current) => current - 1);

  useEffect(() => {
    parent.current && autoAnimate(parent.current);
  }, [parent]);

  useEffect(() => {
    stepRef.current &&
      stepRef.current.scrollIntoView({
        behavior: "smooth",
      });
  }, [active]);

  const getMinMaxParticipants = useMemo(() => {
    const type = queryData?.publicBookingPublicInfo?.publicBookingTypes.filter(
      (el) => el.id === form.values.publicBookingTypeId
    );
    return type;
  }, [queryData, form]);

  const type = (item) => {
    if (item.fieldType === "STRING") {
      return { id: item.id, stringVal: null };
    }
    if (item.fieldType === "TEXT") {
      return { id: item.id, stringVal: null };
    }
    if (item.fieldType === "INTEGER") {
      return { id: item.id, intVal: null };
    }
    if (item.fieldType === "SINGLE_SELECT") {
      return { id: item.id, stringVal: null };
    }
    if (item.fieldType === "MULTI_SELECT") {
      return { id: item.id, multiVal: [] };
    }
  };
  const insertListItems = () => {
    if (form.values.formFields.length === 0) {
      return queryData?.publicBookingPublicInfo?.formFields.map((el) =>
        form.insertListItem("formFields", type(el))
      );
    }
  };

  const checkData = (value) => {
    if (value === undefined) {
      return true;
    } else if (value === null) {
      return true;
    } else if (value?.length === 0) {
      return true;
    } else if (value) {
      return false;
    }
  };

  const checkMandatory = (fields) => {
    const isMandatory =
      form.values.formFields.length > 0 &&
      fields &&
      fields.length > 0 &&
      fields.reduce((acc, curr) => {
        if (curr.fieldConstraints.isMandatory) {
          const getFormData = form.values.formFields.filter(
            (el) => el.id === curr.id
          );
          if (getFormData?.[0]) {
            acc.push(getFormData?.[0]);
          }
        }
        return acc;
      }, []);

    const check =
      isMandatory &&
      isMandatory.some((el) =>
        el.hasOwnProperty("intVal")
          ? checkData(el.intVal)
          : el.hasOwnProperty("stringVal")
          ? checkData(el.stringVal)
          : checkData(el.multiVal)
      );

    const checkExtraForm = extraFormIsValid.filter(
      (el) => el.isValid === false
    );
    if (checkExtraForm.length === 0) return check;
    return true;
  };

  const sortedTypes = useMemo(() => {
    if (
      queryData &&
      queryData?.publicBookingPublicInfo?.publicBookingTypes.length > 0
    ) {
      const typesCopy = [
        ...queryData?.publicBookingPublicInfo?.publicBookingTypes,
      ];
      return typesCopy.sort((a, b) => {
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        else return 0;
      });
    }
    return [];
  }, [queryData]);

  const loggedInUser = `${language.LoggedInAs} ${user?.profile?.given_name} ${user?.profile?.family_name}`;
  const loggedOutUser = `${language.Not_Logged_In}`;

  let sortedformFields;
  if (queryData && queryData.publicBookingPublicInfo) {
    const clone = [...queryData.publicBookingPublicInfo.formFields];
    sortedformFields = clone.sort((a, b) => a.order - b.order);
  }

  if (!activityId) return null;
  return (
    <Container m={0} p={16} style={{ maxWidth: "100%" }}>
      <form
        onSubmit={form.onSubmit((values) => {
          handleSubmit(values);
          nextStep();
        })}
      >
        <div>
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {introduction}
          </ReactMarkdown>
        </div>
        <div ref={stepRef} />
        <Stepper
          active={active}
          onStepClick={activeSet}
          style={{
            paddingTop: "2rem",
            marginTop: isMobile
              ? "var(--header-height-xs)"
              : "var(--header-height)",
          }}
        >
          <Stepper.Step
            allowStepSelect={active > 0}
            aria-label={`${language.Step} ${1}, ${language.Log_On}`}
          >
            <div className={classes.accordionWrapper}>
              <BookingSection
                label={language.Log_On + " - "}
                subLabel={!auth ? loggedOutUser : loggedInUser}
              >
                <Login />
              </BookingSection>
              <ButtonNavigation
                activeStep={0}
                onNextClick={nextStep}
                onPrevClick={prevStep}
                isDisabled={!auth}
              />
            </div>
          </Stepper.Step>
          <Stepper.Step
            allowStepSelect={active > 1}
            disabled={active < 1}
            aria-label={`${language.Step} ${2}, ${language.ChooseType}`}
          >
            <div className={classes.accordionWrapper}>
              <BookingSection label={language.Type + " - "} subLabel={subLabel}>
                <ul className={classes.typeWrapper}>
                  {sortedTypes.map((el, i) => (
                    <li key={el.id}>
                      <Type
                        id={el.id}
                        name={el.name}
                        description={el.description}
                        maxParticipants={el.maxParticipants}
                        minParticipants={el.minParticipants}
                        occasionLengthInMinutes={el.occasionLengthInMinutes}
                        // articlesToApply={el.articlesToApply}
                        {...form.getInputProps("publicBookingTypeId")}
                        form={form}
                        tag={tag[i]}
                      />
                    </li>
                  ))}
                </ul>
              </BookingSection>
              <ButtonNavigation
                activeStep={1}
                onNextClick={nextStep}
                onPrevClick={prevStep}
                isDisabled={form.values.publicBookingTypeId === ""}
              />
            </div>
          </Stepper.Step>

          <Stepper.Step
            allowStepSelect={active > 2}
            disabled={active < 2}
            aria-label={`${language.Step} ${3}, ${language.ChooseDateHeader}`}
          >
            <div className={classes.accordionWrapper}>
              <div>
                <BookingSection label={language.ChooseDateHeader}>
                  <DateTimePicker
                    form={form}
                    bookingApiClient={bookingApiClient}
                  />
                </BookingSection>
                <ButtonNavigation
                  activeStep={2}
                  onNextClick={nextStep}
                  onPrevClick={prevStep}
                  isDisabled={!form.values.occasion.includes("T")}
                />
              </div>
            </div>
          </Stepper.Step>

          <Stepper.Step
            allowStepSelect={active > 3}
            disabled={active < 3}
            aria-label={`${language.Step} ${4}, ${language.BasicInformation}`}
          >
            <div className={classes.accordionWrapper}>
              <BookingSection label={language.BasicInformation}>
                <NumberInput
                  classNames={{
                    label: classes.numberInput,
                    description: classes.description,
                  }}
                  defaultValue={0}
                  placeholder={language.AmountOfParticipants}
                  label={language.AmountOfParticipants}
                  description={`${language.AmountOfParticipantsDescription} (${getMinMaxParticipants?.[0]?.minParticipants}-${getMinMaxParticipants?.[0]?.maxParticipants})`}
                  withAsterisk
                  required
                  min={getMinMaxParticipants?.[0]?.minParticipants}
                  max={getMinMaxParticipants?.[0]?.maxParticipants}
                  style={{ width: matches ? "100%" : "50%" }}
                  {...form.getInputProps("numberOfParticipants")}
                />
              </BookingSection>
              <ButtonNavigation
                activeStep={3}
                onNextClick={nextStep}
                onPrevClick={prevStep}
                isDisabled={
                  form.values.numberOfParticipants <
                  getMinMaxParticipants?.[0]?.minParticipants
                }
                onClick={() => insertListItems()}
              />
            </div>
          </Stepper.Step>
          <Stepper.Step
            allowStepSelect={active > 4}
            disabled={active < 4}
            aria-label={`${language.Step} ${5}, ${language.ExtraFields}`}
          >
            <div className={classes.accordionWrapper}>
              <BookingSection label={language.ExtraFields}>
                <div className={classes.extraFieldWrapper}>
                  {sortedformFields &&
                    sortedformFields.map((el, i) => (
                      <div key={i}>{formFields(el, i)}</div>
                    ))}
                </div>
              </BookingSection>
              <ButtonNavigation
                activeStep={4}
                onNextClick={nextStep}
                onPrevClick={prevStep}
                isDisabled={checkMandatory(
                  queryData?.publicBookingPublicInfo?.formFields
                )}
              />
            </div>
          </Stepper.Step>
          <Stepper.Step
            allowStepSelect={active > 5}
            disabled={active < 5}
            color={
              mutationData?.publicBookingRequest?.success === false
                ? "#ef4444 !important"
                : mutationData?.publicBookingRequest?.success &&
                  "#16a34a !important"
            }
            aria-label={`${language.Step} ${6}, ${language.ConfirmBooking}`}
            completedIcon={
              mutationData?.publicBookingRequest?.success === false && (
                <IoIosCloseCircleOutline
                  style={{
                    width: "1.5rem",
                    height: "1.5rem",
                    strokeWidth: "1.5rem",
                  }}
                />
              )
            }
          >
            <div className={classes.accordionWrapper}>
              <BookingSection label={language.ConfirmBooking}>
                <Confirmation
                  form={form}
                  data={queryData?.publicBookingPublicInfo}
                  name={`${user?.profile?.given_name} ${user?.profile?.family_name}`}
                />
              </BookingSection>

              <div className={classes.confirmationWrapper}>
                <Checkbox
                  classNames={{ label: classes.confirmationCheckbox }}
                  required={true}
                  label={language.BookingConsent}
                  checked={consent}
                  onChange={(e) => consentSet(e.currentTarget.checked)}
                />
              </div>
              <div className={classes.confirmationButtonWrapper}>
                <ButtonNavigation
                  activeStep={5}
                  onNextClick={nextStep}
                  onPrevClick={prevStep}
                />
                <Button
                  className={"primaryButton"}
                  size="md"
                  type="submit"
                  disabled={!consent}
                >
                  {language.SendBooking}
                </Button>
              </div>
            </div>
          </Stepper.Step>
          <Stepper.Completed>
            <div className={classes.confirmationWrapper}>
              <Receipt
                onPrevClick={prevStep}
                success={mutationData?.publicBookingRequest?.success}
                error={mutationData?.publicBookingRequest?.errorMessage}
              />
            </div>
          </Stepper.Completed>
        </Stepper>
      </form>
    </Container>
  );
};
export default PublicBooking;
