import React from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import PageLayout from "../../components/Styles/PageLayout";
import User from "../../components/User";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import PageContent from "../../components/Styles/PageContent";

const query = gql`
  query User($currency: String, $culture: String, $posId: Int) {
    ticketowners(currency: $currency, culture: $culture, posId: $posId) {
      id
      name
      email
      photo
      details {
        civicRegistrationNumber
      }
      tickets {
        id
        fundsAmount
        fundsId
        owner {
          name
          firstname
          lastname
          email
          civicRegistrationNumber
        }
        validFrom
        validUntil
        article {
          id
          name
          plu
        }
        uniqueIdentifier
        applicableModifications {
          id
          name
          description
          plu
          priceInclVat
          price {
            amountInclVat
          }
        }
      }
    }
    whoami {
      id
      name
      firstname
      lastname
      email
      civicRegistrationNumber
      isAuthenticated
      photo
    }
  }
`;

const UserPage = (props) => {
  const { auth, config, culture, currency } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { posId } = config;
  const { data, error, loading } = useQuery(query, {
    variables: { posId, currency, culture },
  });

  if (!auth) return <div>Du behöver vara inloggad för att se din sida.</div>;
  if (loading) return <div />;
  if (error) {
    console.log(error.message);
    return <div />;
  }
  if (auth && !data.whoami) {
    console.log(data);
  }
  return (
    <PageLayout>
      <PageContent>
        <User user={data} />
      </PageContent>
    </PageLayout>
  );
};

export default UserPage;
