import { useSelector } from "react-redux";
import { Stepper } from "@mantine/core";
import { useState } from "react";
import classes from "./Options.module.css";
import Description from "./Description";
import EventItem from "./EventItem";
import Item from "./Item";
import { motion } from "framer-motion";
import { itemVariants } from "./variants";
import { useContext } from "react";
import { DataContext } from "../common/dataProvider";
import { useCallback } from "react";
import OptionsCart from "./OptionsCart";
import { useEffect } from "react";

const Options = () => {
  const { config } = useSelector((state) => ({
    config: state.config,
  }));
  const { data } = useContext(DataContext);
  const [active, setActive] = useState(0);
  const [hasMounted, setHasMounted] = useState(false);

  const product = useCallback(
    (plu) => {
      return data?.articles.find((product) => product.plu === plu);
    },
    [data]
  );
  const allotment = useCallback(
    (id) => {
      return data?.allotments.find((allotment) => allotment.id === id);
    },
    [data]
  );

  const returnFocus = () => {
    const step = document.getElementById("active-stepper");
    if (step) step.focus();
  };

  useEffect(() => {
    if (active) {
      setHasMounted(true);
    }
    if (hasMounted) {
      returnFocus();
    }
  }, [active]);

  return (
    <div>
      {config?.optionsConfig.map((option, i) => {
        if (active === i) {
          return (
            <Description key={option.name} description={option.description} />
          );
        }
      })}
      <div className={classes.wrapper}>
        {config?.optionsConfig.length > 1 && (
          <div className={`${classes.container} options-container`}>
            <Stepper
              active={active}
              onStepClick={setActive}
              className={classes.stepper}
              classNames={{
                separator: classes.separator,
                steps: classes.steps,
                step: classes.step,
                stepBody: classes.stepBody,
              }}
              size={30}
            >
              {config?.optionsConfig.map((option, i) => {
                if (!option.disabled) {
                  return (
                    <Stepper.Step
                      key={option.name}
                      label={option.name}
                      description=""
                      id={active === i ? "active-stepper" : ""}
                    >
                      {option.events.length > 0 &&
                        option.events.map((event, i) => (
                          <motion.div
                            key={`event-${event}`}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={itemVariants}
                          >
                            <EventItem
                              data={allotment(event)}
                              allotmentId={event}
                            />
                          </motion.div>
                        ))}
                      {option.plu.length > 0 &&
                        option.plu.map((item, i) => (
                          <motion.div
                            key={`item-${item}`}
                            initial="hidden"
                            animate="visible"
                            exit="hidden"
                            variants={itemVariants}
                          >
                            <Item data={product(item)} />
                          </motion.div>
                        ))}
                    </Stepper.Step>
                  );
                }
              })}
            </Stepper>
          </div>
        )}
        {config?.optionsConfig.length === 1 && (
          <div className={`${classes.singleContainer} options-container`}>
            {config?.optionsConfig[0].events.length > 0 &&
              config?.optionsConfig[0].events.map((event, i) => (
                <motion.div
                  className={classes.singleItem}
                  key={event + i}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={itemVariants}
                >
                  <EventItem data={allotment(event)} allotmentId={event} />
                </motion.div>
              ))}
            {config?.optionsConfig[0].plu.length > 0 &&
              config?.optionsConfig[0].plu.map((item, i) => (
                <motion.div
                  className={classes.singleItem}
                  key={item}
                  initial="hidden"
                  animate="visible"
                  exit="hidden"
                  variants={itemVariants}
                >
                  <Item data={product(item)} />
                </motion.div>
              ))}
          </div>
        )}
        <OptionsCart
          active={active}
          setActive={setActive}
          maxStep={config?.optionsConfig.length}
        />
      </div>
    </div>
  );
};

export default Options;
