import { useDisclosure } from "@mantine/hooks";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import { useState } from "react";
import { motion } from "framer-motion";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import OptionsModal from "../Options/OptionsModal";

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};

const AllotmentOption = ({
  allotment,
  item,
  timeSelected,
  cart,
  setCart,
  allotmentItemOutOfStock,
  setAllotmentItemOutOfStock,
  optionAllotments,
  optionAllotmentsRemaining,
  calculateQuantity,
  fullOptionAllotment,
}) => {
  const { language, culture } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const [extraInformation, setExtraInformation] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);

  const translation = (item) => {
    if (item?.translation) {
      const getLang = item.translation.filter((el) => el.culture === culture);
      return getLang[0].name;
    } else {
      return item.name;
    }
  };

  const addAllotmentItem = (product, timeSelected, occasion) => {
    if (optionAllotments !== undefined) {
      let data = [];
      data = [occasion];
      const newFilter =
        data &&
        data.reduce((acc, curr) => {
          const item = curr.occasions.filter((ele) => {
            return ele.time.split("T")[0] === timeSelected.split("T")[0];
          });
          acc.push(item);
          return acc;
        }, []);

      const newFilterFlat = newFilter[0];
      const time = newFilterFlat[0].time;
      const newProduct = {
        ...product,
        allotmentId: occasion.id,
        timeOccasions: time,
        extraInformation: extraInformation,
      };

      const filterAllotment = optionAllotmentsRemaining.filter(
        (el) => el.description === occasion.description
      );

      const filterTime = filterAllotment[0].occasions.filter(
        (el) => el.time.split("T")[0] === timeSelected.split("T")[0]
      );
      if (
        calculateQuantity(newProduct) + 1 <= filterTime[0].remaining &&
        calculateQuantity(newProduct) + 1 <= filterTime[0].allotment.maxPerSale
      ) {
        setCart([...cart, newProduct]);
      }
      if (
        calculateQuantity(newProduct) + 1 === filterTime[0].remaining ||
        calculateQuantity(newProduct) + 1 === filterTime[0].allotment.maxPerSale
      ) {
        setAllotmentItemOutOfStock([
          ...allotmentItemOutOfStock,
          newProduct.name,
        ]);
      }
    }
  };

  const removeAllotmentItem = (el, occasion) => {
    const filterAllotment = optionAllotmentsRemaining.filter(
      (ele) => ele.description === occasion.description
    );
    const filterTime = filterAllotment[0].occasions.filter(
      (ele) => ele.time.split("T")[0] === timeSelected.split("T")[0]
    );

    let newCart = [...cart];
    let res = cart.findLastIndex((i) => i.id === el.id);
    newCart.splice(res, 1);
    setCart(newCart);

    if (calculateQuantity(el) <= filterTime[0].remaining) {
      setAllotmentItemOutOfStock(
        allotmentItemOutOfStock.filter((name) => name !== el.name)
      );
    }
  };

  return (
    <div className="calendar-articleWrapper" key={item.name + item.id}>
      <div className="calendar-articleLeftColumn">
        <span className="calendar-articleTitle">{translation(item)}</span>
        <span className="calendar-articlePrice">{item.priceInclVat}</span>
      </div>
      <div className="calendar-articleRightColumnToggle">
        <motion.div
          className="calendar-switch"
          data-ison={calculateQuantity(item) > 0 ? true : ""}
          transition={spring}
          layout
        />
        <motion.div
          className={`calendar-handle ${
            fullOptionAllotment(item) ? "calendar-hideScreen" : ""
          }`}
          data-ison={calculateQuantity(item) > 0 ? true : ""}
          whileTap={{ scale: 1.2 }}
          layout
          transition={spring}
          style={{
            pointerEvents: allotmentItemOutOfStock.includes(item.name)
              ? "none"
              : "",
          }}
        >
          <button
            className={`calendar-articleButton`}
            onClick={() => {
              if (item.additionalFields.length > 0) {
                open();
              } else {
                addAllotmentItem(item, timeSelected, allotment);
              }
            }}
            disabled={fullOptionAllotment(item)}
            aria-label={`${language?.AddProduct} ${translation(allotment)}`}
          >
            <MdAddCircle className="buttonIcons" />
          </button>
        </motion.div>
        <motion.div
          className="calendar-count calendar-articleQty"
          data-ison={calculateQuantity(item) > 0 ? true : ""}
          layout
          transition={spring}
        >
          {calculateQuantity(item)}
        </motion.div>
        <motion.div
          data-ison={calculateQuantity(item) > 0 ? true : ""}
          className="calendar-handle2"
          whileTap={{ scale: 1.2 }}
          layout
          transition={spring}
        >
          <button
            className={`calendar-articleButtonRemove ${
              calculateQuantity(item) === 0 ? "calendar-hideScreen" : ""
            }`}
            onClick={() => removeAllotmentItem(item, allotment)}
            aria-label={`${language?.RemoveProduct} ${calculateQuantity(
              item
            )} ${translation(allotment)}`}
          >
            <MdRemoveCircle className="buttonIcons" />
          </button>
        </motion.div>
      </div>
      {item?.additionalFields?.length > 0 && (
        <OptionsModal
          title={translation(item)}
          opened={opened}
          close={close}
          additionalFields={item.additionalFields}
          handleAdd={() => addAllotmentItem(item, timeSelected, allotment)}
          extraInformation={extraInformation}
          setExtraInformation={setExtraInformation}
        />
      )}
    </div>
  );
};

export default AllotmentOption;
