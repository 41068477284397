import { Image, Select } from "@mantine/core";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../../common/state";
import "./styles.css";
import classes from "./ProductSelector.module.css";
import placeholder from "../../../images/placeholder.jpg";
import { useLocation } from "react-router";

const ProductSelector = ({ product, setSelected }) => {
  const [value, setValue] = useState(null);
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
  }));
  const location = useLocation();

  const sortedItems = useMemo(() => {
    if (!product.articles) return [];
    const clone = [...product.articles];
    return clone.sort((a, b) => a.plu - b.plu);
  }, [product.articles]);

  const items =
    !product.openPriceValues &&
    sortedItems.reduce((acc, curr, index) => {
      acc.push({
        key: index,
        value: curr.id,
        label:
          curr.inStock <= 0
            ? curr.name + " " + language.ArticleBooking.OutOfStock
            : curr.name + " " + curr.priceInclVat,
        disabled: curr.inStock <= 0,
      });
      return acc;
    }, []);

  const openPriceValues =
    product.openPriceValues &&
    product.openPriceValues.reduce((acc, curr, index) => {
      acc.push({
        key: index,
        value: String(curr),
        label: curr + " kr",
      });
      return acc;
    }, []);

  useEffect(() => {
    if (value && product.articles) {
      const getItem = product.articles.filter((el) => el.id === value);
      if (getItem) {
        setSelected(getItem);
      } else {
        setSelected(null);
      }
    }
    if (value && product.openPriceValues) {
      setSelected({ plu: product.plu, priceInclVat: value, quantity: 1 });
    }
  }, [product, value]);

  useEffect(() => {
    setValue(null);
  }, [location]);

  const getName = (item) => {
    return item.translation.filter((el) => el.culture === culture)[0].name;
  };

  const image = (item) => {
    if (item.imageFilename) {
      const uri = config?.apiUriOverride?.startsWith("http")
        ? config.apiUriOverride
        : config.apiUri;
      return `${uri}/articleimage/${item.imageFilename}`;
    }
    return;
  };

  return (
    <div className={classes.container}>
      <div className={`productSelector ${classes.wrapper}`}>
        {config?.useProductImageSelector && !product?.openPriceValues ? (
          <div className={classes.produtSelectorWrapper}>
            <span className={classes.chooseVariant}>
              {language.ChooseVariant}
            </span>
            <span className={classes.chooseArticle}>
              {language.ChooseArticle}
            </span>
            <div className={classes.productImageSelector}>
              {!product?.openPriceValues &&
                product.articles.map((el, i) => (
                  <div className={classes.productWrapper} key={i}>
                    <button
                      className={classes.imageButton}
                      onClick={() => setValue(el.id)}
                      aria-label={`${language.ChooseVariant} ${getName(el)} ${
                        el?.id === value ? language.Chosen : ""
                      }`}
                    >
                      <Image
                        className={`${classes.productImage} groupImage`}
                        src={image(el)}
                        fallbackSrc={placeholder}
                        alt={`${language.Product} ${getName(el)} ${
                          language.AddToCartBtn
                        }`}
                        radius="sm"
                        caption={getName(el)}
                        data-isselected={el?.id === value}
                      />
                    </button>
                  </div>
                ))}
            </div>
          </div>
        ) : (
          !product?.openPriceValues &&
          items &&
          items.length > 0 && (
            <Select
              label={language.ChooseVariant}
              placeholder={language.Product}
              comboboxProps={{ zIndex: 98 }}
              size="md"
              value={value}
              onChange={setValue}
              data={items}
              style={{ paddingTop: 0 }}
            />
          )
        )}
        {product?.openPriceValues && (
          <Select
            label={language.ChooseVariant}
            placeholder={language.Product}
            comboboxProps={{ zIndex: 98 }}
            size="md"
            value={value}
            onChange={setValue}
            data={openPriceValues}
            style={{ paddingTop: 0 }}
          />
        )}
      </div>
    </div>
  );
};

export default ProductSelector;
