import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import { createRoot } from "react-dom/client";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";
import "react-image-gallery/styles/css/image-gallery.css";
import "./index.css";
import "./App.css";
import Main from "./main";
import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { cookieMiddleware, cookieReducer } from "./common/cookies";
import { keys, STATE_CULTURE } from "./common/state";
import { saleMiddleware, saleReducer, validateSaleId } from "./common/sale";
import { promiseMiddleware, thunkMiddleware } from "./common/middleware";
import { busyReducer } from "./common/busy";
import { languageReducer, loadLanguage } from "./common/language";
import { CONFIG_LOADED, configReducer } from "./common/config";
import createOidcMiddleware, {
  OidcProvider,
  reducer as oidcReducer,
} from "redux-oidc";
import { getUserManager } from "./common/auth";
import Callback from "./common/callback";
import SilentRenew from "./common/silentRenew";
import Security from "./components/common/security";
import Terms from "./components/common/terms";
import Privacy from "./components/common/privacy";
import OldBrowser from "./components/common/oldBrowser";
import ContextProvider from "./context";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { MantineProvider } from "@mantine/core";
import platform from "platform";

const dummyMiddleware = () => (next) => (action) => {
  return next(action);
};

try {
  if (
    typeof platform !== "undefined" &&
    platform.hasOwnProperty("name") &&
    platform.name &&
    platform.hasOwnProperty("version") &&
    platform.version
  ) {
    const normalizedBrowserName = platform.name.toLowerCase();
    const normalizedVersion = parseInt(platform.version, 10);
    if (
      (normalizedBrowserName === "chrome" && normalizedVersion < 41) ||
      (normalizedBrowserName === "firefox" && normalizedVersion < 49) ||
      (normalizedBrowserName === "safari" && normalizedVersion < 7) ||
      (normalizedBrowserName === "microsoft edge" && normalizedVersion < 14) ||
      (normalizedBrowserName === "ie" && normalizedVersion < 12)
    ) {
      window.location.href = "/ticketshop/old-browser.html";
    }
  }
} catch (error) {
  //Left blank
}

if (!window.CSS) {
  const container = window.document.getElementById("root");
  const heading = window.document.createElement("h1");
  const headingText = window.document.createTextNode("Gammal Webbläsare!");
  heading.appendChild(headingText);
  container.appendChild(heading);
  console.log(container);
} else {
  fetch(process.env.PUBLIC_URL + "/config.json")
    .then((response) => response.json())
    .then((config) => {
      const getJson = (f) => {
        return fetch(f).then((x) => x.json());
      };

      const merge = (o, rest) => {
        var next = rest.pop();
        if (!next) return o;

        const merged = {
          ...o,
          ...next,
        };
        return merge(merged, rest);
      };

      const configUri = process.env.PUBLIC_URL + "/config.json";
      const localUri = `${config.baseUrl}/ee-static/shop/${config.shopName}/local.json`;
      const files = [configUri, localUri].map((f) => getJson(f));

      Promise.all(files)
        .then((response) => merge({}, response))
        .then((config) => {
          console.log(config);
          const {
            apiUri,
            apiUriOverride,
            posId,
            version,
            shopName,
            pageTitle,
          } = config;
          const uri = apiUriOverride?.startsWith("http")
            ? apiUriOverride
            : apiUri;
          console.log(uri);
          if (pageTitle) {
            window.document.title = pageTitle;
          } else {
            window.document.title = shopName;
          }
          console.log("version " + version);
          //Add local stylesheet
          let link = window.document.createElement("link");
          link.href = `${config.baseUrl}/ee-static/shop/${config.shopName}/style.css`;
          link.rel = "stylesheet";
          window.document.getElementsByTagName("head")[0].appendChild(link);
          const container = window.document.getElementById("root");
          const root = createRoot(container);

          const userManager = getUserManager(config);

          const reducers = combineReducers({
            cookies: cookieReducer(keys(), config),
            sale: saleReducer,
            busy: busyReducer,
            language: languageReducer,
            config: configReducer,
            oidc: oidcReducer,
          });

          const oidcMiddleware = !userManager.error
            ? createOidcMiddleware(
                userManager,
                () => true,
                false,
                `${process.env.PUBLIC_URL}/callback`
              )
            : dummyMiddleware;

          const store = configureStore({
            reducer: reducers,
            middleware: (getDefaultMiddleware) =>
              getDefaultMiddleware().concat(
                cookieMiddleware,
                saleMiddleware({ apiUri: uri, posId }),
                promiseMiddleware,
                thunkMiddleware,
                oidcMiddleware
              ),
            devTools: process.env.NODE_ENV !== "production",
          });

          store.dispatch({ type: CONFIG_LOADED, payload: config });
          store.dispatch(validateSaleId(uri, () => console.log("reset")));

          //Create Apollo client and auth
          const httpLink = new HttpLink({ uri: `${uri}/graphql` });
          const authLink = new ApolloLink((operation, forward) => {
            const { oidc } = store.getState();

            const token = oidc.user ? oidc.user.access_token : null;
            if (token) {
              operation.setContext({
                headers: {
                  authorization: token ? `Bearer ${token}` : null,
                },
              });
            }

            return forward(operation);
          });

          const client = new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache(),
          });

          let culture = store.getState().cookies[STATE_CULTURE];
          loadLanguage(culture, config, store.dispatch);

          if (userManager.error) {
            root.render(
              <ApolloProvider client={client}>
                <Main />
              </ApolloProvider>
            );
          } else {
            if (!window.CSS) return root.render(<OldBrowser />);
            if (!CSS.supports("display", "grid"))
              return root.render(<OldBrowser />);
            root.render(
              <ApolloProvider client={client}>
                <Provider store={store}>
                  <OidcProvider store={store} userManager={userManager}>
                    <ContextProvider userManager={userManager}>
                      <MantineProvider>
                        <BrowserRouter basename="ticketshop">
                          <Routes>
                            <Route
                              path="/callback"
                              element={<Callback userManager={userManager} />}
                            />
                            <Route
                              path="/silent_renew"
                              element={<SilentRenew />}
                            />
                            <Route path="/security" element={<Security />} />
                            <Route path="/terms" element={<Terms />} />
                            <Route path="/privacy" element={<Privacy />} />
                            <Route
                              path="/oldbrowser"
                              element={<OldBrowser />}
                            />
                            <Route path="/*" element={<Main />} />
                          </Routes>
                        </BrowserRouter>
                      </MantineProvider>
                    </ContextProvider>
                  </OidcProvider>
                </Provider>
              </ApolloProvider>
            );
          }
        });
    });
}
