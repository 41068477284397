import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { Context } from "../../context";
import { Button, UnstyledButton } from "@mantine/core";

const Login = ({ changeBackground }) => {
  const { handleLogin } = useContext(Context);
  const { auth, language } = useSelector((state) => ({
    auth: state.oidc.user !== null && !state.oidc.user.expired,
    language: state.language,
  }));
  return auth ? (
    <Link className="nav-headerLoginButton" to="/user" title={language.Account}>
      <UnstyledButton
        className="header__user-button"
        style={{ padding: "12px" }}
        aria-label="User"
      >
        <FaUserCircle size="24px" />
      </UnstyledButton>
    </Link>
  ) : (
    <Button
      variant="filled"
      className="header__login-button primaryButton"
      onClick={handleLogin}
    >
      {(language && language.Log_On) || ""}
    </Button>
  );
};

export default Login;
