import { Accordion, Button, Center, Modal, Switch } from "@mantine/core";
import classes from "./CookieBanner.module.css";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { cookieOptions, gtag, removeTrackingCookies } from "./util";
import { useDispatch, useSelector } from "react-redux";
import { setConsent } from "../../common/state";
import Cookies from "universal-cookie";

function AccordionControl(props) {
  const isActive = props.disabled
    ? false
    : props.checked && props.disabled
    ? false
    : props.checked
    ? true
    : false;
  return (
    <Center>
      <Accordion.Control {...props} disabled={false} />
      <Switch
        className={classes.switch}
        aria-label="Nödvändiga"
        disabled={props?.disabled}
        defaultChecked
        size="md"
        onLabel="På"
        offLabel="Av"
        checked={props?.checked}
        onChange={props?.onChange}
        classNames={{
          track: isActive && classes.switchTrack,
        }}
      />
    </Center>
  );
}
const ConsentContent = ({ opened, close, onAccept }) => {
  const [config, language] = useSelector((state) => [
    state.config,
    state.language,
  ]);
  const [checked, setChecked] = useState([
    { title: "necessary", checked: false },
    { title: "statistics", checked: false },
    { title: "marketing", checked: false },
  ]);
  const dispatch = useDispatch();
  const cookies = useMemo(() => new Cookies(), []);

  useEffect(() => {
    const consent = cookies.get("shop-consent");
    if (consent !== null) {
      setChecked([
        { title: "necessary", checked: true },
        {
          title: "statistics",
          checked: consent?.analytics_storage === "granted" ? true : false,
        },
        {
          title: "marketing",
          checked: consent?.ad_storage === "granted" ? true : false,
        },
      ]);
    } else {
      setChecked([
        { title: "necessary", checked: true },
        { title: "statistics", checked: true },
        { title: "marketing", checked: true },
      ]);
    }
  }, [opened, cookies]);

  const sendConsent = useCallback((consent) => {
    gtag("consent", "default", consent);
  }, []);

  const sendUpdateConsent = useCallback((consent) => {
    gtag("consent", "update", consent);
  }, []);

  const handleChecked = (title) => {
    const newChecked = checked.map((item) => {
      if (item.title === title) {
        return { ...item, checked: !item.checked };
      } else {
        return item;
      }
    });
    setChecked(newChecked);
  };

  const statistics = useMemo(() => {
    return checked.find((item) => item.title === "statistics");
  }, [checked]);

  const marketing = useMemo(() => {
    return checked.find((item) => item.title === "marketing");
  }, [checked]);

  const onlyNecessary = useMemo(() => {
    return !statistics.checked && !marketing.checked;
  }, [statistics, marketing]);

  const consent = useMemo(() => {
    return {
      analytics_storage: statistics.checked ? "granted" : "denied",
      ad_storage: marketing.checked ? "granted" : "denied",
      ad_user_data: marketing.checked ? "granted" : "denied",
      ad_personalization: marketing.checked ? "granted" : "denied",
    };
  }, [statistics, marketing]);

  const handleDecision = () => {
    if (onlyNecessary) {
      removeTrackingCookies(cookies, config?.baseUrl);
      dispatch(setConsent(false, cookieOptions));
    } else {
      dispatch(setConsent(consent, cookieOptions));
      if (cookies.get("shop-consent")) {
        sendUpdateConsent(cookies.get("shop-consent"));
      } else {
        sendConsent(consent);
      }
    }
    close();
    window.location.reload();
  };

  useEffect(() => {
    if (cookies.get("shop-consent")) {
      sendUpdateConsent(cookies.get("shop-consent"));
    } else {
      sendConsent(consent);
    }
  }, [consent]);

  useEffect(() => {
    if (!cookies.get("shop-consent")) {
      removeTrackingCookies(cookies, config?.baseUrl);
    }
  }, [cookies]);

  return (
    <Modal
      opened={opened}
      onClose={close}
      title={language.HandleCookies}
      centered
    >
      <p>
        {language.CookiesDescription}{" "}
        <Link to="/security">{language.CookiesReadMore}</Link>
      </p>
      <div className={classes.cookieWrapper}>
        <Accordion chevronPosition="left">
          <Accordion.Item value="item-1">
            <AccordionControl
              disabled={true}
              checked={checked[0].checked}
              onChange={() => handleChecked("necessary")}
            >
              {language.NecessaryCookies}
            </AccordionControl>
            <Accordion.Panel>
              <p>{language.NecessaryCookiesDescription}</p>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="item-2">
            <AccordionControl
              disabled={false}
              checked={checked[1].checked}
              onChange={() => handleChecked("statistics")}
            >
              {language.StatisticsCookies}
            </AccordionControl>
            <Accordion.Panel>
              <p>{language.StatisticsCookiesDescription}</p>
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item value="item-3">
            <AccordionControl
              disabled={false}
              checked={checked[2].checked}
              onChange={() => handleChecked("marketing")}
            >
              {language.MarketingCookies}
            </AccordionControl>
            <Accordion.Panel>
              <p>{language.MarketingCookiesDescription}</p>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
      <div className={classes.footer}>
        <Button variant="light" onClick={close}>
          {language.Close}
        </Button>
        <Button className="primaryButton" onClick={handleDecision}>
          {language.SaveSettings}
        </Button>
      </div>
    </Modal>
  );
};

export default ConsentContent;
