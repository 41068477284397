import React, { useEffect, useState } from "react";
import {
  addDays,
  endOfMonth,
  endOfWeek,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  parseISO,
  startOfMonth,
  startOfWeek,
  toDate,
} from "date-fns";
import "./styles.css";
import AlertIcon from "../Drawer/AlertIcon";
import { Button, UnstyledButton } from "@mantine/core";

const WeekDay = ({
  currentDate,
  setSelectedDate,
  allotment,
  allotmentData,
  allotmentSettings,
  loading,
  chosenDate,
  setChosenDate,
  setFilteredData,
  scrollIntoView,
  language,
  cultureLocale,
}) => {
  const [allotmentOccasion, setAllotmentOccasion] = useState([]);
  const [siteSettings, setSiteSettings] = useState([]);
  const monthStart = startOfMonth(currentDate, { locale: cultureLocale });
  const monthEnd = endOfMonth(monthStart);
  const startDate = startOfWeek(monthStart, { locale: cultureLocale });
  const endDate = endOfWeek(monthEnd);
  const dateFormat = "d";
  const rows = [];

  let days = [];
  let day = startDate;
  let formattedDate = "";

  useEffect(() => {
    if (allotmentData && allotment.length >= 1 && loading === false) {
      const filteredAllotment = allotmentData.allotments.filter((el) => {
        return allotment.some((FilteredData) => {
          return el.description === FilteredData.description;
        });
      });

      const filterAll = filteredAllotment.map((el) => el.occasions);
      const newAllotmentData = filterAll.flat().map((el) => [
        {
          time: format(parseISO(el.time), "yyyy-MM-dd"),
          remaining: el.remaining,
          articles: el.articles,
          price: el.articles.map((ele) => ele.priceInclVat).sort(),
          allotment: el.allotment,
          timeOccasions: el.time,
        },
      ]);

      // Set the calendar to show the earliest day
      let filtered = newAllotmentData.sort().flat();
      setFilteredData(filtered);

      // Get occasion time and data values
      let res = Object.values(
        newAllotmentData.flat().reduce((a, { time, ...props }) => {
          if (!a[time]) a[time] = Object.assign({}, { time, data: [props] });
          else a[time].data.push(props);
          return a;
        }, {})
      );
      let calendarSettings = [];
      calendarSettings.push(allotmentSettings[0]);
      setSiteSettings([
        {
          showAvailability: calendarSettings[0].showAvailability,
          showColors: calendarSettings[0].showColors,
          showPrice: calendarSettings[0].showPrice,
          lowAllotmentQty: calendarSettings[0].lowAllotmentQty,
          lowTicketQty: calendarSettings[0].lowTicketQty,
        },
      ]);
      setAllotmentOccasion(res);
    }
  }, [allotment]);

  const onDateClick = (day) => {
    setSelectedDate(day);
    setChosenDate(day);
    setTimeout(() => {
      scrollIntoView("TimeChooser");
    }, 100);
  };

  let formatDay = "yyyy-MM-dd";
  let today = new Date();
  let yesterday = today.setDate(today.getDate() - 1);

  const getPrice = (day) => {
    if (siteSettings[0] !== undefined && siteSettings[0].showPrice === true) {
      let filterByDay = allotmentOccasion.filter(
        (el) => format(parseISO(el.time), formatDay) === format(day, formatDay)
      );
      let getData = filterByDay.map((ele) => ele.data);
      let reducedOccasions = getData
        .flat()
        .map((el) => el.remaining)
        .reduce((sum, a) => sum + a, 0);

      const removeChar = (price) => {
        if (price) {
          let newPrice = price && price.replaceAll(/[^0-9\,.]/g, "");
          return newPrice.replace(",", "");
        }
      };

      let price2 = getData.flat()[0]?.price;
      const filterOutZeroCost = price2?.filter((el) => removeChar(el) > 0);
      const getPrices = filterOutZeroCost?.sort(
        (a, b) => parseFloat(removeChar(a)) - parseFloat(removeChar(b))
      )[0];

      if (
        filterByDay.length > 0 &&
        reducedOccasions === 0 &&
        !isBefore(day, yesterday)
      ) {
        return <span className="calendar-price">{language.FullAllotment}</span>;
      }
      if (getPrices !== undefined && !isBefore(day, yesterday)) {
        return (
          <span className="calendar-price">
            {language.CostFromAbbreviation} {getPrices}{" "}
          </span>
        );
      } else {
        return "";
      }
    } else {
      return "";
    }
  };
  const getAvailability = (day) => {
    let filterByDay = allotmentOccasion.filter(
      (el) => format(parseISO(el.time), formatDay) === format(day, formatDay)
    );
    let getData = filterByDay.map((ele) => ele.data);
    let reducedOccasions = getData
      .flat()
      .map((el) => el.remaining)
      .reduce((sum, a) => sum + a, 0);

    if (siteSettings.length > 0 && siteSettings[0].showColors === true) {
      if (reducedOccasions >= siteSettings[0].lowAllotmentQty) {
        return "calendar-available";
      }
      if (
        reducedOccasions < siteSettings[0].lowAllotmentQty &&
        reducedOccasions > 0 &&
        !isBefore(day, yesterday)
      ) {
        return "calendar-low";
      }
      if (filterByDay.length > 0 && reducedOccasions === 0) {
        return "calendar-full";
      }
    }

    if (siteSettings.length > 0 && siteSettings[0].showColors === false) {
      if (filterByDay.length > 0 && reducedOccasions > 0) {
        return "calendar-nocolorallotment";
      }
      if (filterByDay.length > 0 && reducedOccasions === 0) {
        return "disabled";
      }
    }
  };

  const labelAvailability = (day) => {
    if (getAvailability(day) === "calendar-low") {
      return `, ${language.FewLeft}`;
    } else {
      return "";
    }
  };

  while (day <= endDate) {
    for (let i = 0; i < 7; i++) {
      formattedDate = format(day, dateFormat);
      const cloneDay = day;

      days.push(
        <UnstyledButton
          variant="outline"
          aria-label={`${format(day, "EEEE do MMMM yyyy", {
            locale: cultureLocale,
          })} ${labelAvailability(day)} ${
            isSameDay(day, chosenDate) && language.Chosen
          }`}
          className={`calendar-column calendar-cell ${
            isSameDay(day, chosenDate) && "calendar-selected"
          } ${getAvailability(day)}
           ${!isSameMonth(day, monthStart) && "disabled calendar-hideDay"}

          ${isBefore(day, yesterday) && "disabled"}
          ${
            allotmentOccasion &&
            allotmentOccasion.find(
              (el) =>
                format(parseISO(el.time), formatDay) === format(day, formatDay)
            )
              ? ""
              : "disabled"
          }

          `}
          key={day}
          onClick={() => onDateClick(toDate(cloneDay))}
          disabled={
            getAvailability(day) === "calendar-full"
              ? true
              : isBefore(day, yesterday)
              ? true
              : false ||
                (allotmentOccasion &&
                  allotmentOccasion.find(
                    (el) =>
                      format(parseISO(el.time), formatDay) ===
                      format(day, formatDay)
                  ))
              ? ""
              : true
          }
        >
          {getAvailability(day) === "calendar-low" && (
            <div
              className="few-left-indicator-day"
              style={{
                position: "absolute",
                top: "0rem",
                left: "0.25rem",
                width: "1.15rem",
                height: "1.15rem",
                pointerEvents: "none",
              }}
            >
              <AlertIcon />
            </div>
          )}
          <div className="calendar-cellwrapper">
            <span
              className="calendar-number"
              style={{ fontSize: "1rem", margin: 0 }}
            >
              {formattedDate}
            </span>
            {getPrice(day)}
          </div>
        </UnstyledButton>
      );
      day = addDays(day, 1);
    }

    rows.push(
      <div className="calendar-row" key={day}>
        {days}
      </div>
    );
    days = [];
  }
  return <div className={`calendar-body`}>{rows}</div>;
};
export default WeekDay;
