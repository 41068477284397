import React from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import clsx from "clsx";
import { Avatar, Button } from "@mantine/core";
import { addModification } from "../../common/sale";
import { notification } from "../common/util";
import classes from "./activePerson.module.css";

const ActivePerson = ({ activePerson }) => {
  const rdxDispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const add = (model) => {
    rdxDispatch(
      addModification(model, (status, msg) => {
        if (status >= 400) {
          return notification("error", msg);
        } else {
          return notification("success", language.Article_Added);
        }
      })
    );
  };
  if (!activePerson) return null;

  return (
    <div className={classes.activePerson}>
      <div className={classes.person}>
        <Avatar
          className={classes.avatar}
          alt={activePerson.name}
          src={activePerson.photo}
        />
        <h1 className={classes.name}>{activePerson.name}</h1>
      </div>
      {activePerson.tickets && activePerson.tickets.length > 0 ? (
        <div className={classes.tickets}>
          {activePerson.tickets.map((t) => {
            console.log(t);
            const validFrom =
              t.validFrom && moment(t.validFrom).format("YYYY-MM-DD");
            const validUntil =
              t.validUntil && moment(t.validUntil).format("YYYY-MM-DD");
            return (
              <div
                key={t.id}
                className={clsx(classes.ticket, "ticket__container")}
              >
                <h6
                  className={clsx(classes.ticketName, "ticket__article__name")}
                >
                  {t.article.name}
                </h6>
                <p className={clsx(classes.barcode, "secondary-text")}>
                  {t.uniqueIdentifier}
                </p>
                <p className={clsx(classes.valid, "secondary-text")}>
                  {validFrom} - {validUntil}
                </p>
                {t.fundsId && (
                  <p className={clsx(classes.valid, "secondary-text")}>
                    {`Saldo: ${t.fundsAmount}:-`}
                  </p>
                )}
                {t.applicableModifications.map((a) => {
                  const model = {
                    ticketId: t.id,
                    articleId: t.article.id,
                    plu: a.plu,
                    fundsId: t.fundsId,
                    amount: a.price.amountInclVat,
                  };
                  return (
                    <div
                      key={a.id}
                      className={clsx(
                        classes.modificationItem,
                        "modification__article"
                      )}
                    >
                      <div
                        className={clsx(
                          classes.modArticleInfo,
                          "modification__article__info"
                        )}
                      >
                        <div
                          className={clsx(
                            classes.modArticleName,
                            "primary-text"
                          )}
                        >
                          {a.name}
                        </div>
                        <div
                          className={clsx(
                            classes.modArticlePrice,
                            "secondary-text"
                          )}
                        >
                          {a.priceInclVat}
                        </div>
                      </div>
                      <Button onClick={() => add(model)}>KÖP</Button>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      ) : (
        <h6 className={classes.noTickets}>Inga biljetter att förlänga</h6>
      )}
    </div>
  );
};

export default ActivePerson;
