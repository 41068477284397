import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import moment from "moment";

const CountdownContext = createContext();

export const CountdownProvider = ({ children }) => {
  const [timerExpiration, setTimerExpiration] = useState();
  const [timeState, setTimeState] = useState({
    minutes: 0,
    seconds: 0,
  });

  const updateCountdown = useCallback(() => {
    const currentTime = new Date().getTime();
    const timeUntilCountdownFinishes = timerExpiration - currentTime;

    const minutes = String(
      Math.floor((timeUntilCountdownFinishes % (1000 * 60 * 120)) / (1000 * 60))
    ).padStart(2, "0");

    const seconds = String(
      Math.floor((timeUntilCountdownFinishes % (1000 * 60)) / 1000)
    ).padStart(2, "0");

    setTimeState({ minutes, seconds });
  }, [timerExpiration]);

  useEffect(() => {
    if (timerExpiration && moment().isBefore(timerExpiration)) {
      const interval = setInterval(() => updateCountdown(), 1000);
      return () => {
        clearInterval(interval);
        setTimeState({
          minutes: 0,
          seconds: 0,
        });
      };
    }
  }, [timerExpiration, updateCountdown]);

  return (
    <CountdownContext.Provider
      value={{ timeState, timerExpiration, setTimerExpiration }}
    >
      {children}
    </CountdownContext.Provider>
  );
};

export const useCountdown = () => useContext(CountdownContext);
