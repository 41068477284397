import React from "react";
import { Button, Divider, Modal, SimpleGrid, Space, Text } from "@mantine/core";
import GroupArticle from "./GroupArticle";
import classes from "./MultiTicketModal.module.css";

const MultiTicketModal = ({
  opened,
  close,
  closeModal,
  language,
  data,
  groupTicketCart,
  groupTicketCartSet,
  disabledGroupAddToCart,
  returnFocus,
}) => {
  return (
    <Modal
      classNames={{
        title: classes.title,
        header: classes.header,
        body: classes.body,
      }}
      opened={groupTicketCart?.plu === data?.plu ? opened : false}
      size="md"
      onClose={() => closeModal()}
      title={language.GroupTicketTitle}
      centered
      closeButtonProps={{
        "aria-label": language.Close,
      }}
    >
      <Text component="p" style={{ marginBottom: "1.5rem" }}>
        {language.GroupTicketDescription}
      </Text>
      <SimpleGrid cols={1} style={{ gap: ".5rem" }}>
        {data.ticketHolders.map((el) => (
          <div key={el.categoryId}>
            <GroupArticle
              allTicketsData={data.ticketHolders}
              ticketData={el}
              groupTicketCart={groupTicketCart}
              groupTicketCartSet={groupTicketCartSet}
            />
            <Divider size="xs" />
          </div>
        ))}
      </SimpleGrid>
      <Space h="lg" />
      <div className={classes.actionWrapper}>
        <Button
          size="md"
          radius="sm"
          className="secondaryButton"
          color="dark"
          variant="subtle"
          onClick={() => closeModal()}
        >
          {language.Close}
        </Button>
        <Button
          size="md"
          radius="sm"
          className="regularButton"
          onClick={() => {
            close();
            returnFocus();
          }}
          disabled={disabledGroupAddToCart()}
        >
          {language.PrimaryBtn}
        </Button>
      </div>
    </Modal>
  );
};

export default MultiTicketModal;
