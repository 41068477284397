import gql from "graphql-tag";

export const GET_PUBLIC_BOOKING_PUBLIC_INFO = gql`
  query publicBookingQuery($activityId: String!) {
    publicBookingPublicInfo(activityId: $activityId) {
      formFields {
        id
        name
        description
        order
        fieldType
        fieldConstraints {
          isMandatory
          min
          max
          values
        }
      }
      publicBookingTypes {
        id
        name
        description
        minParticipants
        maxParticipants
        articlesToApply
        occasionLengthInMinutes
      }
    }
  }
`;

export const GET_PUBLIC_BOOKING_OCCASIONS = gql`
  query publicBookingOccasionsQuery(
    $activityId: String!
    $occasionsFrom: DateTime
    $occasionsTo: DateTime
  ) {
    publicBookingOccasions(
      activityId: $activityId
      occasionsFrom: $occasionsFrom
      occasionsTo: $occasionsTo
    )
  }
`;

export const CREATE_PUBLIC_BOOKING_REQUEST = gql`
  mutation PublicBookingRequest(
    $activityId: String!
    $responsibleId: Guid
    $publicBookingTypeId: Guid
    $occasion: DateTime!
    $numberOfParticipants: Int!
    $formFields: [String]
  ) {
    publicBookingRequest(
      activityId: $activityId
      responsibleId: $responsibleId
      publicBookingTypeId: $publicBookingTypeId
      occasion: $occasion
      numberOfParticipants: $numberOfParticipants
      formFields: $formFields
    ) {
      success
      errorMessage
    }
  }
`;
