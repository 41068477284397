import React from "react";
import {
  Divider,
  Image,
  Text,
  UnstyledButton,
  useMantineTheme,
} from "@mantine/core";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import slugify from "../common/slugify";
import { v4 as uuid } from "uuid";
import { useSessionStorage } from "@mantine/hooks";
import classes from "./ListV2.module.css";
import placeholder from "../../images/placeholder.jpg";

const ListV2 = ({ item, slug }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const [selected, setSelected] = useSessionStorage({
    key: "activeLink",
    defaultValue: "",
  });
  const navigate = useNavigate();
  const theme = useMantineTheme();

  const link = () => {
    setSelected(item?.text?.primary.toLowerCase());
    const filter = config.landingPage.links.filter(
      (el) => el.name === item.name
    )?.[0];
    if (slug) {
      return slug;
    }
    if (item?.eventCalendar) {
      return `/tickets/${slugify(item.name)}`;
    }
    if (item?.articleBooking) {
      return `/booking/${slugify(item.name)}`;
    }
    if (item?.externalLink) {
      return item?.externalLink;
    }
    if (item?.internalLink) {
      return item?.internalLink;
    }
    if (item?.publicBooking) {
      return `/publicbooking/${slugify(item.name)}`;
    } else {
      if (filter) {
        return `/${slugify(filter.name)}`;
      }
    }
  };

  return (
    <>
      <UnstyledButton
        className={`${classes.wrapper} landing-cardButton`}
        component={"a"}
        onClick={() => !item.externalLink && navigate(link())}
        onKeyDown={(e) =>
          e.key === "Enter" && !item.externalLink && navigate(link())
        }
        href={item?.externalLink && item?.externalLink}
        target={item?.externalLink && "_blank"}
        style={{ borderRadius: "8px" }}
        key={uuid()}
        aria-label={`${item?.text?.primary}, ${item?.text?.secondary}`}
        tabIndex={0}
      >
        <div className={classes.grid}>
          <div className={classes.imageWrapper}>
            <Image
              radius="md"
              src={item.image}
              fit={"cover"}
              h={"6rem"}
              w={"6rem"}
              fallbackSrc={placeholder}
              alt={`${language.PhotoOf} ${item?.text?.primary}`}
            />
          </div>
          <div className={classes.textWrapper}>
            <Text
              className={`${classes.title} landing-cardPrimaryText`}
              fw={500}
              component="p"
            >
              {item?.text?.primary}
            </Text>
            <Text
              className="landing-cardSecondaryText"
              size="sm"
              c="gray.7"
              lineClamp={4}
              component="p"
              mt={"0"}
              fw={"600"}
            >
              {item?.text?.secondary}
            </Text>
          </div>
        </div>
      </UnstyledButton>
      <Divider size="sm" className={classes.divider} />
    </>
  );
};

export default ListV2;
