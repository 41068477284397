import React, { useContext, useEffect, useState } from "react";
import { Drawer, Portal } from "@mantine/core";
import { CLOSE_CART, Context, OPEN_CART } from "../../context";
import { useSelector } from "react-redux";
import "./styles.css";
import DrawerList from "./DrawerList";
import DrawerAlert from "./DrawerAlert";
import DrawerFooter from "./DrawerFooter";
import CountdownContainer from "../Header/CountdownContainer";
import classes from "./Drawer.module.css";
import { useMediaQuery } from "@mantine/hooks";
import { useLocation } from "react-router";

const DrawerCart = ({ sale, cartOpen }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const { dispatch } = useContext(Context);
  const pathname = useLocation().pathname;
  const queryStr = useLocation().search;
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [firstLoad, setIsFirstLoad] = useState(true);

  useEffect(() => {
    if (
      sale?.itemQuantity !== 0 &&
      !cartOpen &&
      !pathname.includes("/options") 
    ) {

      if(queryStr.includes("hideautocart") && firstLoad)
      {
        //Prevent cart to automatically open when query string contains hideautocart
        setIsFirstLoad(false);
        return;
      }

      dispatch({ type: OPEN_CART });
    }

    setIsFirstLoad(false);
  }, [sale]);

  return (
    <Portal>
      <Drawer
        opened={cartOpen}
        onClose={() => dispatch({ type: CLOSE_CART })}
        padding="24px"
        size={isMobile ? "sm" : 500}
        position="right"
        className={`${classes.cart} drawer__cart`}
        aria-label={`${language.CartIsOpen}, ${language.Cart_Quantity} ${sale?.itemQuantity} ${language.Articles}`}
        closeButtonProps={{
          "aria-label": language.Close,
        }}
        aria-describedby="drawer-body"
        withinPortal={false}
        title={language.Cart}
      >
        <div className={classes.itemWrapper}>
          <div aria-live="polite">
            <DrawerAlert />
            <CountdownContainer parent={"Drawer"} />
            {sale?.items.length === 0 && <p>{language.EmptyCart}</p>}
            <DrawerList sale={sale} />
          </div>
          <DrawerFooter sale={sale} />
        </div>
      </Drawer>
    </Portal>
  );
};

export default DrawerCart;
