import React, { useEffect, useState } from "react";
import PageLayout from "../../components/Styles/PageLayout";
import PageContent from "../../components/Styles/PageContent";
import { Button, Container, Text, Title } from "@mantine/core";
import PublicBooking from "../../components/PublicBooking";
import { useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router";
import slugify from "../../components/common/slugify";
import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import classes from "./PublicBookingPage.module.css";

const PublicBookingPage = () => {
  const [activity, activitySet] = useState(null);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const navigate = useNavigate();
  const id = useParams().id;

  const bookingHttpLink = new HttpLink({
    uri: `${config.bookingApiUri}/graphql`,
  });

  const bookingApiClient = new ApolloClient({
    link: bookingHttpLink,
    cache: new InMemoryCache(),
  });

  useEffect(() => {
    const publicBookingId = config?.publicBookingConfigurator.filter(
      (el) => slugify(el.name) === slugify(id)
    )?.[0];
    if (publicBookingId) {
      activitySet(publicBookingId);
    }
  }, [window.location.pathname]);

  if (!config?.bookingApiUri || !config?.usePublicBooking || !activity) {
    return (
      <PageLayout>
        <PageContent>
          <Container
            style={{ textAlign: "center", margin: "3rem 0", maxWidth: "100%" }}
          >
            <Title mb="lg" order={1}>
              {language.CouldNotFindEventHeader}
            </Title>
            <Text component="p" mb="lg">
              {language.CouldNotFindEventText}
            </Text>
            <Button
              component="a"
              onClick={() => navigate("/")}
              className="mantine-Primary-btn backButton"
              variant="filled"
              size="md"
              radius="sm"
              color="indigo"
            >
              {language.BackToHomepage}
            </Button>
          </Container>
        </PageContent>
      </PageLayout>
    );
  }
  return (
    <PageLayout>
      <PageContent>
        <Container className={classes.buttonWrapper}>
          <Button
            className={`${classes.button} backButton`}
            variant="default"
            mb={2}
            onClick={() => navigate(-1)}
          >
            {language.Back}
          </Button>
        </Container>
        <PublicBooking
          activityId={activity.id}
          bookingApiClient={bookingApiClient}
          subLabel={activity.subLabel}
          tag={activity.tag}
        />
      </PageContent>
    </PageLayout>
  );
};

export default PublicBookingPage;
