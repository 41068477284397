import React, { useState } from "react";
import clsx from "clsx";
import { Button, Modal } from "@mantine/core";
import Cultures from "./cultures";
import Currencies from "./currencies";
import Version from "./version";
import Flag from "./flag";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import classes from "./Settings.module.css";

const Settings = (props) => {
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { config, language, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  return (
    <>
      <Button
        variant="subtle"
        aria-label="Byt språk"
        onClick={() => setSettingsOpen(!settingsOpen)}
        tabIndex={0}
        className={clsx(classes.settingsButton, "header__settings-button")}
        color="inherit"
      >
        <Flag culture={culture} />
      </Button>
      <Modal
        title={language.Settings}
        classNames={{
          title: classes.modalTitle,
          header: classes.modalHeader,
          body: classes.modalBody,
        }}
        centered
        withCloseButton={true}
        transitionProps={{ transition: "fade" }}
        size={"lg"}
        fullScreen={window.innerWidth < 577}
        opened={settingsOpen}
        onClose={() => setSettingsOpen(!settingsOpen)}
      >
        <div>
          <Cultures />
          {!config?.hideCurrencySelector && (
            <>
              <hr className={classes.divider} />
              <Currencies />
            </>
          )}
          <hr className={classes.divider} />
          <Version />
        </div>
        <div className={classes.dialogAction}>
          <Button
            variant="default"
            onClick={() => setSettingsOpen(!settingsOpen)}
          >
            {language?.Cancel || "Cancel"}
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Settings;
