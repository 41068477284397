export const getSaleData = (
  product,
  article,
  values,
  photo,
  quantity,
  overrideStartDate,
  extraInformation
) => {
  const saleArticle = product.articles
    ? product.articles.find((a) => a.id === article)
    : product;
  if (!saleArticle) throw new Error("No article");
  if (!values) {
    return {
      plu: saleArticle.plu,
      quantity: quantity || 1,
      overrideStartDate,
      extraInformation,
    };
  }
  const { plu } = saleArticle;
  const ticketHolderCategoryId = saleArticle.ticketHolders[0].categoryId;
  const ticketOwners = [
    {
      ticketHolderCategoryId,
      customerData: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        civicRegistrationNumber: values.civicRegistrationNumber,
        phoneNumbers: [
          {
            type: "Cell",
            number: values.cellPhone,
          },
        ],
        addresses: [
          {
            type: "Generic",
            street: values.street,
            streetNumber: values.streetNumber,
            city: values.city,
            zipCode: values.zipCode,
            countryCode: values.countryCode,
          },
        ],
        photoBase64: photo && photo,
        photoExtension: photo && "jpg",
      },
    },
  ];
  return { plu, quantity: 1, ticketOwners };
};
