import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import classes from "./ChosenButton.module.css";

const ChosenButton = (props) => {
  const [activeBtn, setActiveBtn] = useState(false);
  const location = useLocation();
  const click = (data) => {
    setActiveBtn(data);
    props.setIsVisible(false);

    setTimeout(() => {
      props.setSection(data);
    }, 50);
  };

  useEffect(() => {
    setActiveBtn(props.data);
    sessionStorage.setItem("articleBookingBtn", props.data);
    props.setSection(props.data);
    props.setIsVisible(true);
  }, [props.data]);

  // useEffect(() => {
  //   if (props.section.length === 0) {
  //     setActiveBtn(false);
  //   }
  //   if (props.text === props.section.name) {
  //     setActiveBtn(true);
  //   } else {
  //     setActiveBtn(false);
  //   }

  // }, [props.section]);

  // useEffect(() => {
  //   props.setIsVisible(true);

  //   setTimeout(() => {
  //   props.scrollIntoView("IntroductionSection")

  //   }, 70);
  // }, [props.section]);

  // useEffect(() => {
  //   setActiveBtn("")
  // },[location.pathname])

  return (
    <div>
      <button
        className={`${classes.occasionBtn}  ${
          activeBtn ? "activeArticleButton" : "articleBookingButton"
        }`}
        onClick={() => click(props.data)}
        // disabled={props.disabled}
        style={{ pointerEvents: activeBtn ? "none" : "" }}
        aria-label={props.text}
      >
        {props.text}
      </button>
    </div>
  );
};

export default ChosenButton;
