import React from "react";
import { useSelector } from "react-redux";
import classes from "./Version.module.css";

const Version = () => {
  const {
    config: { version },
  } = useSelector((state) => ({
    config: state.config,
  }));
  return (
    <div>
      <h6 style={{ fontSize: "1rem" }}>Version</h6>
      <p className={classes.versionText}>Vitec Entry Event AB - {version}</p>
    </div>
  );
};

export default Version;
