import React, { useEffect, useState } from "react";
import ProductGroup from "./productGroup";
import AllProducts from "./allProducts";
import ArticleFilter from "../common/ArticleFilter";

const Page = ({ originalPage, page, setPosition }) => {
  const [filteredProductList, setFilteredProductList] = useState({ ...page });

  useEffect(() => {
    setFilteredProductList({ ...page });
  }, [page]);
  if (!page) return null;
  return (
    <div>
      {page.showProductGroupFilter && (
        <>
          <h2
            style={{ margin: "1rem 1rem 1.5rem" }}
            className={`product-filter-header`}
          >
            Produktfilter
          </h2>
          <ArticleFilter
            page={page}
            onChange={(filterResult) => setFilteredProductList(filterResult)}
          />
        </>
      )}

      {page.listByGroup ? (
        filteredProductList.productGroups.map((group, i) => (
          <ProductGroup
            key={i}
            group={group}
            page={filteredProductList}
            setPosition={setPosition}
          />
        ))
      ) : (
        <AllProducts
          originalPage={originalPage}
          page={filteredProductList}
          setPosition={setPosition}
        />
      )}
    </div>
  );
};

export default Page;
