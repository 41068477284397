import React, { useContext, useEffect, useRef, useState } from "react";
import "./styles.css";
import { MdAddCircle, MdRemoveCircle } from "react-icons/md";
import { DataContext } from "../common/dataProvider";
import { useSelector } from "react-redux";
import format from "date-fns/format";
import AddToCart from "./AddToCart";
import { motion } from "framer-motion";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { useDisclosure } from "@mantine/hooks";
import MultiTicketModal from "../Event/EventV2/MultiTicketModal";
import { isSameDay } from "date-fns";
import { Button } from "@mantine/core";
import ClockIcon from "./icons/clockIcon";
import AllotmentOption from "./AllotmentOption";
import ArticleOption from "./ArticleOption";
import OptionsModal from "../Options/OptionsModal";

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};

const ArticleChooser = ({
  chosenDate,
  scrollIntoView,
  allotmentData,
  allotmentSettings,
  selected,
  setSelected,
  setChooseTime,
  setActiveOptionScreen,
  activeOptionScreen,
  cultureLocale,
  singleOccasion,
  activeTicketConfigurator,
  setChosenDate,
}) => {
  const [allotments, setAllotments] = useState();
  const [allotmentArticles, setAllotmentArticles] = useState();
  const [optionAllotments, setOptionAllotments] = useState([]);
  const [optionArticles, setOptionArticles] = useState([]);
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [optionAllotmentsRemaining, setOptionAllotmentsRemaining] = useState();
  const [options, setOptions] = useState();
  const [disabledBtn, setDisabledBtn] = useState(false);
  const [timeSelected, setTimeSelected] = useState();
  const [addedAllotment, setAddedAllotment] = useState(0);
  const [fullAllotment, setFullAllotment] = useState(false);
  const [allotmentItemOutOfStock, setAllotmentItemOutOfStock] = useState([]);
  const [clicked, setClicked] = useState(false);
  const [groupTicketCart, groupTicketCartSet] = useState(null);
  const [extraInformation, setExtraInformation] = useState(null);
  const [optionsId, setOptionsId] = useState(null);
  const [opened, { open, close }] = useDisclosure(false);
  const [
    openedExtraInformation,
    { open: openExtraInformation, close: closeExtraInformation },
  ] = useDisclosure(false);
  const { language, culture, currency } = useSelector((state) => ({
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));

  const { data } = useContext(DataContext);
  const changeTimeBtnRef = useRef(null);
  const formatDay = "EEEE d MMMM HH:mm";

  const resetDate = () => {
    if (
      singleOccasion &&
      activeTicketConfigurator?.hideTimeChooserIfSingleOccasion
    ) {
      scrollIntoView("NewCalendar");
      setChosenDate(undefined);
    } else {
      scrollIntoView("TimeChooser");
    }
    setActiveOptionScreen(false);
    setChooseTime("");
    setSelected(false);
    setOptionAllotments();
    setTimeSelected(selected?.timeOccasions);
    setFullAllotment(false);
    setCart([]);
    setAddedAllotment(0);
    setAllotmentItemOutOfStock([]);
  };
  useEffect(() => {
    const optionAllotmentFilter =
      allotmentSettings[0] &&
      data.allotments.filter((el) =>
        allotmentSettings[0].optionAllotmentId.includes(el.id)
      );

    const sortOptionArticlesByPrice = optionAllotmentFilter?.map((el) => ({
      ...el,
      articles: [...el.articles].sort(
        (a, b) => a.price.amountInclVat - b.price.amountInclVat
      ),
    }));

    const remainingFilter =
      allotmentData &&
      sortOptionArticlesByPrice &&
      allotmentData.allotments.filter((el) => {
        return sortOptionArticlesByPrice.some((ele) => {
          return el.description === ele.description;
        });
      });

    const optionArticleFilter =
      allotmentSettings[0] &&
      data.articles.filter((el) =>
        allotmentSettings[0].optionArticleId.includes(el.id)
      );

    const sortByPrice = optionArticleFilter.sort(
      (a, b) => a.price.amountInclVat - b.price.amountInclVat
    );
    setOptionAllotments(sortOptionArticlesByPrice);
    setOptionAllotmentsRemaining(remainingFilter);
    setOptionArticles(sortByPrice);
  }, [data, selected, allotmentSettings]);

  useEffect(() => {
    const articles = selected?.articles && [...selected.articles];
    const sortSelected =
      selected && articles && articles.sort((a, b) => a.plu - b.plu);

    setAllotments(selected);
    setAllotmentArticles(sortSelected);
    setTimeSelected(selected?.timeOccasions);
    setFullAllotment(false);
    setCart([]);
    setAddedAllotment(0);
    setAllotmentItemOutOfStock([]);
  }, [selected, chosenDate]);

  useEffect(() => {
    if (chosenDate !== selected) {
      setActiveOptionScreen(false);
    }
  }, [chosenDate]);

  useEffect(() => {
    if (
      addedAllotment === allotments?.allotment?.maxPerSale ||
      (groupTicketCart && groupTicketCart.plu)
    ) {
      setDisabledBtn(true);
    }
    if (
      addedAllotment < allotments?.allotment?.maxPerSale &&
      !groupTicketCart
    ) {
      setDisabledBtn(false);
    }
  }, [addedAllotment, groupTicketCart]);

  const addAllotment = (product, timeSelected) => {
    if (product?.isMultiTicket) {
      setCart([]);
      open();
      groupTicketCartSet({
        plu: product.plu,
        quantity: 1,
        allotmentId: selected.allotment?.id,
        allotmentOccasion: timeSelected,
        ticketOwners: [],
      });
    } else {
      setOptionsId(null);
      groupTicketCartSet(null);
      const newProduct = { ...product };
      newProduct.allotmentId = selected.allotment?.id;
      newProduct.timeOccasions = timeSelected;
      newProduct.extraInformation = extraInformation;
      if (
        addedAllotment < selected.remaining &&
        addedAllotment < allotments.allotment.maxPerSale
      ) {
        setAddedAllotment((prev) => prev + 1);
        setCart([...cart, newProduct]);
      }
      if (addedAllotment === selected.remaining - 1) {
        setFullAllotment(true);
      }
    }
  };

  const removeAllotment = (el) => {
    if (el?.isMultiTicket) {
      groupTicketCartSet(null);
    } else {
      setAddedAllotment((prev) => prev - 1);
      let newCart = [...cart];
      let res = cart.findLastIndex((i) => i.id === el.id);
      newCart.splice(res, 1);
      setCart(newCart);
      if (addedAllotment <= selected.remaining) {
        setFullAllotment(false);
      }
    }
  };

  const checkAllotmentItemRemaining = (el, timeSelected) => {
    if (timeSelected !== undefined) {
      const filterAllotment = optionAllotmentsRemaining.filter(
        (item) => item.description === el.description
      );
      const filterTime = filterAllotment[0]?.occasions.filter(
        (item) => item.time.split("T")[0] === timeSelected.split("T")[0]
      );
      if (filterTime && filterTime[0]?.remaining === 0) {
        return "full";
      }
    }
  };

  const removeItem = (el) => {
    const newCart = [...cart];
    const res = cart.findLastIndex((i) => i.id === el.id);
    newCart.splice(res, 1);
    setCart(newCart);
  };

  const calculateValue = () => {
    const removeChar = (price) => {
      if (price) {
        let newPrice = price && price.replaceAll(/[^0-9,.]/g, "");
        const commaIndex = newPrice.lastIndexOf(",");
        if (commaIndex !== -1) {
          if (commaIndex === 1) {
            newPrice =
              newPrice.substring(0, commaIndex) +
              newPrice.substring(commaIndex + 1);
          } else {
            newPrice =
              newPrice.substring(0, commaIndex) +
              "." +
              newPrice.substring(commaIndex + 1);
          }
        }
        return parseFloat(newPrice);
      }
    };
    let total = 0;
    for (let i = 0; i < cart.length; i++) {
      if (cart[i]?.price?.amountInclVat !== 0) {
        total += parseFloat(
          removeChar(cart[i].priceInclVat)
            ? removeChar(cart[i].priceInclVat)
            : removeChar(cart[i].displayPrice)
            ? removeChar(cart[i].displayPrice)
            : ""
        );
      }
    }
    const groupTicket = allotments?.articles?.filter(
      (groupTicket) => groupTicket?.plu === groupTicketCart?.plu
    );
    if (groupTicket && groupTicket.length > 0) {
      total = groupTicket?.[0]?.price?.amountInclVat;
    }
    setCartTotal(total.toFixed(2));
  };

  const calculateQuantity = (el) => {
    if (groupTicketCart && groupTicketCart.plu === el.plu) {
      return 1;
    }
    const findItems = cart.filter((item) => item.id === el.id);
    return findItems.length;
  };

  const disabledGroupAddToCart = () => {
    const getQuantity = groupTicketCart?.ticketOwners?.filter(
      (el) => el.quantity > 0
    );
    if (getQuantity && getQuantity.length > 0) return false;
    return true;
  };

  const fullOptionAllotment = (el) => {
    const getOptionAllotments = optionAllotments?.filter((item) =>
      item?.articles?.some((article) => article.id === el.id)
    )?.[0];

    if (getOptionAllotments) {
      const getSelectedOccasion = getOptionAllotments.occasions.filter((item) =>
        isSameDay(new Date(item.time), new Date(timeSelected))
      )?.[0];

      const getOptionInCart = cart.filter(
        (item) => item.allotmentId === getOptionAllotments.id
      );

      if (
        getOptionInCart &&
        getSelectedOccasion &&
        getOptionInCart.length >= getSelectedOccasion?.remaining
      ) {
        return true;
      }
      return false;
    }
  };

  const closeModal = () => {
    groupTicketCartSet(null);
    close();
  };

  const returnFocus = () => {
    const button = document.getElementById("multicalendar-add-to-cart");
    button.focus();
  };

  const translation = (item) => {
    if (item?.translation) {
      const getLang = item.translation.filter((el) => el.culture === culture);
      return getLang[0].name;
    } else {
      return item.name;
    }
  };

  useEffect(() => {
    calculateValue();
  }, [cart, groupTicketCart]);

  useEffect(() => {
    if (optionAllotments !== undefined && timeSelected !== undefined) {
      let results = optionAllotments.filter((x) => {
        let data = x.occasions.some(
          (y) => y.time.split("T")[0] === timeSelected.split("T")[0]
        );
        return data;
      });
      setOptions(results);
    }
  }, [optionAllotments, timeSelected]);

  useEffect(() => {
    const calendarTimePicker = document.getElementById(
      "calendar-article-picker"
    );
    if (calendarTimePicker && selected?.timeOccasions) {
      setTimeout(() => {
        calendarTimePicker.focus();
      }, 1000);
    }
  }, [selected]);

  return (
    <div
      id="calendar-article-picker"
      data-isactive={activeOptionScreen === false ? "false" : "true"}
      className={`calendar-timeChooserDate calendar-articleChooserWrapper ${
        activeOptionScreen === false ? "hideScreen" : ""
      }`}
      tabIndex={0}
    >
      <h2 className="calendar-header">{language?.ChooseAllotmentHeader}</h2>

      <div
        className={`${
          activeOptionScreen === false ? "calendar-hideItems" : ""
        }  `}
      >
        <div className={`${clicked === true ? "calendar-hideScreen" : ""}`}>
          {!singleOccasion ? (
            <h3 className="calendar-subHeader calendar-articleSubHeader">
              {selected?.timeOccasions !== undefined &&
                format(new Date(selected?.timeOccasions), formatDay, {
                  locale: cultureLocale,
                })}
            </h3>
          ) : (
            <h3 className="calendar-subHeader calendar-articleSubHeader">
              {selected?.timeOccasions !== undefined &&
                format(new Date(selected?.timeOccasions), "EEEE dd MMMM", {
                  locale: cultureLocale,
                })}
            </h3>
          )}

          <Button
            onClick={resetDate}
            className="calendar-changeTimeBtn primaryButtonOutline"
            variant="outline"
            aria-label={
              singleOccasion &&
              activeTicketConfigurator?.hideTimeChooserIfSingleOccasion
                ? language?.ChangeDateBtn
                : language?.ChangeTimeBtn
            }
            ref={changeTimeBtnRef}
            size="lg"
            leftSection={<ClockIcon />}
          >
            {singleOccasion &&
            activeTicketConfigurator?.hideTimeChooserIfSingleOccasion
              ? language?.ChangeDateBtn
              : language?.ChangeTimeBtn}
          </Button>
          <div className="calendar-allotmentArticleWrapper">
            {allotments &&
              allotmentArticles.map((el, i) =>
                allotmentArticles.length > 0 ? (
                  <div className="calendar-articleWrapper" key={el.name + i}>
                    {el?.isMultiTicket && (
                      <MultiTicketModal
                        opened={opened}
                        close={close}
                        closeModal={closeModal}
                        language={language}
                        data={el}
                        groupTicketCart={groupTicketCart}
                        groupTicketCartSet={groupTicketCartSet}
                        disabledGroupAddToCart={disabledGroupAddToCart}
                        returnFocus={returnFocus}
                      />
                    )}
                    {el?.additionalFields?.length > 0 && (
                      <OptionsModal
                        title={translation(el)}
                        opened={openedExtraInformation && optionsId === el.id}
                        close={closeExtraInformation}
                        additionalFields={el.additionalFields}
                        handleAdd={() => addAllotment(el, timeSelected)}
                        extraInformation={extraInformation}
                        setExtraInformation={setExtraInformation}
                      />
                    )}
                    <div className="calendar-articleLeftColumn">
                      <span className="calendar-articleTitle">
                        {translation(el)}
                      </span>
                      <span className="calendar-articlePrice">
                        {el.priceInclVat}
                      </span>
                    </div>
                    <div className="calendar-articleRightColumnToggle">
                      <motion.div
                        className="calendar-switch"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        transition={spring}
                        layout
                      />
                      <motion.div
                        className={`calendar-handle ${
                          fullAllotment === true ? "calendar-hideScreen" : ""
                        }`}
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        whileTap={{ scale: 1.2 }}
                        tabIndex="-1"
                        layout
                        transition={spring}
                        style={{
                          pointerEvents: disabledBtn ? "none" : "",
                        }}
                      >
                        <button
                          className={`calendar-articleButton`}
                          onClick={() => {
                            if (el?.additionalFields?.length > 0) {
                              setOptionsId(el.id);
                              openExtraInformation();
                            } else {
                              addAllotment(el, timeSelected);
                            }
                          }}
                          style={{
                            opacity: disabledBtn ? "0.2" : "1",
                          }}
                          aria-label={`${language?.AddQuantity} ${el.name}`}
                          disabled={disabledBtn}
                        >
                          <MdAddCircle className="buttonIcons" />
                        </button>
                      </motion.div>
                      <motion.div
                        className="calendar-count calendar-articleQty"
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        layout
                        transition={spring}
                        role="region"
                        aria-live="assertive"
                      >
                        {calculateQuantity(el)}
                      </motion.div>
                      <motion.div
                        data-ison={calculateQuantity(el) > 0 ? true : ""}
                        className="calendar-handle2"
                        tabIndex="-1"
                        whileTap={{ scale: 1.2 }}
                        layout
                        transition={spring}
                      >
                        <button
                          className={`calendar-articleButtonRemove ${
                            calculateQuantity(el) === 0
                              ? "calendar-hideScreen"
                              : ""
                          }`}
                          onClick={() => removeAllotment(el)}
                          aria-label={`${
                            language?.DecreaseQuantity
                          } ${translation(el)}`}
                          disabled={calculateQuantity(el) === 0 ? true : false}
                        >
                          <MdRemoveCircle className="buttonIcons" />
                        </button>
                      </motion.div>
                    </div>
                  </div>
                ) : (
                  ""
                )
              )}
          </div>
          <div className="calendar-allotmentArticleWrapper">
            {(options && optionArticles && options.length > 0) ||
            optionArticles.length > 0 ? (
              <h3 className="calendar-articleHeader">{language?.Options}</h3>
            ) : (
              ""
            )}
            {options &&
              options.map((el) => (
                <div className="calendar-allotmentOptionsWrapper" key={el.id}>
                  {el.articles.map((ele, i) =>
                    checkAllotmentItemRemaining(el, timeSelected) === "full" ? (
                      <div
                        className="calendar-articleWrapper calendar-hideScreen"
                        key={ele.name + i}
                      >
                        <div className="calendar-articleLeftColumn">
                          <span className="calendar-articleTitle">
                            {translation(el)}
                          </span>
                          <span className="calendar-articlePrice">
                            {language?.SoldOut}
                          </span>
                        </div>
                      </div>
                    ) : (
                      <AllotmentOption
                        allotment={el}
                        key={ele.id}
                        item={ele}
                        timeSelected={timeSelected}
                        cart={cart}
                        setCart={setCart}
                        allotmentItemOutOfStock={allotmentItemOutOfStock}
                        setAllotmentItemOutOfStock={setAllotmentItemOutOfStock}
                        optionAllotments={optionAllotments}
                        optionAllotmentsRemaining={optionAllotmentsRemaining}
                        calculateQuantity={calculateQuantity}
                        fullOptionAllotment={fullOptionAllotment}
                      />
                    )
                  )}
                </div>
              ))}
            {optionArticles &&
              optionArticles.map((item, i) => (
                <ArticleOption
                  key={item.id}
                  item={item}
                  cart={cart}
                  setCart={setCart}
                  translation={translation}
                  calculateQuantity={calculateQuantity}
                  removeItem={removeItem}
                />
              ))}
            {cartTotal > 0 ? (
              <p className="calendar-articleTotalCost">
                {language?.ToPay} {cartTotal} {currency}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="calendar-articleBuyWrapper">
          <AddToCart
            cart={cart}
            setCart={setCart}
            allotmentId={selected}
            setClicked={setClicked}
            language={language}
            groupTicketCart={groupTicketCart}
            groupTicketCartSet={groupTicketCartSet}
            allotments={allotments}
          />
        </div>
      </div>
    </div>
  );
};
export default ArticleChooser;
