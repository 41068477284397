import * as React from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { STATE_CULTURE } from "../../common/state";
import ProductList from "../ProductList";
import Description from "../common/Description";
import slugify from "../common/slugify";
import { DataContext } from "../common/dataProvider";

let source;

const ProductGroup = ({ group, page, setPosition }) => {
  const { data } = React.useContext(DataContext);
  const [text, setText] = React.useState("");
  const { config, culture, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
  }));
  const listType =
    window.innerWidth < 667 ? page.listType?.sm : page.listType?.lg;

  React.useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    if (group.description && group.description.length > 0) {
      axios
        .get(
          `${config.baseUrl}/${config.descriptionFolderUrl}/${group.description}.${culture}.md`,
          {
            cancelToken: source.token,
          }
        )
        .then((res) => setText(res.data));
    } else {
      setText("");
    }
    return () => source.cancel("Component Is Unmounting");
  }, [culture, config.descriptionFolderUrl, group.description]);

  const dataTags = data.tags.map((t) => t.name);
  if (!dataTags.includes(group.name)) return null;
  if (group.products.length < 1) return null;

  return (
    <div style={{ marginBottom: "24px" }}>
      <h6 className="product-group__heading" id={slugify(group.name)}>
        {(language && language.Categories && language.Categories[group.name]) ||
          group.name}
      </h6>
      <Description text={text} />
      <ProductList
        products={group.products}
        listType={listType}
        setPosition={setPosition}
      />
    </div>
  );
};

export default ProductGroup;
