import { ActionIcon, Divider, Grid, Image, Loader } from "@mantine/core";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeArticle, removeFromCartTracking } from "../../common/sale";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { DataContext } from "../common/dataProvider";
import QuantityInput from "./QuantityInput";
import TrashIcon from "./TrashIcon";
import { format } from "date-fns";
import classes from "./DrawerItem.module.css";
import placeholder from "../../images/placeholder.jpg";

const DrawerItem = ({ item, sale, isLastItem }) => {
  const [loading, setLoading] = useState(null);
  const { config, culture, language, currency } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
    language: state.language,
    currency: state.cookies[STATE_CURRENCY],
  }));
  const { data } = useContext(DataContext);
  const rdxDispatch = useDispatch();
  const uri = config?.apiUriOverride?.startsWith("http")
    ? config.apiUriOverride
    : config.apiUri;

  const image = `${uri}/articleimage/${item?.article?.imageFilename}`;

  const remove = () => {
    setLoading(true);
    rdxDispatch(
      removeArticle(item.key, (cb, msg) => {
        if (cb.status === 200) {
          const trackItem = [
            {
              id: item.article.plu,
              quantity: item.quantity,
              price: item.pricePerPiece.amountInclVat,
              name: item.article.name,
            },
          ];
          removeFromCartTracking(
            currency,
            trackItem,
            item.totalPrice.amountInclVat
          );
        }
      })
    );
  };

  const getName = () => {
    const getTranslation = item?.article?.translation.filter(
      (el) => el.culture === culture
    );
    return getTranslation?.[0]?.name;
  };

  const getStock =
    item.article.plu &&
    data &&
    data.articles.filter((el) => el.plu === item.article.plu)?.[0]?.inStock;

  const allotment =
    item.allotmentId &&
    data &&
    data.allotments.reduce((acc, curr) => {
      if (curr.id === item.allotmentId) {
        let occasion = curr.occasions.filter((el) => el.time === item.occasion);
        acc = occasion;
      }
      return acc;
    }, {});

  const allotmentRemaining = useMemo(() => {
    if (allotment && allotment.length > 0) {
      return allotment[0].remaining;
    } else return null;
  }, [allotment]);

  useEffect(() => {
    setLoading(false);
    return () => setLoading(null);
  }, [sale.items]);

  return (
    <div className={loading ? classes.loadingWrapper : undefined}>
      <div className={classes.wrapper}>
        {loading && (
          <Loader
            variant="dots"
            className={classes.loader}
            color="var(--theme-primary-color)"
          />
        )}
        <div className={classes.itemWrapper}>
          <Image
            fallbackSrc={placeholder}
            className={classes.image}
            radius="md"
            src={image}
            fit="cover"
            w={"5.7rem"}
            h={"5.7rem"}
            alt={getName()}
          />
          <Grid className={classes.rowWrapper}>
            <Grid.Col span={12} style={{ paddingBottom: 0 }}>
              <div className={classes.leftWrapper}>
                <div className={classes.textWrapper}>
                  <p className={classes.text}>{getName()}</p>
                  {item?.ticketOwners?.[0]?.name && (
                    <p className={classes.textSmall}>
                      {item?.ticketOwners?.[0]?.name}
                    </p>
                  )}
                  <div className={classes.occasionWrapper}>
                    {item?.occasion && (
                      <p className={classes.textSmall}>
                        {format(new Date(item?.occasion), "yyyy-MM-dd HH:mm")}
                      </p>
                    )}
                    {item?.extraInformation?.length > 0 &&
                      item?.extraInformation?.map((item) => (
                        <p className={classes.textSmall} key={item}>
                          {item?.value}
                        </p>
                      ))}
                    <div className={classes.multiTicketWrapper}>
                      {item?.isMultiTicket &&
                        item?.multiTickets.map((ticket) => (
                          <p
                            className={classes.textSmall}
                            key={ticket.categoryName}
                          >
                            {ticket.categoryName} x {ticket.quantity}
                          </p>
                        ))}
                    </div>
                  </div>
                </div>
                {loading && (
                  <div className={classes.hideAriaLive} aria-live="polite">
                    {language.Removed}
                  </div>
                )}
                <ActionIcon
                  variant="transparent"
                  className={`${classes.trashButton} trashButton`}
                  aria-label={`${language.RemoveProduct} ${getName()}`}
                  onClick={() => remove()}
                >
                  <TrashIcon />
                </ActionIcon>
              </div>
            </Grid.Col>
            <Grid.Col span={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
              <div className={classes.rightWrapper}>
                <QuantityInput
                  className={classes.quantityInput}
                  style={{ width: "100%" }}
                  name={getName()}
                  inStock={
                    getStock
                      ? getStock
                      : allotmentRemaining
                      ? allotmentRemaining
                      : ""
                  }
                  item={item}
                  id={item.key}
                  quantity={item.quantity}
                />
                <p component="p" className={classes.price}>
                  {item.totalPriceInclVat}
                </p>
              </div>
            </Grid.Col>
          </Grid>
        </div>
      </div>
      {isLastItem ? (
        <div style={{ marginBottom: "20px" }} />
      ) : (
        <Divider my="lg" size="sm" className={classes.divider} />
      )}
    </div>
  );
};

export default DrawerItem;
