import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { STATE_CULTURE, STATE_CURRENCY } from "../../common/state";
import { useSelector } from "react-redux";
import Header from "./Header";
import Content from "./Content";
import "./styles.css";
import { useLocation } from "react-router";
import Modal from "react-modal";
import { motion } from "framer-motion";
import { addYears, startOfMonth } from "date-fns";
import useResizeObserver from "./hooks/useResizeObserver";

const query = gql`
  query detailQuery(
    $currency: String
    $culture: String
    $posId: Int
    $from: DateTime
    $until: DateTime
  ) {
    allotments(
      currency: $currency
      culture: $culture
      posId: $posId
      from: $from
      until: $until
    ) {
      id
      description
      occasions {
        time
        remaining
        allotment {
          id
          maxPerSale
        }
        articles {
          id
          name
          description
          plu
          priceInclVat
          isPersonalTicket
          price {
            amountInclVat
          }
        }
      }
    }
  }
`;

const MoveOccasions = () => {
  const { config, language, currency, culture } = useSelector((state) => ({
    config: state.config,
    language: state.language,
    culture: state.cookies[STATE_CULTURE],
    currency: state.cookies[STATE_CURRENCY],
  }));
  const [step, setStep] = useState(0);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [progress, setProgress] = useState([1, 1, 1, 1, 1]);
  const [ticketData, setTicketData] = useState();
  const [from, setFrom] = useState();
  const [until, setUntil] = useState();
  const [filteredTicketData, setFilteredTicketData] = useState([]);
  const [chosenOldOccasion, setChosenOldOccasion] = useState();
  const [occasion, setOccasion] = useState();
  const [errorMsg, setErrorMsg] = useState("");
  const [introductionText, setIntroductionText] = useState("");
  const [initialHeight, setInitialHeight] = useState();
  const [newHeight, setNewHeight] = useState();

  const { posId } = config;
  const location = useLocation();

  const token = location.pathname.split("/").slice(-1);

  useEffect(() => {
    setFrom(startOfMonth(new Date()));
    setUntil(addYears(currentDate, 1));
  }, [currentDate]);

  const result = useQuery(query, {
    fetchPolicy: "cache-and-network",
    variables: { posId, currency, culture, from, until },
  });

  useEffect(() => {
    const uri = config?.apiUriOverride?.startsWith("http")
      ? config.apiUriOverride
      : config.apiUri;

    axios
      .get(`${uri}/api/managesale/${token}/allotments`)
      .then((res) => {
        const result = res.data;
        setTicketData(result);
      })
      .catch((error) => {
        if (error.response.data === 100) {
          setErrorMsg(language.ErrorMsgMissingToken);
        }
        if (error.response.data === 101) {
          setErrorMsg(language.ErrorMsgBadToken);
        }
      });
  }, []);

  useEffect(() => {
    axios
      .get(
        `${config.baseUrl}/ee-static/shop/${config?.shopName}/Texts//moveoccasions.${culture}.md`
      )
      .then((data) => setIntroductionText(data.data))
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const filter = result.data?.allotments.filter((el) => {
      return (
        ticketData &&
        ticketData.some((ele) => {
          return el.id === ele.allotmentId;
        })
      );
    });
    setFilteredTicketData(filter);
  }, [result, ticketData]);

  useEffect(() => {
    if (chosenOldOccasion !== undefined && filteredTicketData !== undefined) {
      const filter =
        chosenOldOccasion &&
        filteredTicketData &&
        filteredTicketData.filter(
          (el) => el.id === chosenOldOccasion.allotmentId
        );
      setOccasion(filter);
    }
  }, [chosenOldOccasion, filteredTicketData]);

  let isOpen = true;

  const headerRef = useRef(null);
  const [ref, entry] = useResizeObserver();

  useEffect(() => {
    setNewHeight(entry?.contentRect?.height);
    window.addEventListener("resize", setNewHeight(entry?.contentRect?.height));
    return window.removeEventListener(
      "resize",
      setNewHeight(entry?.contentRect?.height)
    );
  }, [entry.contentRect]);

  useEffect(() => {
    if (headerRef.current) {
      headerRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [headerRef, step]);

  const variants = {
    initial: {
      height: newHeight,
      transition: {
        duration: 0,
      },
    },
    updated: {
      height: newHeight,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        className="moveoccasions-modalContent"
        appElement={document.getElementById("root") || undefined}
      >
        <motion.div
          variants={variants}
          initial="initial"
          animate={introductionText.length > 0 ? "updated" : "initial"}
          className="moveoccasions-modal"
        >
          <div ref={ref}>
            <div ref={headerRef}>
              <Header
                progress={progress}
                step={step}
                setStep={setStep}
                language={language}
              />
            </div>
            <Content
              currentDate={currentDate}
              setCurrentDate={setCurrentDate}
              step={step}
              setStep={setStep}
              ticketData={ticketData}
              filteredTicketData={filteredTicketData}
              chosenOldOccasion={chosenOldOccasion}
              setChosenOldOccasion={setChosenOldOccasion}
              occasion={occasion}
              config={config}
              language={language}
              culture={culture}
              posId={posId}
              token={token}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
              introductionText={introductionText}
            />
          </div>
        </motion.div>
      </Modal>
    </div>
  );
};

export default MoveOccasions;
