import slugify from "../../components/common/slugify";

export const getEvent = (data, id) => {
  if (!data) return undefined;
  const allotment = data.allotments.find((a) => slugify(a.description) === id);
  return {
    ...allotment,
    name: allotment?.description,
  };
};
