import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Grid, Group, SimpleGrid } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import PersonalInputField from "./PersonalInputField";
import PersonalImage from "./PersonalImage";
import { useMediaQuery } from "@mantine/hooks";
import Asterisk from "./icons/Asterisk";
import { getZodSchema } from "./validate";
import classes from "./PersonalTicketForm.module.css";

const PersonalTicketForm = ({ photo, photoSet, handleAdd, addedToCart }) => {
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  const mobile = useMediaQuery("(max-width: 768px)");

  const checkIfRequired = (field) => {
    if (config?.formConfig?.[0].requiredFields.includes(field)) {
      return true;
    } else {
      return false;
    }
  };

  const zodSchema = getZodSchema(checkIfRequired, language);

  const form = useForm({
    initialValues: {
      firstName: "",
      lastName: "",
      civicRegistrationNumber: "",
      email: "",
      cellPhone: "",
      street: "",
      streetNumber: "",
      zipCode: "",
      city: "",
      countryCode: "",
    },
    validateInputOnBlur: [
      checkIfRequired("firstName")
        ? checkIfRequired("firstName") && "firstName"
        : "firstName",
      checkIfRequired("lastName")
        ? checkIfRequired("lastName") && "lastName"
        : "lastName",
      checkIfRequired("civicRegistrationNumber")
        ? checkIfRequired("civicRegistrationNumber") &&
          "civicRegistrationNumber"
        : "civicRegistrationNumber",
      checkIfRequired("email") ? checkIfRequired("email") && "email" : "email",
      checkIfRequired("cellPhone")
        ? checkIfRequired("cellPhone") && "cellPhone"
        : "cellPhone",
      checkIfRequired("street")
        ? checkIfRequired("street") && "street"
        : "street",
      checkIfRequired("streetNumber")
        ? checkIfRequired("streetNumber") && "streetNumber"
        : "streetNumber",
      checkIfRequired("zipCode")
        ? checkIfRequired("zipCode") && "zipCode"
        : "zipCode",
      checkIfRequired("city") ? checkIfRequired("city") && "city" : "city",
      checkIfRequired("countryCode")
        ? checkIfRequired("countryCode") && "countryCode"
        : "countryCode",
    ],
    validate: zodResolver(zodSchema),
  });

  const handleSubmit = (val) => {
    handleAdd(val);
  };

  const isValid = () => {
    if (checkIfRequired("photo") && photo && form.isValid()) {
      return false;
    }
    if (!checkIfRequired("photo") && form.isValid()) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (addedToCart) {
      form.reset();
    }
  }, [addedToCart]);

  if (!config) return null;
  return (
    <div className={classes.wrapper}>
      <form
        onSubmit={form.onSubmit((val) => handleSubmit(val))}
        className={classes.form}
        autoComplete="on"
      >
        <Grid>
          <Grid.Col span={mobile ? 12 : 6}>
            <p className={classes.formTitle}>{language.PersonalDetails}</p>
            <p className={classes.formLabel}>{language.FillForm}</p>
            <SimpleGrid cols={mobile ? 1 : 2} mb="md">
              {config &&
                config?.formConfig?.[0].visibleFields.includes("firstName") && (
                  <PersonalInputField
                    form={form}
                    type="firstName"
                    label={language.Form_FirstName}
                  />
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes("lastName") && (
                  <PersonalInputField
                    form={form}
                    type="lastName"
                    label={language.Form_LastName}
                  />
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes(
                  "civicRegistrationNumber"
                ) && (
                  <PersonalInputField
                    form={form}
                    type="civicRegistrationNumber"
                    label={language.Form_CivicRegistrationNumber}
                    placeholder={
                      language.Form_CivicRegistrationNumber_Placeholder
                    }
                  />
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes("email") && (
                  <PersonalInputField
                    form={form}
                    type="email"
                    label={language.Form_Email}
                  />
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes("cellPhone") && (
                  <PersonalInputField
                    form={form}
                    type="cellPhone"
                    label={language.Form_CellPhone}
                  />
                )}
            </SimpleGrid>

            <Grid cols={2}>
              {config &&
                config?.formConfig?.[0].visibleFields.includes("street") && (
                  <Grid.Col span={9}>
                    <PersonalInputField
                      form={form}
                      type="street"
                      label={language.Form_Street}
                    />
                  </Grid.Col>
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes(
                  "streetNumber"
                ) && (
                  <Grid.Col span={3}>
                    <PersonalInputField
                      form={form}
                      type="streetNumber"
                      label={language.Form_StreetNumber}
                    />
                  </Grid.Col>
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes("zipCode") && (
                  <Grid.Col span={3}>
                    <PersonalInputField
                      form={form}
                      type="zipCode"
                      label={language.Form_ZipCode}
                    />
                  </Grid.Col>
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes("city") && (
                  <Grid.Col span={9}>
                    <PersonalInputField
                      form={form}
                      type="city"
                      label={language.Form_City}
                    />
                  </Grid.Col>
                )}
              {config &&
                config?.formConfig?.[0].visibleFields.includes(
                  "countryCode"
                ) && (
                  <Grid.Col span={12}>
                    <PersonalInputField
                      form={form}
                      type="countryCode"
                      label={language.Form_Country}
                    />
                  </Grid.Col>
                )}
            </Grid>
          </Grid.Col>
          <Grid.Col span={mobile ? 12 : 6} mt={mobile ? "xl" : 0}>
            {config &&
              config?.formConfig?.[0].visibleFields.includes("photo") && (
                <>
                  <div className={classes.asteriskWrapper}>
                    <p className={classes.formTitle}>{language.Photo}</p>
                    {checkIfRequired("photo") && <Asterisk />}
                  </div>
                  <p className={classes.formLabel}>{language.AddPhoto}</p>
                  <PersonalImage photo={photo} photoSet={photoSet} />
                </>
              )}
          </Grid.Col>
        </Grid>
        <Group justify="right" aria-live="assertive" role="region">
          <Button
            variant="filled"
            size="md"
            className="regularButton add_to_cart_product"
            classNames={{
              root: addedToCart && classes.addedToCart,
              label: "add_to_cart_product",
              inner: "add_to_cart_product",
            }}
            type="submit"
            aria-label={language.AddToCartBtn}
            style={{ marginTop: "1rem" }}
            disabled={isValid()}
          >
            {addedToCart ? language.Added_To_Cart : language.AddToCartBtn}
          </Button>
        </Group>
      </form>
    </div>
  );
};

export default PersonalTicketForm;
