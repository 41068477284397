import React, { useCallback, useEffect, useState } from "react";
import DrawerItem from "./DrawerItem";
import classes from "./DrawerList.module.css";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const DrawerList = ({ sale }) => {
  const [items, setItems] = useState([]);
  const [parent] = useAutoAnimate();

  const shippingArticle = useCallback(() => {
    const filter = sale.items.filter(
      (el) => el.type === "shipping_fee" || el.type === "service_fee"
    );
    if (filter) {
      const filterOutShipping = sale.items.filter((el) =>
        filter.every((ele) => el.key !== ele.key)
      );
      return setItems(filterOutShipping);
    } else {
      return setItems(sale.items);
    }
  }, [sale]);

  useEffect(() => {
    if (sale && sale?.items) {
      shippingArticle();
    }
  }, [sale]);

  return (
    <>
      {items && (
        <div className={classes.wrapper}>
          <div id="drawer-body">
            <div role="region" aria-live="polite" ref={parent}>
              {items.length > 0 &&
                items.map((el, i) => (
                  <DrawerItem
                    key={el.key}
                    item={el}
                    sale={sale}
                    isLastItem={i + 1 === items.length}
                  />
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DrawerList;
