import React, { useContext, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { BiLinkExternal } from "react-icons/bi";
import { Button } from "@mantine/core";
import { Context } from "../../context";
import SideMenu from "./sideMenu";
import ActivePerson from "./activePerson";
import classes from "./User.module.css";

const User = ({ user: { whoami, ticketowners } }) => {
  const [activePerson, setActivePerson] = useState();
  const sideMenu = ticketowners && ticketowners.length > 1;
  const navigate = useNavigate();
  const { handleLogout } = useContext(Context);
  const { config, language } = useSelector((state) => ({
    config: state.config,
    language: state.language,
  }));
  useEffect(() => {
    if (!activePerson) {
      const person = ticketowners?.find((o) => o?.id === whoami.id);
      if (person) {
        setActivePerson(person);
      }
    }
  }, [whoami, ticketowners, activePerson, setActivePerson]);

  return (
    <div className={classes.user}>
      <div className={`${classes.content} ${sideMenu && classes.sideMenu}`}>
        <SideMenu
          ticketOwners={ticketowners}
          activePerson={activePerson}
          setActivePerson={setActivePerson}
        />
        <ActivePerson activePerson={activePerson} />
      </div>
      <div className={classes.actions}>
        <Button
          variant="outline"
          className="primaryButtonOutline"
          onClick={() => navigate(-1)}
        >
          {language.Back}
        </Button>
        <Button
          variant="outline"
          className="primaryButtonOutline"
          component="a"
          href={config.membersUri}
          target="_blank"
        >
          Ändra uppgifter
          <BiLinkExternal />
        </Button>
        <Button variant="outline" onClick={handleLogout}>
          {language.Log_Off}
        </Button>
      </div>
    </div>
  );
};

export default User;
