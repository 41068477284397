import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

let source;

const Privacy = () => {
  const [text, setText] = useState("");
  const { shopName, baseUrl } = useSelector((state) => state.config);
  useEffect(() => {
    let CancelToken = axios.CancelToken;
    source = CancelToken.source();
    axios
      .get(`${baseUrl}/ee-static/shop/${shopName}/Texts/privacy.md`)
      .then((res) => setText(res.data));

    return source.cancel("Component is unmounting");
  }, [shopName]);

  return (
    <ReactMarkdown className="privacy__text" rehypePlugins={[rehypeRaw]}>
      {text}
    </ReactMarkdown>
  );
};

export default Privacy;
