import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { STATE_CULTURE } from "../../common/state";
import ListItemV2 from "./ListItemV2";
import CardItemV2 from "./CardItemV2";
import classes from "./ProductList.module.css";

const ProductList = ({
  originalPage,
  products,
  events,
  listType,
  setPosition,
  data,
}) => {
  const [eventCalendar, setEventCalendar] = useState(null);
  const { config, culture } = useSelector((state) => ({
    config: state.config,
    culture: state.cookies[STATE_CULTURE],
  }));
  const isCardList = listType
    ? listType === "CARD"
    : window.innerWidth < 667
    ? config.articleListType?.sm === "CARD"
    : config.articleListType?.lg === "CARD";

  // insert imageFileName and translations into the "events" object, using data from API
  if (events) {
    events.forEach((event) => {
      event.imageFileName = data.allotments.find(
        (allotment) => event.id === allotment.id
      ).imageFileName;
      event.translation = data.allotments.find(
        (allotment) => event.id === allotment.id
      ).translation;
    });
  }

  useEffect(() => {
    if (originalPage && originalPage?.events && config?.ticketConfigurator) {
      const filter = config?.ticketConfigurator.filter((ele) => {
        return originalPage?.events.some((el) => {
          return el === ele.name && !ele.disabled;
        });
      });

      if (filter && filter.length > 0) {
        setEventCalendar(filter);
      }
      return () => setEventCalendar(null);
    }
  }, [config, originalPage]);

  return (
    <div
      className={clsx(
        isCardList && classes.cardList,
        "product-list__container"
      )}
    >
      {eventCalendar &&
        eventCalendar.map((ec, key) =>
          isCardList ? (
            <React.Fragment key={key}>
              <CardItemV2 product={ec} />
            </React.Fragment>
          ) : (
            <React.Fragment key={ec.id}>
              <ListItemV2 product={ec} culture={culture} />
            </React.Fragment>
          )
        )}

      {events?.map((event) =>
        isCardList ? (
          <React.Fragment key={event.id}>
            <CardItemV2 product={event} />
          </React.Fragment>
        ) : (
          <React.Fragment key={event.id}>
            <ListItemV2 product={event} culture={culture} />
          </React.Fragment>
        )
      )}
      {products.map((product) =>
        isCardList ? (
          <React.Fragment key={product.id}>
            <CardItemV2 product={product} />
          </React.Fragment>
        ) : (
          <React.Fragment key={product.id}>
            <ListItemV2 product={product} culture={culture} />
          </React.Fragment>
        )
      )}
    </div>
  );
};

export default ProductList;
