import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Busy from "../components/common/busy";

//Need to wait for oidc.isLoadingUser to go from true to false before querying the GraphQL endpoint.
//Also need to prevent further state changes in oidc.isLoadingUser to propagate to the page and thus
//show the spinner once the state has been set.
const OidcClientInitializer = ({ children }) => {
  const [isInitializing, setIsInitializing] = useState(true);
  const finalState = { isLoadingUser: false };

  const { isLoadingUser } = useSelector((state) => {
    if (isInitializing === true) {
      return { isLoadingUser: state?.oidc?.isLoadingUser };
    }

    return finalState;
  });

  useEffect(() => {
    if (isLoadingUser === false && isInitializing === true) {
      setIsInitializing(false);
    }
  }, [isLoadingUser, isInitializing]);

  if (isInitializing === true) {
    return <Busy busy={isInitializing} busyText="Laddar webbshop..." />;
  }

  return <> {children} </>;
};

export default OidcClientInitializer;
