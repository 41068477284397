import classes from "./Options.module.css";
import { SaleContext } from "../common/saleProvider";
import { useContext } from "react";
import DrawerList from "../Drawer/DrawerList";
import { useSelector } from "react-redux";
import OptionsCartFooter from "./OptionsCartFooter";
import { Button } from "@mantine/core";
import { HiChevronDown, HiChevronUp } from "react-icons/hi";
import { useState } from "react";
import { useMediaQuery } from "@mantine/hooks";
import { useMemo } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

const OptionsCart = ({ active, setActive, maxStep }) => {
  const { language } = useSelector((state) => ({
    language: state.language,
  }));
  const saleContext = useContext(SaleContext);
  const { sale } = saleContext.state;
  const [show, setShow] = useState(false);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [parent] = useAutoAnimate();

  const handleShowItems = () => {
    setShow((prev) => !prev);
  };

  const shouldShow = useMemo(() => {
    if (isMobile) {
      if (show) return true;
      else return false;
    } else return true;
  }, [isMobile, show]);

  return (
    <div className={classes.cart}>
      <h2 className={classes.cartHeading}>{language.Cart}</h2>
      {isMobile && (
        <Button
          rightSection={show ? <HiChevronUp /> : <HiChevronDown />}
          variant="transparent"
          className={classes.showProductsButton}
          onClick={handleShowItems}
        >
          {language.Show} {sale?.items.length} {language.ProductsInCart}
        </Button>
      )}
      <div ref={parent}>{shouldShow && <DrawerList sale={sale} />}</div>
      <OptionsCartFooter
        active={active}
        setActive={setActive}
        maxStep={maxStep}
      />
    </div>
  );
};

export default OptionsCart;
