import * as React from "react";
import { useSelector } from "react-redux";
import { getAllProducts } from "./util";
import ProductList from "../ProductList";
import { DataContext } from "../common/dataProvider";

// Returns and displays products or events based on selected link, such as choosing "Produkter" or "Simbassäng" in the headline links.
// In essence, filters what is to be displayed based on groups, if it's a product or event.
const AllProducts = ({ originalPage, page, setPosition }) => {
  const { data } = React.useContext(DataContext);
  const config = useSelector((state) => state.config);
  const groups = page.productGroups;
  const products = getAllProducts(groups, config, data);
  const listType =
    window.innerWidth < 667 ? page.listType?.sm : page.listType?.lg;
  return (
    <div>
      <ProductList
        originalPage={originalPage}
        products={products}
        events={page.events}
        listType={listType}
        setPosition={setPosition}
        data={data}
      />
    </div>
  );
};

export default AllProducts;
