import React from "react";
import { NumberInput } from "@mantine/core";
import classes from "./Field.module.css";

const NumberField = ({ form, id, label, description, required, min, max }) => {
  const matchingFormIndex = form.values.formFields.findIndex(
    (field) => field.id === id
  );
  return (
    <NumberInput
      classNames={{ label: classes.label, description: classes.description }}
      description={`${description} (${min}-${max})`}
      label={label}
      withAsterisk={required}
      style={{ width: "100%" }}
      min={min}
      max={max}
      {...form.getInputProps(`formFields.${matchingFormIndex}.intVal`)}
    />
  );
};

export default NumberField;
