import { createUserManager } from "redux-oidc";

export const getUserManager = (cfg) => {
  const config = {
    client_id: "entryevent-browser-pkce",
    redirect_uri: `${cfg.self}/callback`,
    silent_redirect_uri: `${cfg.self}/silent_renew`,
    automatic_silent_renew: true,
    scope: "openid profile ShopApi entryevent",
    post_logout_redirect_uri: `${cfg.self}`,
    authority: cfg.authUri,
    response_type: "code",
    filterProtocolClaims: false,
    loadUserInfo: true,
  };

  //console.log('usrmgr-config', config);

  try {
    return createUserManager(config, () => true, true);
  } catch (err) {
    console.log(err);
    return { error: true };
  }
};
