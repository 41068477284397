import React from "react";

const Asterisk = ({ strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      strokeWidth={strokeWidth ? strokeWidth : 5}
      stroke="currentColor"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{
        width: "0.5rem",
        color: "rgb(235, 63, 51)",
        alignSelf: "baseline",
        paddingBottom: "0.7rem",
        paddingLeft: "0.1rem",
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
      <path d="M12 12v-9"></path>
      <path d="M12 12l-9 -2.5"></path>
      <path d="M12 12l9 -2.5"></path>
      <path d="M12 12l6 8.5"></path>
      <path d="M12 12l-6 8.5"></path>
    </svg>
  );
};

export default Asterisk;
